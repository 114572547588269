import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import BlueHeader from './blue-header';
import DynamicImage from './dynamic-image';
import { densityImages } from './image-details';
import InputDensity from './input-density';


class Question4 extends Component {

  constructor(props){
    super(props)

    this.onDensityChange = this.onDensityChange.bind(this);
    this.state = {
      density : props.inputValue ? props.inputValue : "low"
    }

  }
  
  renderError({ meta: { touched, error } }) {
    return touched && error ? <span>{error}</span> : false;
  }

  onDensityChange(e){
    this.setState({
      density : e.target.value
    })
  }

  render(){
    const { handleSubmit } = this.props
    const { density } = this.state;
    
    return (
      <div className="page-content">
        <form onSubmit={handleSubmit}>
          <BlueHeader title="Density" text="Select population density of the city" />
          <div className="all-80 push-center content">
            <div className="column-group gutters">
              <InputDensity onDensityChange={this.onDensityChange} />
              <div className="all-50 small-100 tiny-100">
                <div style={{position:'relative'}}>
                  <DynamicImage imageDetails={densityImages[density]} transition="img-fade"  />
                </div>
              </div>
            </div>
            <div className="column-group gutters">
              <div className="all-50 small-100 tiny-100">
                <p>{density ? densityImages[density].text : "This is required in all circumstances, noting that it has the most significant impact on infrastructure requirements and hence cost of all the parameters in the model"}</p>
              </div>
              <div className="all-50 small-100 tiny-100">
               
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}

export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question4))
