import React, { Component } from 'react';
import { horizontalStackedBarChart } from './d3-charts';
import _ from 'lodash';

class StackedBar extends Component {

   /* static propTypes = {...}*/
    
    constructor(props){
        super(props)
    }

    componentDidMount() {
        
        // D3 Code to create the chart
        var stackKeys = this.props.data.map(function(datum){
            return datum.name;
        });
        
        var result = {};
        for (var i = 0; i < this.props.data.length; i++) {
          result[this.props.data[i].name] = this.props.data[i].value;
        }
        result["Keys"] = this.props.breakdownKey;

        var colors = ["#2790B0", "#FDBC09",  "#8F234F",  "#707070", "#FF8000", "#94BA65","#857968"];
        var width = document.getElementById("stacked-bar-wrap").offsetWidth;
        this._chart = horizontalStackedBarChart().init([result], width, this._rootNode, colors, stackKeys);

    }

    componentDidUpdate() {
        // D3 Code to update the chart
        /*D3Line.update(
           this._rootNode,
           this.props.data,
           this.props.config,
           this._chart
        );*/
    }

    componentWillUnmount() {
        /*D3Line.destroy(this._rootNode);*/
    }

    _setRef(componentNode) {
        this._rootNode = componentNode;
    }

    render() {
       
        return (
             <div className="bar-container" ref={this._setRef.bind(this)} />
        )
    }
}

export default StackedBar;