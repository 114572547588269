import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import Handle from './slider-handle';
import { iconsSpec } from '../icons/results'


const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    
    <Handle value={value} {...restProps} />
    
  );
};
const wrapperStyle = { width: 500, margin: 0 };

const renderSliderField = ({settings, onChange, value, onBlur, input, label, type, width, meta: { touched, error } }) => 
{
  const wrapperStyle = { width: width, margin: 0 };
  const min = settings.min;
  const max = settings.max;
  let valueType = settings.valueType;
  let marks = {};
  let step;
  marks[settings.min] = settings.min
  marks[settings.max] = settings.max
  if(valueType != 'population')
  {
    valueType = "hectares"
    step = 1;
  }
  else
  {
    valueType = ""
    step = 500;
  }
  return (
    <div>
      <div style={wrapperStyle}>
        <p className="value-display">
          <span className="icon push-left" style={{backgroundImage:`url(${iconsSpec[input.name].secondary})`, backgroundRepeat: 'no-repeat' }}>            
          </span>
          {input.value + " " + valueType} 
        </p>
        <Slider step={parseInt(step)} min={min} max={max} defaultValue={input.value ? input.value : 0} handle={handle} marks={marks} onChange={input.onChange} className="yellow-slider" />
      </div>
    </div>
  )

}


export default renderSliderField
