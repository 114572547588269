import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from './reducers';
import _ from 'lodash';
import App from './components/app.js';
import * as serviceWorker from './serviceWorker';

const createStoreWithMiddleware = applyMiddleware()(createStore);
let persistedState = localStorage.getItem('CCMState') ? JSON.parse(localStorage.getItem('CCMState')) : {}
persistedState.form = {};
const theStore = createStoreWithMiddleware(reducers, persistedState);
theStore.subscribe(_.throttle(() => {
  localStorage.setItem('CCMState', JSON.stringify(theStore.getState()))
}, 1000))


ReactDOM.render(

<Provider store={theStore}>
	<App />
</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
