import React from 'react';

export default function Header(props){
	return (
		<div className="column-group main-header">
			<div className="all-100">
				<span className="hamburger"></span>
				<h1 className="push-center" onClick={ () => props.history.push({ pathname: "/", state: {transition:"page-fade", duration:700} }) } >Cities cost model</h1>
			</div>
		</div>
	)
}
