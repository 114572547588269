import React from 'react';

import Footer from './footer';

export default function HowTo({ history, to, ...props }){

	return (
		<div className="how-to static-content">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="column-group gutters">
				<div className="all-80 xlarge-70 large-70 push-center content">
					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box">
								<h2>How to use the model</h2>
								<p>Once you have authenticated the app you can start modelling your first city. If you have any problems authenticating please contact </p>
								<h2>Start modeling</h2>
								<p>Answer the question to build up the variables to cost your first city.</p>
								<h2>Inspect the details</h2>
								<p>Once you have completed the questions you can see an output showing the cost of each different category of infrastructure. You can click into each section to see how that cost is broken down in to its’ component parts.</p>
								<p>Our cities cost models and the infrastructure requirements that inform them are based on spatial models utilising best practice in urban design.</p>
								<p>The spatial models reflect a  coastal location served by a commercial port and with an airportand with an environmental boundary ( eg mountains ) along the eastern edge.</p>
								<h2>Save your city</h2>
								<p>In the bottom right hand corner you can save your city.</p>
								<p>Then you can restart the whole process again or you can duplicate your city and alter some of the variables on the compare screen to give you a picture of how your city would look with some minor scenario changes.</p>
								<h2>Compare your models</h2>
								<p>You can see your saved cities in the right hand menu once you have one saved.</p>
								<p>On the compare screen you can either duplicate your city or edit it using the icons at the top of each saved city.</p>
								<h2>Develop your bespoke city model</h2>
								<p>Once you have some saved cities, if you would like us to create a more informed cost model taking into account the bespoke variables of your city you can contact us and we will have the data ready to help you take the next step in planning your city.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters">
						<div className="all-50 small-100 tiny-100">
						</div>
						<div className="all-50 small-100 tiny-100">
							<span className="push-right black-btn" onClick={() => history.push({ pathname: "/included", state: {transition:"page-fade", duration:700} })}>WHAT DOES THE MODEL INCLUDE</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
