import React, { Component } from 'react';
import Modal from 'react-modal';
import { reduxForm } from 'redux-form';

import validate from './validate';
import RadioCheckbox from './renderRadioCheckbox';
import Textarea from './renderTextArea';
import TextInput from './renderTextInput';

class ContactBar extends Component{
	
	constructor(props){
		super(props)

		this.openContactModal = this.openContactModal.bind(this);
		this.closeContactModal = this.closeContactModal.bind(this);
		this.state = {
			modalContactIsOpen: false
		}
	}

	openContactModal() {
		this.setState({modalContactIsOpen: true});
  }

  closeContactModal(e) {
  	this.props.handleSubmit()
  	this.setState({modalContactIsOpen: false});
  }

  handleChange(){

  }

  handleFormSubmit(a, b){
  	a.handleSubmit()
  }

	render(){

		return (
			<span className={"contact-"+this.props.className}>
				{this.props.type === "text" && (
					<div className="contact-btn-text" onClick={this.openContactModal}>
						<p>{this.props.text}</p>
					</div>
				)}

				{this.props.type === "button" && (
					<button className="push-center black-btn talk-to-us" onClick={this.openContactModal}>Talk to us</button>
				)}
				{this.props.children && (
					<span className="contact-text" onClick={this.openContactModal}>
						{this.props.children}
					</span>
				)}
				<Modal
				  isOpen={this.state.modalContactIsOpen}
				  onRequestClose={this.closeContactModal}
				  contentLabel="Example Modal"
				  ariaHideApp={false}
				  className="contact-modal"
				  overlayClassName="contact-overlay"
				>
					<div className="ink-grid">
						<div className="column-group gutters">
				  		<div className="all-80 push-center">
				  			<div className="modal-content">
				          <h2>Contact us</h2>
				          
				          <p>Let us know what you’d like to talk about :</p>
				          <form id="contactForm" onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
				          	<p><Textarea className="contact-message" name="message" /></p>
				            
				            <p>What areas are you interested in?</p>
				            <div className="column-group gutters">
				            	<div className="all-33 push-center">
				            		<div><RadioCheckbox name="interests.ecomonics" label="Ecomonics" value="ecomonics" onChange={this.handleChange} /></div>
				            		<div><RadioCheckbox name="interests.water" label="Water" value="water" onChange={this.handleChange} /></div>
				            		<div><RadioCheckbox name="interests.health" label="Health" value="health" onChange={this.handleChange} /></div>
				            	</div>
				            	<div className="all-33 push-center">
				            		<div><RadioCheckbox name="interests.planning" label="PLANNING" value="planning" onChange={this.handleChange} /></div>
				            		<div><RadioCheckbox name="interests.education" label="EDUCATION" value="education" onChange={this.handleChange} /></div>
				            		<div><RadioCheckbox name="interests.environmnent" label="ENVIRONMENT" value="environmnent" onChange={this.handleChange} /></div>
				            	</div>
				            	<div className="all-33 push-center">
				            		<div><RadioCheckbox name="interests.transport" label="TRANSPORT" value="transport" onChange={this.handleChange} /></div>
				            		<div><RadioCheckbox name="interests.power" label="POWER" value="power" onChange={this.handleChange} /></div>
				            		<div><RadioCheckbox name="interests.design" label="DESIGN" value="design" onChange={this.handleChange} /></div>
				            	</div>
				            </div>
				            <p>Can we contact you by phone? <br /> If so please let us have your contact number</p>
				            <TextInput name="phone" />
				            <button className="push-right black-btn" type="submit"  onClick={this.closeContactModal}>Send</button>
				          </form>
				        </div>
				      </div>
				    </div>
				   </div>
				</Modal>
			</span>
		)

	}
	
}

export default reduxForm({
  form: 'contactForm', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(ContactBar)