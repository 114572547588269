import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';

import numberWithCommas from './numberWithCommas';
import { iconsSpec, iconsEdit } from '../icons/results';
import InputClimate from './input-climate';
import InputDemographic from './input-demographic';
import InputDensity from './input-density';
import InputGeology from './input-geology';
import InputLocation from './input-location';
import InputPopulation from './input-population';
import { getCountryData } from '../json';
import { getIncomeData } from '../json';
import { updateAnswers } from '../actions/';

class ModelSpecList extends Component{

	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleLocationChange = this.handleLocationChange.bind(this);
		this.handlePopulationChange = this.handlePopulationChange.bind(this);
		this.handleAreaChange = this.handleAreaChange.bind(this);

		var countryData = getCountryData();
    this.incomeData = getIncomeData();
    var newData = countryData.map(item=>{
      return {value:item.name, label:item.name, latlng:item.latlng, code:item.alpha3Code}
    })
    
		this.state = {
			countryData : newData,
			name : {
				showEdit : false,
				updated : false
			},
			location : {
				showEdit : false,
				updated : false
			},
			climate : {
				showEdit : false,
				updated : false
			},
			population : {
				showEdit : false,
				updated : false
			},
			density : {
				showEdit : false,
				updated : false
			},
			geology : {
				showEdit : false,
				updated : false
			},
			demographic : {
				showEdit : false,
				updated : false
			},
			update : false
		}

		this.populationSettings = {
      min : 50000,
      max : 2000000,
      valueType : "population"
    }
		
	}

	handleEditClick(key){
		let newState = {
			...this.state
		};
		newState[key] = {
			...this.state[key]
		};
		
		if(this.state[key].showEdit)
		{
			newState[key].showEdit = false;
		}
		else
		{
			newState[key].showEdit = true;
			
		}
		
		this.setState(newState)
	}

	handlePopulationChange(e, value){
		let newState = {};
		newState["population"] = { 
  		value : value,
  		showEdit : true,
  		updated : true
  	}
  	newState.update = true;
 		this.setState(newState);
	}

	handleAreaChange(e, value){
		let newState = {};
		newState["area"] = { 
  		value : value,
  		showEdit : true,
  		updated : true
  	}
  	newState.update = true;
 		this.setState(newState);
	}

	handleLocationChange(selectedItem){
		let newState = {};
		if(this.incomeData[selectedItem.code])
		{
			newState.income = this.incomeData[selectedItem.code]['Income group'] ? this.incomeData[selectedItem.code]['Income group'] : "default";
		}
		else
		{
			newState.income = "default";
		}
		
 		newState["location"] = { 
  		value : selectedItem,
  		showEdit : true,
  		updated : true
  	}
  	newState.update = true;
 		this.setState(newState);
	}


	handleChange(e){


/*    this.props.change('location', selectedItem.value);
    
    if(this.incomeData[selectedItem.code])
    {
      this.props.change('income', this.incomeData[selectedItem.code]['Income group']);
    }
    else
    {
      this.props.change('income', "default");
    }
*/
		//add exception if codeis not in income data

		let newState = {};
		newState[e.target.name] = { 
  		value : e.target.value,
  		showEdit : true,
  		updated : true
  	}
  	newState.update = true;
 		this.setState(newState);
	}

  handleSaveClick(model, field){
  	/*console.log(selectedItem)
    this.props.change('location', selectedItem.value);
    
    if(this.incomeData[selectedItem.code])
    {
      this.props.change('income', this.incomeData[selectedItem.code]['Income group']);
    }
    else
    {
      this.props.change('income', "default");
    }*/
    
    //this.props.dispatch(submit('wizard'));

    if(this.state.update)
    {

    	const newState = () => {
	    	let newState = {};
	    	newState[field] = {};
	    	newState[field].showEdit = false;
	    	return newState;
	    };
	    newState.update = false;
	    this.setState(newState);
	   	this.props.updateAnswers({
	   		value:this.state[field].value, 
	   		model, 
	   		field
	   	})
	   	if(field === 'location')
	   	{
	   		this.props.updateAnswers({
		   		value:this.state["income"], 
		   		model, 
		   		field : "income"
		   	})
	   	}
	   	
	  }
    
		this.handleEditClick(field)
  }

  handleNameChange(e){

  }

  handleNameSaveClick(e){

  }

  handleSubmit(e){

  }

	render(){

		const { handleSubmit, onSubmit } = this.props;
		return(
			
			<form onSubmit={handleSubmit(onSubmit)} config={{form:this.props.modelKey+"Form"}}>
				<ul>
					<li>
						
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['location'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Location</span>
						{this.state['location'].showEdit ? (
							<span className="edit icon push-right" onClick={() => this.handleSaveClick(this.props.modelKey, 'location')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>
							</span>
						) : (
							<span className="edit icon push-right" onClick={() => this.handleEditClick("location")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}>
							</span>
						)}	
						
						<span className="value push-right">{this.props.location.label}</span>
						<div style={{clear:"both"}} > </div>
							
							{
								this.state['location'].showEdit && (
									<div className={"location item-wrap "+((this.state['location'].showEdit) ? "open" : "close")}>
										<InputLocation handleChange={this.handleLocationChange} countryData={this.state.countryData} />
									</div>
								)
							}
						
					</li>
					<li>
						
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['climate'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Climate</span>
						
						{this.state['climate'].showEdit ? (
							<span className="edit icon push-right" onClick={() => this.handleSaveClick(this.props.modelKey, 'climate')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>						
							</span>
						) : (
							<span className="edit icon push-right" onClick={() => this.handleEditClick("climate")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}>
							</span>
						)}	
						<span className="value push-right">{this.props.climate}</span>
						<div style={{clear:"both"}} > </div>
						
							{
								this.state['climate'].showEdit && (
									<div className={"climate item-wrap "+ ((this.state['climate'].showEdit) ? "open" : "close")}>
										<InputClimate onClimateChange={this.handleChange} />
									</div>	
								)
							}
						
					</li>
					<li>
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['population'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Population</span>
						
						{this.state['population'].showEdit ? (
							<span className="edit icon push-right" onClick={() => this.handleSaveClick(this.props.modelKey, 'population')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>	
							</span>
						) : (
							<span className="edit icon push-right" onClick={() => this.handleEditClick("population")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}>								
							</span>
						)}	
						<span className="value push-right">{numberWithCommas(this.props.population)}</span>
						<div style={{clear:"both"}} > </div>
						
							{
								this.state['population'].showEdit && (
									<div className={"population item-wrap "+((this.state['population'].showEdit) ? "open" : "close")}>
										<InputPopulation width="160" settings={this.populationSettings} onSliderChange={this.handlePopulationChange} />
									</div>
								)
							}
						
					</li>
					<li>
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['density'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Density</span>

						{this.state['density'].showEdit ? (
							<span className="edit icon push-right" onClick={() => this.handleSaveClick(this.props.modelKey, 'density')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>								
							</span>
						) : (
							<span className="edit icon push-right" onClick={() => this.handleEditClick("density")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}>
							</span>
						)}	
						<span className="value push-right">{this.props.density}</span>
						<div style={{clear:"both"}} > </div>
						
							{
								this.state['density'].showEdit && (
									<div className={"density item-wrap "+((this.state['density'].showEdit) ? "open" : "close")}>
										<InputDensity onDensityChange={this.handleChange} />
									</div>
								)
							}
						
					</li>
					<li>
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['geology'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Geology</span>

						{this.state['geology'].showEdit ? (
							<span className="edit icon push-right" onClick={() => this.handleSaveClick(this.props.modelKey, 'geology')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>
							</span>
						) : (
							<span className="edit icon push-right" onClick={() => this.handleEditClick("geology")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}>					
							</span>
						)}	
						<span className="value push-right">{this.props.geology}</span>
						<div style={{clear:"both"}} > </div>
						
							{
								this.state['geology'].showEdit && (
									<div className={"geology item-wrap "+((this.state['geology'].showEdit) ? "open" : "close")}>
										<InputGeology onGeologyChange={this.handleChange} />
									</div>
								)
							}
						
					</li>
					<li>
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['demographic'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Demographic</span>

						{this.state['demographic'].showEdit ? (
							<span className="edit icon push-right" onClick={() => this.handleSaveClick(this.props.modelKey, 'demographic')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>								
							</span>
						) : (
							<span className="edit icon push-right" onClick={() => this.handleEditClick("demographic")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}>
							</span>
						)}	
						<span className="value push-right">{this.props.demographic}</span>
						<div style={{clear:"both"}} > </div>
						
							{
								
								this.state['demographic'].showEdit && (
									<div className={"demographic item-wrap "+((this.state['demographic'].showEdit) ? "open" : "close")}>
										<InputDemographic onDemographicChange={this.handleChange} />
									</div>
								)
							}
						
					</li>
				</ul>
			</form>
		)
	}
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ updateAnswers }, dispatch);
}

export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(connect(mapStateToProps, mapDispatchToProps)(ModelSpecList))