import React, { Component } from 'react';
import { radialBarChart } from './d3-charts';

class RadialBar extends Component {

   /* static propTypes = {...}*/
   
    componentDidMount() {
        
        // D3 Code to create the chart


        var stackKeys = this.props.data.map(function(datum){
            return datum.name;
        });
        var width = document.getElementById("radial-bar-wrap").offsetWidth;
        var colors = ["#2790B0", "#FDBC09",  "#8F234F",  "#707070", "#FF8000", "#94BA65","#857968"];
        this._chart = radialBarChart().init(this.props.data, this._rootNode, width, colors, stackKeys);
        
        /*this._chart = radialBarChart.create(
            this._rootNode,
            this.props.data,
            this.props.config
        );*/


    }

    componentDidUpdate() {
        // D3 Code to update the chart
        /*D3Line.update(
           this._rootNode,
           this.props.data,
           this.props.config,
           this._chart
        );*/
    }

    componentWillUnmount() {
        /*D3Line.destroy(this._rootNode);*/
    }

    _setRef(componentNode) {
        this._rootNode = componentNode;
    }

    render() {
       
        return (
             <div className="line-container" ref={this._setRef.bind(this)} />
        )
    }
}

export default RadialBar;