import React, { Component } from 'react';
import { connect } from 'react-redux';

import TotalCostList from './total-cost-list';
import ModelSpecList from './model-spec-list';

import { iconsSpec, iconsTotals, iconsEdit } from '../icons/results';

class ModelSpec extends Component {

	componentDidMount(){

	}

	constructor(props){
		super(props)
		this.showResults = this.showResults.bind(this);

		this.state = {
			nameShowEdit : false,
			locationShowEdit : false,
			climateShowEdit : false,
			populationShowEdit : false,
			densityShowEdit : false,
			geologyShowEdit : false,
			demographicShowEdit : false
		}

		this.handleSpecEditClick = this.handleSpecEditClick.bind(this);
	}

	handleSpecEditClick(a, b){
		let propertyName = a + "ShowEdit";
		let newState = {};
		newState[propertyName] = true;
		this.setState(newState)

	}

	getResult(key){
		
		const models = this.props.models;
		const results = models[key].results;

		//add this in to edit name
			/*		<h3>
			
			{this.state['name'].showEdit ? (
				<span className="edit icon push-right" onClick={() => this.handleNameSaveClick(this.props.modelKey, 'name')} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}></span>
			) : (
				<span className="edit icon push-right" onClick={() => this.handleEditClick("location")} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}></span>
			)}

			{
				this.state['name'].showEdit && (
					<InputName handleChange={this.handleNameChange} />
				)
			}	
		</h3>
		<span className="edit icon push-right" style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}></span>
		*/

		return (
			<div className="compareItem">
					
				<h3>{models[key].info.title}</h3>
				<div className="compare-list-wrap total-cost-wrap"><TotalCostList total={results.total} perCapita={results.perCapita} icons={iconsTotals}/></div>
				<div className="compare-list-wrap model-spec"><ModelSpecList {...results.answers} modelKey={key} onSubmit={this.onFormSubmit} icons={iconsSpec} handleClick={this.handleSpecEditClick} /></div>
			</div>
		)
	}

	onFormSubmit(e){
		
	}

	showResults(){
		if(this.props.comparisions)
		{
			const ccmHistory = localStorage.getItem('ccm-history') ? JSON.parse(localStorage.getItem('ccm-history')) : {};
			const comparatorCount = this.props.comparisions.length;
			let className;
			let rowLimit;
			
			if(comparatorCount > 2)
			{
				className = "adjustable-column fit-33 spec-wrap";
				rowLimit = 3;
			}
			else
			{
				className = "adjustable-column fit-50 spec-wrap";
				rowLimit = 2;
			}

			let index = 0;
			let rowIndex = 0;
			let jsx = [];
			jsx[rowIndex] = [];

			for(let key in this.props.comparisions)
			{
				
				if(index % rowLimit == 0 && index > 1)
				{
					rowIndex++;
					jsx[rowIndex] = [];
					jsx[rowIndex].push(<div key={index} className={className}>{this.getResult(this.props.comparisions[key])}</div>)
				}
				else
				{
					jsx[rowIndex].push(<div key={index} className={className}>{this.getResult(this.props.comparisions[key])}</div>)
				}
				index++;
			}
			// jsx is now each element of the array represents 1 row, which is 
			// ignored for horizontal scrolling
			// console.log(jsx)

			return <div className="column-group adjustable-column-group">{jsx}</div>;
		}
			
	}

	render(){
		return (
			<div className="content">
				{this.showResults()}
			</div>
		)
	}
}

function mapStateToProps(state){
	return state;
}

export default connect(mapStateToProps)(ModelSpec);