import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Transition } from '../transitions'
import Question1 from './question1';
import Question2 from './question2';
import Question3 from './question3';
import Question4 from './question4';
import Question5a from './question5a';
import Question5b from './question5b';
import Question6 from './question6';
import Question7 from './question7';
import Footer from './footer';
import ProgressBar from './progress-bar';
import bgVideo from '../video/AdobeStock_182426277_small.mp4';

class Questions extends Component {
  constructor(props) {
    super(props)

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.clearStorage = this.clearStorage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.addToStorageHistory = this.addToStorageHistory.bind(this);
    this.state = {
      page: 1,
      values:{}
    }

    this.footerBtns = {
      navigateBack : {
        text : "Back",
        callback : () => props.history.push({ pathname: "/", state: {transition:"page-fade", duration:700} })
      },
      back : {
        text : "Back",
        callback : this.previousPage
      },
      navigateForward : {
        text : "Back",
        callback : () => props.history.push({ pathname: "/results", state: {transition:"page-fade", duration:700} })
      }

    }
  }
  nextPage(values) {
    this.setState({
      page: this.state.page + 1,
      prevPage: this.state.page -1,
      values : values
    })
    if(this.refs.bgVideo)
    {
      this.refs.bgVideo.play();
      setTimeout(function(){
        this.refs.bgVideo.pause();
      }.bind(this), 700)
    }
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
      prevPage: this.state.page + 1
    })
    this.refs.bgVideo.play();
    setTimeout(function(){
      this.refs.bgVideo.pause();
    }.bind(this), 700)
  }

  getDateTime(){
    var currentdate = new Date();
    var datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/"
                + currentdate.getFullYear() + "@"
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();
    return datetime;
  }

  clearStorage(){
    localStorage.removeItem('CCMState');
  }



  addToStorageHistory(){
    const currentState = localStorage.getItem('CCMState');
    let history = localStorage.getItem('CCMHistory');

    if(!history)
    {
      history = {};
      history[this.getDateTime()] = currentState;
    }
    else
    {
      history = JSON.parse(history);
      history[this.getDateTime()] = currentState;
    }
    history = JSON.stringify(history)
    localStorage.setItem('CCMHistory', history);
    localStorage.removeItem('CCMState');
  }

  onFormSubmit(values){
    this.props.history.push({ pathname: "/results", state: {transition:"forwards", duration:700} });
  }

  render() {

    const { page, prevPage, values } = this.state

    let transition = "forwards";
    if(page < prevPage)
    {
      //go backwards
      transition = "backwards";
    }
    let question5 = "";
    if(values.planning)
    {
      if(values.planning === "population")
      {
        question5 = <Question5a previousPage={this.previousPage} onSubmit={this.nextPage} key="4" inputValue={values.population} />
      }
      else
      {
        question5 = <Question5b previousPage={this.previousPage} onSubmit={this.nextPage} density={values.density} key="4" inputValue={values.area} />
      }
    }
    else
    {
      question5 = <Question5a previousPage={this.previousPage} onSubmit={this.nextPage} key="4" inputValue={values.population} />
    }

    return (
      <div className="questions page">


          <video id="myVideo" style={{ width: '100%' }}
            src={bgVideo}
            loop
            muted
            ref="bgVideo">
            <div className="bg-image"></div>
          </video>
          <Transition pageKey={page} duration={700} transition={transition}>

          {page === 1 && (
            <Question1 onSubmit={this.nextPage} key="1" inputValue={values.location} />
          )}
          {page === 2 && (
            <Question2
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
              key="2"
              inputValue={values.climate}
            />
          )}
          {page === 3 && (
            <Question3
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
              key="3"
            />
          )}
          {page === 4 && (
            <Question4
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
              key="4"
              inputValue={values.density}
            />
          )}
          {page === 5 && (
            question5
          )}
          {page === 6 && (
            <Question6
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
              key="6"

            />
          )}
          {page === 7 && (
            <Question7
              previousPage={this.previousPage}
              onSubmit={this.onFormSubmit}
              key="7"
            />
          )}
        </Transition>
        <Footer page={page} buttonData={this.footerBtns}/>
        <ProgressBar page={page} values={values} />
      </div>
    )
  }
}




function mapStateToProps(state){
  return state
}

export default connect(mapStateToProps)(Questions);
