import React from 'react';

import Footer from './footer';
import ContactBar from './contact-bar';


export default function HomePage({ history, to, ...props }){
	return (
		<div className="front-page">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="all-80 push-center content">
				<div className="push-center all-100">
					<div className="grey-text-box">
						<h2>How much will a new city cost?</h2>
						<p>Understanding the impact of varying factors on the infrastructure requirements and capital costs of new cities can be hard to define. AECOM have developed an interactive cost model which provides a comprehensive analysis of the impact.</p>
						<p>The model incorporates best practice on urban design, transport, utilities and social infrastructure design and assesses how these interact to shape the form and function of a new city.</p>
						<p>Enabling users to consider the best fit for their own development using a spatial models and precedent images. It will then  generate a range of outputs on the cost per head and total strategic infrastructure costs. You will be able to make instant comparisons on the impact of population, density, climate, demographic and geology on your city, showing how these interact together.</p>
						<p>Note, that this model is designed for a population range of  50,000 up to  2,000,000. However, if you are considering something smaller or larger, then please get in touch as we have experts at all levels. You can also view our recent garden communities cost model which looks at some of these elements in a smaller scale.</p>
						<p>If you have any queries or difficulties with this model, please <ContactBar onSubmit={props.handleContactSubmit} >contact us</ContactBar>.</p>
					</div>
				</div>
				<div className="all-100 push-center btns">
					<div className="column-group">
						<div className="all-50">
							<button className="grey-btn" onClick={props.openLeftMenu}>Find out about the model</button>
						</div>
						<div className="all-50">
							<button className="grey-btn" onClick={() => history.push({ pathname: "/modeling", state: {transition:"page-fade", duration:700} })}>START MODELING</button>
							{/*<Link to="/modeling" className="grey-btn">Start modeling</Link>*/}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
