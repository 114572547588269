import React from 'react';

const renderTextField = ({ name, value, onBlur, options, input, label, type, onChange, meta: { touched, error } }) => (
  <div>
    <input type="hidden" name={name} onChange={onChange}  />
    {touched && error && <span className="error">{error}</span>}
  </div>
)

export default renderTextField
