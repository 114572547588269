import ResultsCalc from './results-calc';

export default function calculateResults(answers,inflation){

	console.log(answers, inflation)

		const resultsCalc = new ResultsCalc(answers, inflation);
		const sitePrep = resultsCalc.calculateSitePre();
  	const transport = resultsCalc.calculateTransport();
  	const utilities = resultsCalc.calculateUtilities();
  	const landscaping = resultsCalc.calculateLandscaping();
  	const socialInfrastructure = resultsCalc.calculateSocialInfrastructure();
  	const professionalFees = (sitePrep[0] + transport[0] + utilities[0] + landscaping[0] +socialInfrastructure[0]) * 0.15;
  	const professionalFeesWO = "( "+sitePrep[0] +" + "+ transport[0] +" + "+ utilities[0] +" + "+ landscaping[0] +" + "+ socialInfrastructure[0] +") * 0.15";
  	const contingency = (sitePrep[0] + transport[0] + utilities[0] + landscaping[0] + socialInfrastructure[0] + professionalFees) * 0.15;
  	const contingencyWO = "( "+sitePrep[0] +" + "+ transport[0] +" + "+ utilities[0] +" + "+ landscaping[0] +" + "+ socialInfrastructure[0] +" + "+ professionalFees +" ) * 0.15";
  	const total = (sitePrep[0] + transport[0] + utilities[0] + landscaping[0] + socialInfrastructure[0] + professionalFees + contingency);
		// const totalPreInflation = (sitePrep[0] + transport[0] + utilities[0] + landscaping[0] + socialInfrastructure[0] + professionalFees + contingency);
		// const total =totalPreInflation + ((totalPreInflation/100)*inflation)
		const totalWO = "( "+sitePrep[0] +" + "+ transport[0] +" + "+ utilities[0] +" + "+ landscaping[0] +" + "+ socialInfrastructure[0] +" + "+ professionalFees +" + "+ contingency +" ) ";
		// const totalWOPreInflation = "( "+sitePrep[0] +" + "+ transport[0] +" + "+ utilities[0] +" + "+ landscaping[0] +" + "+ socialInfrastructure[0] +" + "+ professionalFees +" + "+ contingency +" ) ";
		// const totalWO =totalWOPreInflation + ((totalWOPreInflation/100)*inflation)
		const perCapita = total / answers.population;
  	const perCapitaWO = total +" / "+ answers.population;


  	const totalsObj = {
  		socialInfrastructure : {name:"Social Infrastructure", id:"socialInfrastructure", value:socialInfrastructure[0].toFixed(), color:"primary", icon:"", order:4, breakdown:true},
  		transport : {name:"Transport", id:"transport", value:transport[0].toFixed(), color:"primary", icon:"", order:1, breakdown:true},
  		utilities : {name:"Utilities", id:"utilities", value:utilities[0].toFixed(), color:"primary", icon:"", order:2, breakdown:true},
  		landscaping : {name:"Landscaping", id:"landscaping", value:landscaping[0].toFixed(), color:"primary", icon:"", order:3},
  		sitePrep : {name:"Site Preperation", id:"sitePrep", value:sitePrep[0].toFixed(), color:"primary", icon:"", order:0},
  		professionalFees : {name:"Professional Fees", id:"professionalFees", value:professionalFees.toFixed(), color:"primary", icon:"", order:5},
  		contingency : {name:"Contingency", id:"contingency", value:contingency.toFixed(), color:"primary", icon:"", order:6}
		};

		const formulaObj = {
			sitePrep : sitePrep[1] + " = " + sitePrep[0],
			transport : transport[1] + " = " + transport[0],
			utilities : utilities[1] + " = " + utilities[0],
			landscaping : landscaping[1] + " = " + landscaping[0],
			socialInfrastructure : socialInfrastructure[1] + " = " + socialInfrastructure[0],
			professionalFees : professionalFeesWO  + " = " + professionalFees,
			contingency : contingencyWO  + " = " + contingency,
			total : totalWO  + " = " + total,
			perCapita : perCapitaWO  + " = " + perCapita
		};

		// let maritime;
		// let aviation;
		// let publicTransport;
		// let privateTransport;
		//
		// if(answers.population < 500000)
		// {
		// 	maritime = 8400 * answers.population;
		// 	aviation = 0;
		// 	publicTransport = (transport[0].toFixed() - maritime) * .6;
		// 	privateTransport = (transport[0].toFixed() - maritime) * .4;
		// }
		// else
		// {
		// 	maritime = 8400 * answers.population;
		// 	aviation = 3500 * answers.population;
		// 	publicTransport = (transport[0].toFixed() - (maritime + aviation)) * .6;
		// 	privateTransport = (transport[0].toFixed() - (maritime + aviation)) * .4;
		// }
		let maritime;
		let aviation;
		let publicTransport;
		let privateTransport;

		if(answers.population < 250000)
		{
			let maritimeTemp = 8400 * answers.population
			maritime = maritimeTemp *transport[3]
			aviation = 0;
			publicTransport = ((transport[2].toFixed() - maritimeTemp) * .6)*transport[3];
			privateTransport = ((transport[2].toFixed() - maritimeTemp) * .4)*transport[3];
		}
		else
		{
			let maritimeTemp = 8400 * answers.population
			let aviationTemp = 3500 * answers.population

			publicTransport = ((transport[2].toFixed() - (maritimeTemp + aviationTemp)) * .6)*transport[3];
			privateTransport = ((transport[2].toFixed() - (maritimeTemp + aviationTemp)) * .4)*transport[3];
			maritime = maritimeTemp *transport[3]
			aviation = aviationTemp *transport[3]

		}

		let transportTemp = maritime + aviation + publicTransport + privateTransport
		console.log(transport , transportTemp)

  	const transportBreakdowns = {
  		maritime : {name:"Maritime", id:"maritime", value:maritime, color:"tertiary", icon:"", order:0, percentage: 48},
  		aviation : {name:"Aviation", id:"aviation", value:aviation, color:"tertiary", icon:"", order:1, percentage: 15},
  		publicTransport : {name:"Public Transport", id:"publicTransport", value:publicTransport, color:"tertiary", icon:"", order:2, percentage: 23},
  		privateTransport : {name:"Private Transport", id:"privateTransport", value:privateTransport, color:"tertiary", icon:"", order:3, percentage: 14}
  	}


		const utilitiesBreakdowns = {
			power : {name:"Power", id:"power", value:((40 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 40},
			districtHeating : {name:"District Heating / Cooling", id:"districtHeating", value:((30 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:1, percentage: 30},
			potableWater : {name:"Potable Water", id:"potableWater", value:((12 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:2, percentage: 12},
			sewerage : {name:"Sewerage", id:"sewerage", value:((7 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:3, percentage: 7},
			surfaceWater : {name:"Surface Water", id:"surfaceWater", value:((8 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:4, percentage: 8},
			telecommunication : {name:"Telecommunication", id:"telecommunication", value:((1 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:5, percentage: 1},
			solidWaste : {name:"Solid Waste", id:"solidWaste", value:((2 / 100) * utilities[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:6, percentage: 2}
		};


		const socialBreakdowns = {
			education : {name:"Education", id:"education", value:((38 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 38},
			health : {name:"Health", id:"health", value:((20 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 20},
			cityManagement : {name:"City Management", id:"cityManagement", value:((4 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 4},
			sportsAndLeisure : {name:"Sports & Leisure", id:"sportsAndLeisure", value:((7 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 7},
			culturalAndCommunitySpace : {name:"Cultural & Community Space", id:"culturalAndCommunitySpace", value:((6 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 6},
			emergency : {name:"Emergency", id:"emergency", value:((1 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 1},
			religiousRequirement : {name:"Religious Requirement", id:"religiousRequirement", value:((24 / 100) * socialInfrastructure[0].toFixed()).toFixed(), color:"tertiary", icon:"", order:0, percentage: 24}
		};

/*
		let transportBreakdownPerc = {};
		transportBreakdownPerc["Maritime"] = 48;
		transportBreakdownPerc["Aviation"] = 15;
		transportBreakdownPerc["Public Transport"] = 23;
		transportBreakdownPerc["Private Transport"] = 14;

		let utilitiesBreakdownPerc = {}
		utilitiesBreakdownPerc["Power"] = 40;
		utilitiesBreakdownPerc["District Heating"] = 30;
		utilitiesBreakdownPerc["Potable Water"] = 12;
		utilitiesBreakdownPerc["Sewerage"] = 7;
		utilitiesBreakdownPerc["Surface Water"] = 8;
		utilitiesBreakdownPerc["Telecommunication"] = 1;
		utilitiesBreakdownPerc["Solid Waste"] = 2;

		let socialBreakdownPerc = {}

		socialBreakdownPerc["Education"] = 38;
		socialBreakdownPerc["Health"] = 20;
		socialBreakdownPerc["CityManagement"] = 4;
		socialBreakdownPerc["Sports & Leisure"] = 7;
		socialBreakdownPerc["Cultural & Community Space"] = 6;
		socialBreakdownPerc["Emergency"] = 1;
		socialBreakdownPerc["Religious Requirement"] = 24;*/

		const breakdowns = {
  		transport :  transportBreakdowns,
    	utilities : utilitiesBreakdowns,
    	socialInfrastructure : socialBreakdowns
    }

/*	    let breakdownPercs = {
  		transport :  transportBreakdownPerc,
    	utilities : utilitiesBreakdownPerc,
    	socialInfrastructure : socialBreakdownPerc
    }*/
		const results = {
  		total : total.toFixed(),
  		perCapita : perCapita.toFixed(),
  		totals : totalsObj,
  		formulas : formulaObj,
    	breakdowns : breakdowns,
    	answers : answers
    }

    return results

}
