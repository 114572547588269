import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default class CustomScrollbars extends Component {
    constructor(props, context) {
        super(props, context)
        this.handleScrollFrame = this.handleScrollFrame.bind(this);
    }

    handleScrollFrame(values) {
        this.props.handleSliderChange(values);
    }

    render() {
        return (
            <Scrollbars 
            		style={{height:"15px", width:"80%"}}
                onScrollFrame={this.handleScrollFrame}
                className="custom-scroll"
                {...this.props}/>
        );
    }
}