import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import Header from './header';
import Footer from './footer';
import { iconsTotals } from '../icons/results';

export default function About({ history, to, ...props }){

	return (
		<div className="how-to static-content">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="column-group gutters">
				<div className="all-80 xlarge-70 large-70 push-center content">
					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box">
								<h2>About the model</h2>
								<p>Through a detailed analysis, AECOM have selected a series of key factors that are considered to have the most significant impact on the infrastructure requirements and hence capital cost of a new city.</p>
								<p>The scenarios are shown in a coastal location, allowing the provision of a commercial port and with an airport. For any other bespoke configuration please contact us.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters set-min-height">
						<div className="all-100">
							<div className="grey-text-box">
								<h3>Population</h3>
								<p>The model considers a range of 50,000 up to 2,000,000</p>
								<p>If you are considering something smaller or larger, then please get in touch as we have experts at all levels. You can also view our recent garden communities cost model which looks at some of these elements in a smaller scale.</p>
							</div>
						</div>
					</div>

					<div className="column-group gutters set-min-height">
						<div className="all-100">
							<div className="grey-text-box">
								<h3>Construction costs</h3>
								<p>Location factors have been used to reflect the range of construction costs across the World. We have used  the World Bank designation for low, lower mid, upper mid and high incomes.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters set-min-height">
						<div className="all-100">
							<div className="grey-text-box">
								<h3>Density</h3>
								<p>Scenarios were developed covering five population sizes (2 million; 1 million;  500,000;  250,000), and three density characteristics:</p>
								<ul>
									<li>High (average ~160 inh / ha) – Representing a compact European city model, with relatively uniform distribution of high densities, and a structure with one major centre covering a large part of its footprint.  Think – Barcelona.</li>
									<li>Medium (average ~70 inh / ha) –  Most similar to typical AECOM structure planning approach, with a polycentric structure, fully-developed hierarchy of urban centres, co-ordinated with a range of densities.  Think – London.</li>
									<li>Low (average ~40 inh / ha) – Representing a low-density model eg a  North American or Middle Eastern city with several top-level centres set in a large suburban area served by many small-scale centres.  Think – Jeddah.</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="column-group gutters set-min-height">
						<div className="all-100">
							<div className="grey-text-box">
								<h3>Climate</h3>
								<p>Having selected the location of the City, the Model will automatically incorporate the climatic conditions from one of the four options below</p>
								<p>Arid - An arid climate is one that receives less than 10 inches (25.4 centimetres) of rainfall in an entire year.</p>
								<p>Tropical – A temperate climate has every month with an average temperature of 18 C or higher and with significant precipitation</p>
								<p>Temperate - Temperate climates are those without extremes of temperature and precipitation (rain and snow). The changes between summer and winter are generally invigorating without being frustratingly extreme.</p>
								<p>Cold - These climates have average temperatures above 10 °C in their warmest months and average below −3 °C in their coldest month. These usually occur in the interiors of continents, or on their east coasts, north of 40° North latitude.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters set-min-height">
						<div className="all-100">
							<div className="grey-text-box">
								<h3>Geology</h3>
								<p>The model considers 3 geological zones</p>
								<ul>
									<li>Previously undeveloped land</li>
									<li>Previously developed land low risk - Previously developed land but with limited risk of contamination of the existing ground due to the nature of previous land uses.</li>
									<li>Previously developed land high risk - Previously developed land but with a significant risk of contamination of the existing ground due to the nature of previous land uses</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="column-group gutters set-min-height">
						<div className="all-100">
							<div className="grey-text-box">
								<h3>Demographic</h3>
								<p>Three demographic profiles have been selected from three existing geographies to emulate cities at different demographic and development stages</p>
								<ul>
									<li>
										Balanced Demographic Profile: e.g. London – this presents a demographic profile that has a large working age population between 20 and 65 years old , but one that also has a significant portion of the population that is a young  dependent who will eventually move into the workforce.
										<ul>
											<li>Young (&lt;20): 25%</li>
											<li>Working (20-65): 64%</li>
											<li>Old (&gt;65): 11%</li>
										</ul>
									</li>
									<li>
										Old Demographic Profile: e.g. Japan – this presents a demographic profile that has a very large elderly population that is disproportionate to the young and working age population.
										<ul>
											<li>Young (&lt;20): 18%</li>
											<li>Working (20-65): 56%</li>
											<li>Old (&gt;65): 26%</li>
										</ul>
									</li>
									<li>
										Young Demographic Profile: e.g. Jeddah – this presents a demographic profile that has a very young population that is disproportionate to the working age population.
										<ul>
											<li>Young (&lt;20): 40%</li>
											<li>Working (20-65): 57%</li>
											<li>Old (&gt;65): 3%</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box header-links-wrap">
								<p>By selecting one of the options from each of these factors the Model will provide a total site preparation and infrastructure cost in US Dollars as at November 2018 prices. This will be expressed as  a total cost  and a cost per capita ( per head of population )and will show a more detailed split between</p>
								<div className="column-group gutters">
									<div className="all-50 small-100 tiny-100">
										<Link to={{pathname:"/included", hash:"#site-preperation"}} >
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['sitePrep'].primary})`, backgroundRepeat: 'no-repeat' }}>
												</span>
												Site preparation
											</h3>
										</Link>
									</div>
									<div className="all-50 small-100 tiny-100">
										<Link to={{pathname:"/included", hash:"transport"}}>
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['transport'].primary})`, backgroundRepeat: 'no-repeat' }} >
												</span>
												Transport
											</h3>
										</Link>
									</div>
								</div>
								<div className="column-group gutters">
									<div className="all-50 small-100 tiny-100">
										<Link to="/included#utilities">
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['utilities'].primary})`, backgroundRepeat: 'no-repeat' }}>
												</span>
												Utilities
											</h3>
										</Link>
									</div>
									<div className="all-50 small-100 tiny-100">
										<Link to="/included#social-infrastructure">
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['socialInfrastructure'].primary})`, backgroundRepeat: 'no-repeat' }}>
												</span>
												Social infrastructure
											</h3>
										</Link>
									</div>
								</div>
								<div className="column-group gutters">
									<div className="all-50 small-100 tiny-100">
										<Link to="/included#landscaping">
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['landscaping'].primary})`, backgroundRepeat: 'no-repeat' }}>
												</span>
												Landscaping
											</h3>
										</Link>
									</div>
									<div className="all-50 small-100 tiny-100">
										<Link to="/included#professional-fees">
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['professionalFees'].primary})`, backgroundRepeat: 'no-repeat' }}>
												</span>
												Professional fees
											</h3>
										</Link>
									</div>
								</div>
								<div className="column-group gutters">
									<div className="all-50 small-100 tiny-100">
										<Link to="/included#contingency">
											<h3>
												<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals['contingency'].primary})`, backgroundRepeat: 'no-repeat' }}>
												</span>
												Contingency
											</h3>
										</Link>
									</div>
								</div>
								<p>The infrastructure requirements and  costs in the model for the site preparation and strategic infrastructure, will then support the residential, commercial, industrial, retail, leisure and cultural development within the city. These developments costs though are excluded from the model.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters">
						<div className="all-50 small-100 tiny-100">
						</div>
						<div className="all-50 small-100 tiny-100">
							<span className="push-right black-btn" onClick={() => history.push({ pathname: "/how-to-use-the-model", state: {transition:"page-fade", duration:700} })}>HOW TO USE THIS MODEL</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
