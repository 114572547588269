import { SAVE_MODEL } from '../actions';
import { GET_MODELS } from '../actions';
import { UPDATE_ANSWERS } from '../actions';
import { UPDATE_MODEL_INFO } from '../actions';
import { DELETE_MODEL } from '../actions';
import _ from 'lodash';

export default function(state = {}, action) {
  switch (action.type) {
    
    case SAVE_MODEL:
    		let saveState = Object.assign({}, state);
				saveState[action.payload.key] = action.payload.model;
    		return saveState;
    
    case GET_MODELS: 

    	return state;

    case UPDATE_ANSWERS: 
    	let updateState = Object.assign({}, state);
    	updateState[action.payload.model].results.answers[action.payload.field] = action.payload.value;

    	return updateState;

    case UPDATE_MODEL_INFO: 
     
      let updatedModelInfo = _.cloneDeep(state);
      updatedModelInfo[action.payload.model].info[action.payload.field] = action.payload.value;
      return updatedModelInfo;

    case DELETE_MODEL: 
      
      let deleteModel = _.cloneDeep(state);
      
      delete deleteModel[action.payload.modelName];
     
      return deleteModel;

    default:
      return state
  }
}