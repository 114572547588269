export default class ResultsCalc {

	constructor(answers, inflation){

		this.answers = answers;
		this.inflation = inflation;
		this.site_pre_vars = {
			density : {
				high : 1,
				medium : 2.50,
				low : 3.75
			},
			geology : {
				"Previously undeveloped land" : 1,
				"Previously developed land low risk" : 7,
				"Previously developed land high risk" : 21
			}
		}

		this.transport_vars = {
			lowerPopulation : {

				density : {
					high : 0,
					medium : 487200000,
					low : 911300000
				}
			},
			higherPopulation : {

				density : {
					high : 0,
					medium : 1979000000,
					low : 2570000000
				}
			}
		}

		this.utilities_vars = {
			lowerPopulation : {

				density : {
					high : 0,
					medium : 1697000000,
					low : 3272000000
				},
				climate : {
					arid : 0,
					tropical : 0,
					cold : -2990000000,
					temperate : -2521000000
				},
				costFor50000  : {
					"high-temperate" :  "604038596",
					"medium-temperate" : "838412596",
					"low-temperate" : "1027020596",
					"high-cold" : "517528662",
					"medium-cold" : "751902662",
					"low-cold" : "940510662",
					"high-arid" : "1574881192",
					"medium-arid" : "1809255192",
					"low-arid" : "1997863192",
					"high-tropical" : "1574881192",
					"medium-tropical" : "1809255192",
					"low-tropical" : "1997863192"
				},
				costFor1000000  : {
					"high-temperate" :  "8953549374",
					"medium-temperate" : "14224164374",
					"low-temperate" : "23656881374",
					"high-cold" : "7198559925",
					"medium-cold" : "1246917492",
					"low-cold" : "21901891925",
					"high-arid" : "15084376807",
					"medium-arid" : "20442015807",
					"low-arid" : "30113180807",
					"high-tropical" : "15084376807",
					"medium-tropical" : "20442015807",
					"low-tropical" : "30113180807"
				}

			},
			higherPopulation : {

				density : {
					high : 0,
					medium : 4284000000,
					low : 9776000000
				},
				climate : {
					arid : 0,
					tropical : 0,
					cold : -6667000000,
					temperate : -5334000000
				}
			}
		}

		this.demographic = {};
		this.demographic["Young population"] = 1.20;
		this.demographic["Standard demographic"] = 1;
		this.demographic["Ageing population"] = .92;

		this.social_infrastructure_vars = {
			demographic : this.demographic
		}

		this.income_brackets = {
			"high income high" : 1,
			"high income low" : 1,
			"upper middle income" : 0.45,
			"lower middle income" : 0.33,
			"low income average" : 0.33,
			"low income" : 0.33,
			"default" : 1
		}

	}

	calculateSitePre(){

let PreSiteTotal = ((210*this.answers.population) * this.site_pre_vars.density[this.answers.density] * this.site_pre_vars.geology[this.answers.geology]) * this.income_brackets[this.answers.income.toLowerCase()]

		return ([
			PreSiteTotal + ((PreSiteTotal/100)*this.inflation),
			"((210 * "+this.answers.population+ ") * "+this.site_pre_vars.density[this.answers.density]+" * "+this.site_pre_vars.geology[this.answers.geology]+") * "+this.income_brackets[this.answers.income.toLowerCase()]
		])
	}

	calculateTransport(){
		console.log(this.answers.income.toLowerCase())
		if(this.answers.population < 250000)
		{
			const a = (13880*this.answers.population) + this.transport_vars.lowerPopulation.density[this.answers.density]
			const b = a + 33030000;
			return (
				[b * this.income_brackets[this.answers.income.toLowerCase()],
				"((13880* "+this.answers.population+" ) + "+this.transport_vars.lowerPopulation.density[this.answers.density]+" - 33030000) * "+this.income_brackets[this.answers.income.toLowerCase()],b,this.income_brackets[this.answers.income.toLowerCase()]]
		)
		}
		else
		{
			const a = (18280*this.answers.population) + this.transport_vars.higherPopulation.density[this.answers.density]
			let b;
			if(a < 1720000000)
			{
				b = 1720000000 - a;
			}
			else
			{
				b = a - 1720000000;
			}

			let TransTotal = b * this.income_brackets[this.answers.income.toLowerCase()]
			return ([

				TransTotal + ((TransTotal/100)*this.inflation),
				"((18280* "+this.answers.population+" ) + "+this.transport_vars.higherPopulation.density[this.answers.density]+" - 1720000000) * "+this.income_brackets[this.answers.income.toLowerCase()],b,this.income_brackets[this.answers.income.toLowerCase()]
			]

		)
		}
	}

	calculateUtilities(){

		/*if(this.answers.population < 500000)
		{

		}
		else
		{
			const a = (15920*this.answers.population) + this.utilities_vars.higherPopulation.density[this.answers.density]
			let b;
			if(a < this.utilities_vars.higherPopulation.climate[this.answers.climate])
			{
				b = this.utilities_vars.higherPopulation.climate[this.answers.climate] + a;
			}
			else
			{
				b = a + this.utilities_vars.higherPopulation.climate[this.answers.climate];
			}

			return ([
				(b + 395100000) * this.income_brackets[this.answers.income.toLowerCase()],
				"((15920 * "+this.answers.population+") + "+this.utilities_vars.higherPopulation.density[this.answers.density]+" + "+this.utilities_vars.higherPopulation.climate[this.answers.climate]+" + 395100000) * "+this.income_brackets[this.answers.income.toLowerCase()]
			])
		}*/
let UtilitiesTotal = 0
		if(this.answers.population < 250000)
		{
			const costFor50000 = this.utilities_vars.lowerPopulation.costFor50000[this.answers.density+"-"+this.answers.climate];
			UtilitiesTotal = ((costFor50000 / 50000) * this.answers.population) * this.income_brackets[this.answers.income.toLowerCase()]
			return ([UtilitiesTotal + ((UtilitiesTotal/100)*this.inflation),
				"((17370* "+this.answers.population+" ) + "+this.utilities_vars.lowerPopulation.density[this.answers.density]+" + "+this.utilities_vars.lowerPopulation.climate[this.answers.climate]+" + 589100000) * "+this.income_brackets[this.answers.income.toLowerCase()]
			])
		}
		else if(this.answers.population > 249999 && this.answers.population < 1000000)
		{
			const costFor50000 = this.utilities_vars.lowerPopulation.costFor50000[this.answers.density+"-"+this.answers.climate];
			const costFor1000000 = this.utilities_vars.lowerPopulation.costFor1000000[this.answers.density+"-"+this.answers.climate];
			UtilitiesTotal = ((costFor1000000 - (costFor50000*5)) / 750000 * (this.answers.population -250000) + (costFor50000*5))* this.income_brackets[this.answers.income.toLowerCase()]
			return ([
			UtilitiesTotal + ((UtilitiesTotal/100)*this.inflation),
				"((17370* "+this.answers.population+" ) + "+this.utilities_vars.lowerPopulation.density[this.answers.density]+" + "+this.utilities_vars.lowerPopulation.climate[this.answers.climate]+" + 589100000) * "+this.income_brackets[this.answers.income.toLowerCase()]
			])
		}
		else if(this.answers.population > 999999 && this.answers.population < 2000001)
		{
			const costFor1000000 = this.utilities_vars.lowerPopulation.costFor1000000[this.answers.density+"-"+this.answers.climate];
			UtilitiesTotal = ((costFor1000000 / 1000000) * this.answers.population) * this.income_brackets[this.answers.income.toLowerCase()]
			return ([UtilitiesTotal + ((UtilitiesTotal/100)*this.inflation),
				"((17370* "+this.answers.population+" ) + "+this.utilities_vars.lowerPopulation.density[this.answers.density]+" + "+this.utilities_vars.lowerPopulation.climate[this.answers.climate]+" + 589100000) * "+this.income_brackets[this.answers.income.toLowerCase()]
			])
		}
	}

	calculateLandscaping(){
		let LandscapingTotal = (160 * this.answers.population) * this.income_brackets[this.answers.income.toLowerCase()]
		return ([LandscapingTotal + ((LandscapingTotal/100)*this.inflation),
			"(160 * "+this.answers.population+") * "+this.income_brackets[this.answers.income.toLowerCase()]
		])
	}

	calculateSocialInfrastructure(){
			let SocialInfrastructureTotal = ((24322*this.answers.population) * this.social_infrastructure_vars.demographic[this.answers.demographic]) * this.income_brackets[this.answers.income.toLowerCase()]
		return ([
			SocialInfrastructureTotal + ((SocialInfrastructureTotal/100)*this.inflation),
			"((24322* "+this.answers.population+" ) * "+this.social_infrastructure_vars.demographic[this.answers.demographic]+") * "+this.income_brackets[this.answers.income.toLowerCase()]
		])
	}
}
