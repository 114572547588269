import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';

import BlueHeader from './blue-header';
import DynamicImage from './dynamic-image';
import { populationImages } from './image-details';
import convertToPopulation from './convert-to-population';
import getPopulationBracket from './get-population-bracket.js';
import InputArea from './input-area';



class Question5b extends Component {
 //const { handleSubmit, previousPage } = props

  constructor(props){
    super(props);
    const population = convertToPopulation({area : props.inputValue, density:this.props.density});
    const populationBracket = getPopulationBracket(population);
    this.state = {
      area : props.inputValue ? props.inputValue : null,
      population : population,
      populationBracket : populationBracket
    }
    this.onSliderChange = this.onSliderChange.bind(this)

    this.densityBrackets = {
      low : {
        min : 1250,
        max : 50000
      },
      medium : {
        min : 715,
        max : 28571
      },
      high : {
        min : 313,
        max : 12500
      }
    }

    this.densityLevel = this.densityBrackets[props.density];

  }
  onSliderChange(event, value)
  {
    let population = convertToPopulation({area : value, density:this.props.density});
    this.setState({
      area : value,
      populationBracket : getPopulationBracket(population)
    })
    this.props.change('population', population);
  }

  render(){
    const { populationBracket } = this.state;
 
    let imageDetails = populationImages["default"];

    if(populationImages[populationBracket])
    {
      imageDetails = populationImages[populationBracket];
    }
    let settings = this.densityLevel;
    settings.valueType = "area"
    return (
      <div className="page-content">
      <form onSubmit={this.props.handleSubmit}>
        <BlueHeader title="Area" text="Select the approximate area you hae to build on" />
        <div className="all-80 push-center content">
          <div className="column-group gutters">
            <InputArea settings={settings} width="500" onSliderChange={this.onSliderChange} />
            <div className="all-50 small-100 tiny-100">
              <div style={{position:'relative'}}>
                <DynamicImage imageDetails={imageDetails} transition="img-fade-fast" />
              </div>
            </div>
          </div>
          <div className="column-group gutters">
            <div className="all-50 small-100 tiny-100">
              <p>By selecting an area we caluculate an approximate population for the size of city you are planning. The model has been developed using statistical analysis such that any given population between 50,000 and 2,000,000 can be selected and an output generated.</p>
            </div>
            <div className="all-50 small-100 tiny-100">
            </div>
          </div>
        </div>
      </form>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}
export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question5b))
