import _ from 'lodash';
import React, { Component } from 'react';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

import numberWithCommas from './numberWithCommas';
import StackedBar from  './StackedBar';
import BreakdownListBuilder from  './breakdown-list-builder';
import { iconsTotals, iconsBreakdowns } from '../icons/results';
import setAdjutableWidths from './set-adjustable-widths'
import { ResultsTransition } from '../transitions'
import ContactBar from './contact-bar';

class CompareListBuilder extends Component {
	
	componentDidUpdate(){
		setAdjutableWidths();
	}

	constructor(props){
		super(props)

		this.renderRow = this.renderRow.bind(this);
		this.renderList = this.renderList.bind(this);
	}

	handleClick(e, key){
		if(this.props.loadBreakdown)
		{
			this.props.loadBreakdown(key);
		}
	}

	renderList(){
		const _this = this;
		return _.map(this.props.data, function(item, key){
			
			let breakdownText = "";

			if(key === "socialInfrastructure")
			{
				breakdownText = "SOCIAL INFRASTRUCTURE";
			}
			else if(key === "transport"){
				breakdownText = "TRANSPORT";
			}
			else if(key === "utilities"){
				breakdownText = "UTILITIES";
			}


			let selected = "";
			let styles = {};
	
			if((_this.props.breakdownKey === key) && _this.props.breakdownData)
			{
				selected = "selected";
				styles = {
					cursor:"pointer"
				}
			}

			if(item[Object.keys(item)[0]].breakdown)
			{
				styles = {
					cursor:"pointer"
				}
			}
			return <div className="totals-wrap" key={key}><div className={selected} style={styles} ><div className="adjustable-column-group column-group">{_this.renderRow(item)}</div></div>{_this.props.showBreakdown && _this.props.breakdownKey === key && _this.props.breakdownData && ( _this.renderBreakdownList(breakdownText))}</div>
		})
	}

	renderBreakdownList(text){
		const _this = this;

		const breakdownList = _.map(this.props.breakdownData, function(item, key){
			return <div className="adjustable-column-group column-group" key={key}>{_this.renderBreakdownRow(item)}</div>
		})
		return <div className="breakdown-wrap">{breakdownList}<div className="all-80 push-center"><p className="cta push-center center-text">WE CAN HELP YOU DELIVER THE { text } FOR YOUR CITY </p><ContactBar type="button" onSubmit={this.props.handleContactSubmit} /></div></div>
	}

	renderRow(rowData){
		let _this = this;
		return _.map(rowData, function(item, key){
			
			return (
				<div className="adjustable-column fit-33" key={key}>
					<div className="compareItem">
						<div className="compare-list-wrap">
							<ul className="list-group results-list-wrap">
								<li className={"list-group-item "} key={item.id} onClick={(e) => _this.handleClick(e, item.id)} >
									<div>
										<span className="icon push-left" style={{backgroundImage:`url(${iconsTotals[item.id].primary})`, backgroundRepeat: 'no-repeat' }}></span>
										<span className="label">
											{item.name}
										</span>
										<span className="value push-right">${numberWithCommas(item.value)}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)
		})
	}

	renderBreakdownRow(rowData){
		let _this = this;
		const breakdownJsx =  _.map(rowData, function(item, key){
			
			return (
				
				<div className="adjustable-column fit-33" key={key}>
					<div className="compareItem">
						<div className="compare-list-wrap">
							<ul className="list-group results-list-wrap">
								<li className={"list-group-item "+item.id} key={item.id} onClick={(e) => _this.handleClick(e, item.id)} >
									<div>
										<span className="icon push-left" style={{backgroundImage:`url(${iconsBreakdowns[item.id].tertiary})`, backgroundRepeat: 'no-repeat' }}>
											
										</span>
										<span className="label">{item.name}</span><span className="value push-right">${numberWithCommas(item.value)}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				
			)
		})
		return breakdownJsx;
	}



	render (){
		return (
				<div className="column-group compare-totals-wrap">
					
					{this.renderList()}
					
				</div>
		)
	}
}

export default CompareListBuilder