import React, { Component } from 'react';

import numberWithCommas from './numberWithCommas';
import { iconsSpec, iconsEdit } from '../icons/results';

export default class ModelSpecListResults extends Component{

	constructor(props){
		super(props);
	}

	render(){
		return(
			<ul>
				<li>
					<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['location'].primary})`, backgroundRepeat: 'no-repeat' }}>
					</span>
					<span className="label">Location</span>
					<span className="value push-right">{this.props.location.label}</span>
				</li>
				<li>
					<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['climate'].primary})`, backgroundRepeat: 'no-repeat' }}>
					</span>
					<span className="label">Climate</span>
					<span className="value push-right">{this.props.climate}</span>
				</li>
				<li>
					<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['population'].primary})`, backgroundRepeat: 'no-repeat' }}>
					</span>
					<span className="label">Population</span>
					<span className="value push-right">{numberWithCommas(this.props.population)}</span>
				</li>
				{this.props.area && (
					<li>
						<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['area'].primary})`, backgroundRepeat: 'no-repeat' }}>
						</span>
						<span className="label">Area</span>
						<span className="value push-right">{this.props.area} hectares</span>
					</li>
				)}
				<li>
					<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['density'].primary})`, backgroundRepeat: 'no-repeat' }}>
					</span>
					<span className="label">Density</span>
					<span className="value push-right">{this.props.density}</span>
				</li>
				<li>
					<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['geology'].primary})`, backgroundRepeat: 'no-repeat' }}>
					</span>
					<span className="label">Geology</span>
					<span className="value push-right">{this.props.geology}</span>
				</li>
				<li>
					<span className="icon push-left" style={{backgroundImage:`url(${iconsSpec['demographic'].primary})`, backgroundRepeat: 'no-repeat' }}>
					</span>
					<span className="label">Demographic</span>
					<span className="value push-right">{this.props.demographic}</span>
				</li>
			</ul>
			
		)
	}
}

