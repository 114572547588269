export default function(){
	const contentWidth = document.querySelector('div.blue-header .all-80').offsetWidth; 
	const elements = document.querySelectorAll('div.adjustable-column');
	const elementCount = document.querySelectorAll('.compare .spec-wrap').length;
	const parent = document.querySelectorAll('div.adjustable-column-group');
	const content = document.querySelector('div.content');
	const selected = document.querySelector('div.selected');
	const totalsWraps = document.querySelectorAll('div.totals-wrap');
	let maxElePerPage = 3;
	var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0];

	if(elementCount < 3)
	{
		maxElePerPage = elementCount;
	}
  const pageWidth = w.innerWidth || e.clientWidth || g.clientWidth;
  const rightMargin = pageWidth * .09;
	const newParentWidth = (elementCount * (contentWidth/maxElePerPage) + rightMargin)
	const eleWidth = ((contentWidth - 92) / maxElePerPage);
	const totalsWrapsWidth = (newParentWidth+ (rightMargin * 2))
	const marginLeft = document.querySelector('div.blue-header > .all-80').offsetLeft - 20;

	content.width = newParentWidth+"px";

	for(let index = 0; index < elements.length; index++)
	{
		elements[index].style.width = eleWidth+"px";
	}

	for(let index = 0; index < parent.length; index++)
	{
		parent[index].style.width = newParentWidth+"px";
		parent[index].style.marginLeft = marginLeft+"px";
	}

	for(let index = 0; index < totalsWraps.length; index++)
	{
		totalsWraps[index].width = newParentWidth+"px";
	}
	return {
		pageWidth,
	  rightMargin,
		newParentWidth,
		eleWidth,
		totalsWrapsWidth,
		marginLeft,
		contentWidth
	}
}