import React from 'react';

import Footer from './footer';

export default function Excluded({ history, to, ...props }){

	return (
		<div className="how-to static-content">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="column-group gutters">
				<div className="all-80 xlarge-70 large-70 push-center content">
					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box">
								<h2>What does the Model Exclude?</h2>
								<p>The following are not included in the cities cost model</p>
								<h3>VAT</h3>
								<p>Due to the disparate and idiosyncratic nature of global taxation, the model currently excludes the impacts of any direct or indirect taxes. The nature of these will depend upon local rates and policies at national and regional levels, as well as the availability of any potential state-sponsored incentives. As models becomes further developed, costs should include consideration of the presence and impact of taxes, levies, reliefs and credits on the development and financing process; especially where there is engagement with private sector stakeholders.</p>

								<h3>Inflation from September 2019 prices</h3>
								<p>The project assumes a notional start and completion in September 2019. However it is possible to choose predicted inflation rates up to 2022 from the left menu.</p>
								<p>Although AECOM believes that the expectations reflected in its forward-looking statements and forecasts are reasonable, actual results or outcomes could differ materially from those projected or assumed in any of its forecasts and projections. The data provided is for informational purposes only and is subject to revision. No warranty is made as to the correctness, accuracy or completeness of the information or forecasts made.</p>

								<h3>Site acquisition</h3>
								<p>The acquisition of all land and associated interests</p>

								<h3>Finance charges</h3>
								<p>This covers the interest and other costs on servicing the debt.</p>

								<h3>Residential, commercial and industrial</h3>
								<p>All private and public residential, commercial development including offices and warehousing and all industrial development are excluded together with localised infrastructure associated with these developments.</p>

								<h3>Retail, leisure and cultural development</h3>
								<p>All retail, leisure and cultural development is excluded together with localised infrastructure associated with these developments.</p>

								<h3>Operation and maintenance costs</h3>
								<p>All costs associated with the operation, management and maintenance of the infrastructure.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters">
						<div className="all-50 small-100 tiny-100">
						</div>
						<div className="all-50 small-100 tiny-100">
							<span className="push-right black-btn" onClick={() => history.push({ pathname: "/privacy", state: {transition:"page-fade", duration:700} })}>PRIVACY POLICY</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
