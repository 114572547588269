import React, { Component } from 'react'
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import validate from './validate';
import BlueHeader from './blue-header';
import DynamicImage from './dynamic-image';
import { climateImages } from './image-details';
import InputClimate from './input-climate';

class Question2 extends Component {

  constructor(props){
    super(props)
    
    this.onClimateChange = this.onClimateChange.bind(this);
    
    this.state = {
      climate : props.inputValue ? props.inputValue : ""
    }
  }

  componentDidMount(){
   
  }

  onClimateChange(e){
    this.setState({
      climate : e.target.value
    })
  }

  render(){

    const { handleSubmit } = this.props;
    const { climate } = this.state;
   
    return (
    <div className="page-content ink-flex">
      <form onSubmit={handleSubmit} style={{height:"100%"}}>
        <BlueHeader title="Climate" text="Select city climate zone" />
        <div className="all-80 push-center content">
          <div className="column-group gutters push-center">
            
            <InputClimate onClimateChange={this.onClimateChange} />
            <div className="all-50 small-100 tiny-100">
              <div style={{position:'relative'}}>
                <DynamicImage imageDetails={climateImages[climate ? climate : "arid"]} transition="img-fade"  />

              </div>
            </div>
          </div>
          <div className="column-group">
            <div className="all-50 small-100 tiny-100">
              <p>{climate ? climateImages[climate].text : "Having selected the location of the City, the Model will automatically incorporate the climatic conditions from one of the three options below"}</p>
            </div>
          </div>
        </div>
      </form>
    </div>
    )
  }
  
}
const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}

export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question2))
