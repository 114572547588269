import React from 'react';
import Select from 'react-select';
import { iconsSpec } from '../icons/results'

const renderSelectField = ({ inputValue, name, value, onBlur, options, input, label, type, meta: { touched, error } }) => {
  
  return (
  
  <div>
    <label>{label}</label>
    <div className="custom-select">
      <span className="icon push-left" style={{backgroundImage:`url(${iconsSpec[input.name].secondary})`, backgroundRepeat: 'no-repeat' }}>    
      </span>
      <Select {...input} 
      	options={options} 
      	onBlur={() => input.onBlur(value)} 
        className={`${input.name}`+"-select"}
        onChange={input.onChange}
        value={input.value}
      />
      {touched && error && <span className="error">{error}</span>}
	</div>
  </div>
)}

export default renderSelectField
