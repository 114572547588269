import React from 'react';


import Header from './header';
import Footer from './footer';

export default function Privacy({ history, to, ...props }){

	return (
		<div className="how-to static-content">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="column-group gutters">
				<div className="all-80 xlarge-70 large-70 push-center content">
					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box">
								<h2>Privacy</h2>
								<p><strong>We have updated our Privacy Policy to include a link to AECOM’s Global Data Privacy policy for personal data received from third parties by AECOM. We have not made any material changes to how we treat data collected through the use of this site.</strong></p>
								<p>AECOM and its family of companies ("AECOM," "we," or "us") recognizes the importance of protecting the privacy of your information. This Privacy Policy governs the collection of your data received through this website or other online service (collectively, the “Services”) that links or refers to it. Any personal information received by AECOM through this site is treated according to AECOM’s <a href="https://www.aecom.com/global-privacy-statement/">Global Data Privacy Policy</a>.</p>

								<h3>INFORMATION WE COLLECT</h3>
								<h4>Information You Provide Directly</h4>
								<p>We collect information that you provide us directly, including when you contact us using a “Contact Us” form on the app, send us an e-mail, or create a city model.  For example, if you send us e-mail, your email address and the contents of your message will be collected. This includes text characters and any audio, video, and graphic information formats included in the message.</p>

								<h4>Information We Collect Automatically</h4>
								<p>Our servers automatically log information about your use of and visits to the Services, including through the use of cookies, Web beacons, and similar technologies.  For example, we may collect your IP address, the type of web browser and operating system used to access the Services, the time and duration of your visits, and information about the content and webpages you view and the features you access on the Services.</p>

								<h4>Information from Third Parties</h4>
								<p>We may receive additional information about you from other publically and commercially available sources, as permitted law.</p>
								<p>We may combine all of the information we collect or receive about you and use or disclose it in the manner described in this Privacy Policy.</p>

								<h3>USE OF YOUR INFORMATION</h3>
								<p>We use the information we collect and receive to provide and improve the Services and our other products, services, and offerings, as well as to provide you with information you request or respond to inquiries we receive from you or your company or organization.  We also may use such information to analyze how users use the Services and related analysis, research, reporting, and troubleshooting and as we believe is necessary or appropriate to protect, enforce, or defend legal rights, privacy, safety or property, whether our own or that of our employees or agents or others, and to comply with applicable law.</p>

								<h3>SHARING YOUR INFORMATION</h3>
								<p>To the extent consistent with applicable law, we may share your information in the following circumstances:</p>
								<ul>
									<li>with our subsidiaries and affiliates;</li>
									<li>with affiliated and third-party service providers, such as companies that provide us technical support, data hosting, and other services;</li>
									<li>with other parties when required by law or as necessary to provide or protect our services, products, and other offerings.  For example, we may need to disclose information with other parties to comply with the law or respond to legal process or a request for cooperation by a government entity or law enforcement; to prevent fraud or verify and enforce compliance with the policies and terms governing our Services; and to protect the rights, property, or safety of us, or any of our respective affiliates, business partners, customers or employees;</li>
									<li>in the event we sell or transfer all or a portion of a business or assets to a third party, such as in connection with a merger or in the event of a bankruptcy reorganization or liquidation; and</li>
									<li>when you or your company or organization consent to or request such sharing. </li>
								</ul>
								<p>The information you provide may be transferred outside your country to another country that does not have similar data protection legislation and may provide a lower level of protection for your information.  By using any of the Services or providing us with your information you consent such transfers.</p>
								<h3>COOKIES, WEB BEACONS, AND SIMILAR TECHNOLOGIES</h3>
								<p>The Services — as well as certain third parties that provide content and other functionality on the Services — use a variety of technologies to learn more about how people use the Services and the Internet.  This section provides more information about some of those technologies and how they work.</p>
								<ul>
									<li>
										Cookies.  Cookies are small text files used to store information about users on the users’ own computer.   Cookies may be used to recognize you as the same user across different visits to the website.  Knowing how a user is using the Services through cookies enables us to tailor our content and Services to more effectively meet visitors’ needs. It also enables us to improve the quality of your visit by making sure that the Services are properly formatted for your computer and web browser. Some Internet browsers can be configured to warn you each time a cookie is being sent or to refuse cookies completely.  Refer to your browser help menu for more information.  You can also manage cookie tracking directly through the third-party service providers that we use:
										<ul>
											<li>Google Analytics: You may prevent your data from being used by Google Analytics by downloading and installing the Google Analytics Opt-out Browser Add-on, available at <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">https://tools.google.com/dlpage/gaoptout/</a>.  Google’s ability to use and share information collected by Google Analytics about your visits to this Site is restricted by the Google Analytics Terms of Service, available at <a href="http://www.google.com/analytics/terms/us.html" target="_blank">http://www.google.com/analytics/terms/us.html</a>, and the Google Privacy Policy, available at <a href="http://www.google.com/policies/privacy/" target="_blank">http://www.google.com/policies/privacy/</a>. To learn more about how Google collects and processes data in connection with Google Analytics, visit <a href="http://www.google.com/policies/privacy/partners/" target="_blank">http://www.google.com/policies/privacy/partners/</a>.</li>
											<li>Wistia: Information collected by Wistia in connection with videos played on this website is covered by Wistia’s Privacy Policy, available at <a href="https://wistia.com/privacy" target="_blank">https://wistia.com/privacy</a>.</li>
										</ul>
									</li>
									<li>Other local storage.  Local Shared Objects (also referred to as “Flash cookies”) and HTML5 local storage are similar to cookies in that they are stored on your computer and can be used to store certain information about your activities and preferences.  These objects are stored in different parts of your computer from ordinary browser cookies, however.  Many Internet browsers allow you to disable HTML5 local storage or delete information contained in HTML5 local storage using browser controls.</li>
									<li>Web beacons.  Web beacons can be embedded in web pages, videos, or emails, and can allow a web server to read certain types of information from your browser, check whether you have viewed a particular web page or email message, and determine, among other things, the time and date on which you viewed the Web beacon, the IP address of your computer, and the URL of the web page from which the Web beacon was viewed.</li>
								</ul>

								<h3>PROTECTION OF INFORMATION</h3>
								<p>AECOM uses industry standard security measures to protect the information under our control, including firewalls and other security measures.  Although the inherent dangers of the Internet mean we cannot guarantee the protection of your information against loss, misuse, or alteration, AECOM takes steps to protect your personal information.</p>

								<h3>CHILDREN’S INFORMATION</h3>
								<p>The Services are intended to be used by adults and corporate entities interested in AECOM.  They are not intended for children, and AECOM does not knowingly collect or store personal information about children under the age of 13.</p>

								<h3>CHANGES TO THIS PRIVACY POLICY</h3>
								<p>Due to changes in technology, legal requirements, and our Services, we may revise this Privacy Policy from time to time. We will notify you of changes by posting changes here, or by other appropriate means.  We suggest that you bookmark this page so that you may check periodically to see if any changes have been made.</p>

								<h3>YOUR CALIFORNIA PRIVACY RIGHTS</h3>
								<p>California residents are entitled once a year, free of charge, to request and obtain certain information regarding AECOM’s disclosure of certain categories of personal information to third parties for their direct marketing purposes in the preceding calendar year.  AECOM does not disclose personal information to third parties for direct marketing purposes.</p>

								<h3>DO NOT TRACK SIGNAL</h3>
								<p>Some web browsers may transmit “do-not-track” signals to the websites with which the user communicates.  We do not currently take action in response to those signals.  If an industry standard on responding to such signals is established and accepted, we may reassess how to respond to those signals.</p>

								<h3>QUESTIONS AND CONTACT INFORMATION</h3>
								<p>If you have any questions about this statement or our handling of personal information please contact us by e-mail at <a href="mailto:privacyquestions@AECOM.com">privacyquestions@AECOM.com</a>.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters">
						<div className="all-50 small-100 tiny-100">
						</div>
						<div className="all-50 small-100 tiny-100">
							<span className="push-right black-btn" onClick={() => history.push({ pathname: "/eula", state: {transition:"page-fade", duration:700} })}>SOFTWARE AGREEMENT</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
