import icon1 from '../images/icons_yellow/icons_geology.png';
import icon2 from '../images/icons_yellow/icons_density.png';
import icon3 from '../images/icons_yellow/icons_social.png';
import icon4 from '../images/icons_yellow/icons_transport.png';
import icon5 from '../images/icons_yellow/icons_sitePrep.png';
import icon6 from '../images/icons_yellow/icons_utilities.png';
import icon7 from '../images/icons_yellow/icons_demographic.png';
import icon8 from '../images/icons_yellow/icons_population.png';
import icon9 from '../images/icons_yellow/icons_climate.png';
import icon10 from '../images/icons_yellow/icons_totalcost.png';
import icon11 from '../images/icons_yellow/icons_prof_fees.png';
import icon12 from '../images/icons_yellow/icons_contingency.png';
import icon13 from '../images/icons_yellow/icons_location.png';
import icon14 from '../images/icons_yellow/icons_public_sector.png';
import icon15 from '../images/icons_yellow/icons_cultural.png';
import icon16 from '../images/icons_yellow/icons_education.png';
import icon17 from '../images/icons_yellow/icons_medical.png';
import icon18 from '../images/icons_yellow/icons_cityManagement.png';
import icon19 from '../images/icons_yellow/icons_landscaping.png';
import icon20 from '../images/icons_yellow/icons_per_capita.png';
import icon21 from '../images/icons_yellow/icons_sports.png';
import icon22 from '../images/icons_yellow/icons_emergency.png';
import icon23 from '../images/icons_yellow/icons_religion.png';
import icon24 from '../images/icons_yellow/icons_maratime.png';
import icon25 from '../images/icons_yellow/icons_publicTransport.png';
import icon26 from '../images/icons_yellow/icons_privateTransport.png';
import icon27 from '../images/icons_yellow/icons_aviation.png';
import icon28 from '../images/icons_yellow/icons_power.png';
import icon29 from '../images/icons_yellow/icons_heating.png';
import icon30 from '../images/icons_yellow/icons_potablewater.png';
import icon31 from '../images/icons_yellow/icons_sewage.png';
import icon32 from '../images/icons_yellow/icons_surfacewater.png';
import icon33 from '../images/icons_yellow/icons_telecoms.png';
import icon34 from '../images/icons_yellow/icons_solidWaste.png';


import iconBlack1 from '../images/icons_black/icons_black_geology.png';
import iconBlack2 from '../images/icons_black/icons_black_density.png';
import iconBlack3 from '../images/icons_black/icons_black_social.png';
import iconBlack4 from '../images/icons_black/icons_black_transport.png';
import iconBlack5 from '../images/icons_black/icons_black_sitePrep.png';
import iconBlack6 from '../images/icons_black/icons_black_utilities.png';
import iconBlack7 from '../images/icons_black/icons_black_demographic.png';
import iconBlack8 from '../images/icons_black/icons_black_population.png';
import iconBlack9 from '../images/icons_black/icons_black_climate.png';
import iconBlack10 from '../images/icons_black/icons_black_totalcost.png';
import iconBlack11 from '../images/icons_black/icons_black_prof_fees.png';
import iconBlack12 from '../images/icons_black/icons_black_contingency.png';
import iconBlack13 from '../images/icons_black/icons_black_location.png';
import iconBlack14 from '../images/icons_black/icons_black_public_sector.png';
import iconBlack15 from '../images/icons_black/icons_black_cultural.png';
import iconBlack16 from '../images/icons_black/icons_black_education.png';
import iconBlack17 from '../images/icons_black/icons_black_medical.png';
import iconBlack18 from '../images/icons_black/icons_black_cityManagement.png';
import iconBlack19 from '../images/icons_black/icons_black_landscaping.png';
import iconBlack20 from '../images/icons/icons-20.png';
import iconBlack21 from '../images/icons_black/icons_black_sports.png';
import iconBlack22 from '../images/icons_black/icons_black_emergency.png';
import iconBlack23 from '../images/icons_black/icons_black_religion.png';
import iconBlack24 from '../images/icons_black/icons_black_maratime.png';
import iconBlack25 from '../images/icons_black/icons_black_publicTransport.png';
import iconBlack26 from '../images/icons_black/icons_black_privateTransport.png';
import iconBlack27 from '../images/icons_black/icons_black_aviation.png';
import iconBlack28 from '../images/icons_black/icons_black_power.png';
import iconBlack29 from '../images/icons_black/icons_black_heating.png';
import iconBlack30 from '../images/icons_black/icons_black_potablewater.png';
import iconBlack31 from '../images/icons_black/icons_black_sewage.png';
import iconBlack32 from '../images/icons_black/icons_black_surfacewater.png';
import iconBlack33 from '../images/icons_black/icons_black_telecoms.png';
import iconBlack34 from '../images/icons_black/icons_black_solidWaste.png';

import iconTertiary15 from '../images/icons_orange_cultural.png';
import iconTertiary16 from '../images/icons_blue_education.png';
import iconTertiary17 from '../images/icons_yellow_medical.png';
import iconTertiary18 from '../images/icons_red_cityManagement.png';
import iconTertiary21 from '../images/icons_grey_sports.png';
import iconTertiary22 from '../images/icons_green_emergency.png';
import iconTertiary23 from '../images/icons_brown_religion.png';
import iconTertiary24 from '../images/icons_blue_maratime.png';
import iconTertiary25 from '../images/icons_red_publicTransport.png';
import iconTertiary26 from '../images/icons_grey_privateTransport.png';
import iconTertiary27 from '../images/icons_orange_aviation.png';
import iconTertiary28 from '../images/icons_blue_power.png';
import iconTertiary29 from '../images/icons_orange_heating.png';
import iconTertiary30 from '../images/icons_red_potablewater.png';
import iconTertiary31 from '../images/icons_grey_sewage.png';
import iconTertiary32 from '../images/icons_orange_surfacewater.png';
import iconTertiary33 from '../images/icons_green_telecoms.png';
import iconTertiary34 from '../images/icons_brown_solidWaste.png';

import iconEdit from '../images/icons_edit.png';
import iconCopy from '../images/icons_duplicate.png';
import iconTick from '../images/icons_tick_1.png';
import iconDelete from '../images/icons_delete.png';
import iconUserYellow from '../images/user-yellow.png';
import iconUserWhite from '../images/user-white.png';
import iconUserBlack from '../images/user-black.png';

export const iconsSpec = {
    population:{
        primary : icon8,
        secondary : iconBlack8
    },
    area:{
        primary : icon8,
        secondary : iconBlack8
    },
    climate:{
        primary : icon9,
        secondary : iconBlack9
    },
    location:{
        primary : icon13,
        secondary : iconBlack13
    },
    density:{
        primary : icon2,
        secondary : iconBlack2
    },
    demographic:{
        primary : icon7,
        secondary : iconBlack7
    },
    geology:{
        primary : icon1,
        secondary : iconBlack1
    }
};

export const iconsTotals = {
    totalCost:{
        primary : icon10,
        secondary : iconBlack10
    },
    perCapita:{
        primary : icon20,
        secondary : iconBlack20
    },
    socialInfrastructure:{
        primary : icon3,
        secondary : iconBlack3
    },
    transport:{
        primary : icon4,
        secondary : iconBlack4
    },
    sitePrep:{
        primary : icon5,
        secondary : iconBlack5
    },
    utilities:{
        primary : icon6,
        secondary : iconBlack6
    },
    professionalFees:{
        primary : icon11,
        secondary : iconBlack11
    },
    contingency:{
        primary : icon12,
        secondary : iconBlack12
    },
    landscaping:{
        primary : icon19,
        secondary : iconBlack19
    }
};

export const iconsBreakdowns = {
    publicSector:{
        primary : icon14,
        secondary : iconBlack14
    },
    culturalAndCommunitySpace:{
        primary : icon15,
        secondary : iconBlack15,
        tertiary : iconTertiary15
    },
    education:{
        primary : icon16,
        secondary : iconBlack16,
        tertiary : iconTertiary16
    },
    health:{
        primary : icon17,
        secondary : iconBlack17,
        tertiary : iconTertiary17
    },
    cityManagement:{
        primary : icon18,
        secondary : iconBlack18,
        tertiary : iconTertiary18
    },
    sportsAndLeisure:{
        primary : icon21,
        secondary : iconBlack21,
        tertiary : iconTertiary21 
    },
    emergency : {
        primary : icon22,
        secondary : iconBlack22,
        tertiary : iconTertiary22
    },
    religiousRequirement : {
        primary : icon23,
        secondary : iconBlack23,
        tertiary : iconTertiary23
    },
    maritime : {
        primary : icon24,
        secondary : iconBlack24,
        tertiary : iconTertiary24
    },
    publicTransport : {
        primary : icon25,
        secondary : iconBlack25,
        tertiary : iconTertiary25
    },
    privateTransport : {
        primary : icon26,
        secondary : iconBlack26,
        tertiary : iconTertiary26
    },
    aviation : {
        primary : icon27,
        secondary : iconBlack27,
        tertiary : iconTertiary27
    },
    power : {
        primary : icon28,
        secondary : iconBlack28,
        tertiary : iconTertiary28
    },
    districtHeating : {
        primary : icon29,
        secondary : iconBlack29,
        tertiary : iconTertiary29
    },
    potableWater : {
        primary : icon30,
        secondary : iconBlack30,
        tertiary : iconTertiary30
    },
    sewerage : {
        primary : icon31,
        secondary : iconBlack31,
        tertiary : iconTertiary31
    },
    surfaceWater : {
        primary : icon32,
        secondary : iconBlack32,
        tertiary : iconTertiary32
    },
    telecommunication : {
        primary : icon33,
        secondary : iconBlack33,
        tertiary : iconTertiary33
    },
    solidWaste : {
        primary : icon34,
        secondary : iconBlack34,
        tertiary : iconTertiary34
    }
};

export const iconsEdit = {
    edit : {
        primary : iconEdit
    },
    copy : {
        primary : iconCopy
    },
    tick : {
        primary : iconTick
    },
    delete : {
        primary : iconDelete
    }
}

export const iconsInfo = {
    user : {
        primary : iconUserYellow,
        secondary : iconUserWhite,
        tertiary : iconUserBlack
    }
}