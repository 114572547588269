import React from 'react';

import LoginForm from './login-form';
import Footer from './footer';

export default function LoginPage({ history, to, ...props }){
	return (
		<div className="front-page login">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
				<LoginForm handleLogin={props.handleLogin} />
			<Footer />
		</div>
	)
}