import React, { Component } from 'react';
import Modal from 'react-modal';

export default class DeeteBtn extends Component{
	
	constructor(props){
		super(props)

		this.openDeleteModal = this.openDeleteModal.bind(this);
		this.closeDeleteModal = this.closeDeleteModal.bind(this);
		this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);
		this.state = {
			modalDeleteIsOpen: false
		}
	}

	openDeleteModal(modelKey) {
		this.setState({modalDeleteIsOpen: true});
  }

  closeDeleteModal(e) {
  	if(e)
  	{
  		e.preventDefault();
  	}
  	
  	this.setState({modalDeleteIsOpen: false});
  }

	handleDeleteSubmit(modelKey){
		this.props.handleDeleteClick(modelKey);
		this.closeDeleteModal();
	}

	render(){
		const { modelKey } = this.props;
		return (
			<span>
				<span className="edit icon push-right" style={{backgroundImage:`url(${this.props.iconPath})`, backgroundRepeat: 'no-repeat' }} onClick={() => {this.openDeleteModal(modelKey)}}>
				
				</span>
				<Modal
	        isOpen={this.state.modalDeleteIsOpen}
	        onAfterOpen={this.afterOpenModal}
	        onRequestClose={this.closeDeleteModal}
	        contentLabel="Example Modal"
	        ariaHideApp={false}
	        className="delete-modal"
	        overlayClassName="delete-overlay"
	      >
	      	<div className="ink-grid">
	      		<div className="column-group gutters">
	        		<div className="all-80 push-center">
	        			<div className="modal-content">
				          <h2>Delete</h2>
				          
				          <p>Are you sure you want to delete model {modelKey}</p>
				          <form onSubmit={() => {this.handleDeleteSubmit(modelKey)}}>
				            <button className="push-right black-btn">Delete</button>
				            <button onClick={this.closeDeleteModal} className="push-right black-btn">Cancel</button>
				          </form>
				        </div>
			        </div>
			      </div>
	         </div>
	      </Modal>
	    </span>
			
		)

	}
	
}

