import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import validate from './validate';
import BlueHeader from './blue-header';
import DynamicImage from './dynamic-image';
import { demographicImages } from './image-details';
import InputDemographic from './input-demographic';



class Question7 extends Component {
  constructor(props){
    super(props)
    this.state = {
      demographic : false
    }
    this.onDemographicChange = this.onDemographicChange.bind(this);
  }
  
  onDemographicChange(e){
    this.setState({
      demographic : e.target.value
    })
  }

  render(){
    const { handleSubmit } = this.props;
    const { demographic } = this.state;
    return (
    <div className="page-content">
      <form onSubmit={handleSubmit}>
        <BlueHeader title="Demographic" text="Select demographic of the city" />
        <div className="all-80 push-center content content-demographic">
          <div className="column-group gutters">
            <InputDemographic onDemographicChange={this.onDemographicChange} />
            <div className="all-50 small-100 tiny-100">
              <div style={{position:'relative'}}>
                <DynamicImage imageDetails={demographicImages[demographic ? demographic : "Standard demographic"]} transition="img-fade"  />
              </div>
            </div>
          </div>
          <div className="column-group gutters">
            <div className="all-50 small-100 tiny-100">
            {!demographic && (
            
                <p>This reflects the age profile of the inhabitants of the City and the selection of each of the options impacts on social infrastructure requirements.</p>
            )}
            {demographic === "Young population" && (
            <span>
              <p>A very young population that is disproportionate to the working age population. </p>
                <ul>
                  <li>Young (&lt;20): 40%</li>
                  <li>Working (20-65): 57% </li>
                  <li>Old (&gt;65): 3%</li>
                </ul>
            </span>
            )}
            {demographic === "Standard demographic" && (
            <span>
              <p>A large working age population between 20 and 65 years old. However, there shouldalso be a significant portion that is a young dependent who will eventually move into the workforce.</p>
              <ul>
                <li>Young (&lt;20): 25%</li>
                <li>Working (20-65): 64%</li>
                <li>Old (&gt;65): 11%</li>
              </ul>
            </span>
            )}
            {demographic === "Ageing population" && (
              <span>
                <p>A very large elderly population that is disproportionate to the young and working age population. </p>
                
                <ul>
                <li>Young (&lt;20): 18%</li>
                <li>Working (20-65): 56%</li>
                <li>Old (&gt;65): 26%</li>
              </ul>
              </span>
            )}
            </div>
            <div className="all-50 small-100 tiny-100">
              
            </div>
          </div>
        </div>
      </form>
    </div>


    
    )
  }
}

export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(Question7)
