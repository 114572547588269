const densityBraclets = {
	high : 160,
	medium : 70,
	low : 40
}

export default function(answers, inSquareMeters){
	const areaHa = inSquareMeters ? answers.area / 10000 : answers.area;
	const population = densityBraclets[answers.density] * areaHa;
	return population;
}