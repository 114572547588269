import React from 'react';
import { Field } from 'redux-form';

import renderSliderField from './renderSliderField';

export default function InputArea(props){
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span>{error}</span> : false;

	return (
		<div className="all-50 small-100 tiny-100 question5b">
      <Field
        type="text"
        name="area"
        onChange={props.onSliderChange}
        component={renderSliderField}
        settings={props.settings}
        width={props.width}
      />
    </div>
	)
}