import { SAVE_INFO } from '../actions';
import { GET_INFO } from '../actions';
import { SET_INFLATION } from '../actions';



export default function(state = {}, action) {
  switch (action.type) {

    case SAVE_INFO:
    		let saveState = Object.assign({}, state);
				saveState[action.payload.key] = action.payload.value;
    		return saveState;

    case GET_INFO:

    	return state;


    default:
      return state
  }
}
