import convertToPopulation from '../components/convert-to-population';
import { GET_RESULTS } from '../actions'
	
export default function(state = {}, action) {
  switch (action.type) {
    
    case GET_RESULTS:
    	let answers = '';
    	if(action.payload.population)
    	{
    
    		answers = action.payload
    	}
    	else
    	{	
    		answers = {...action.payload, population : convertToPopulation(action.payload)}
    	}

	    return answers
     
    default:
      return state
  }
}



