import axios from 'axios';
export const GET_RESULTS = 'get_results';
export const FETCH_CLIMATE_DATA = 'fetch_climate_data';
export const SET_COMPARISIONS = 'set_comparisons';
export const SAVE_MODEL = 'save_model';
export const GET_MODELS = 'get_model';
export const UPDATE_ANSWERS = "update_answers";
export const SAVE_INFO = "save_info";
export const GET_INFO = "get_info";
export const UPDATE_MODEL_INFO = "update_model_info";
export const DELETE_MODEL  = "delete_model";
export const GET_COUNTRY  = "get_country";
export const SET_INFLATION  = "set_inflation";

export function getResults(answers) {
  return {
  	type : GET_RESULTS,
  	payload : answers
  }
}

export function setInflation(inflation) {
  console.log("setInflation", inflation)
  return {
  	type : SET_INFLATION,
  	payload : inflation
  }
}

export function setComparisons(models) {
  return {
  	type : SET_COMPARISIONS,
  	payload : {models:models}
  }
}

export function saveModel(model) {
  return {
  	type : SAVE_MODEL,
  	payload : model
  }
}

export function getModels() {
	return {
  	type : GET_MODELS,
  	payload : true
  }
}

export function updateAnswers(updates) {
	return {
  	type : UPDATE_ANSWERS,
  	payload : updates
  }
}

export function saveInfo(info) {
  return {
    type : SAVE_INFO,
    payload : info
  }
}

export function getInfo(id) {
  return {
    type : GET_INFO,
    payload : id
  }
}

export function updateModelInfo(data) {
  return {
    type : UPDATE_MODEL_INFO,
    payload : data
  }
}

export function deleteModel(data) {
  return {
    type : DELETE_MODEL,
    payload : data
  }
}

const googleAPIKey = "AIzaSyAv9Jg2m0_FbfcuASkvikOW7qDx_zuGkoE";
const googleURL = "https://maps.googleapis.com/maps/api/geocode/json?latlng=";
export function getCountry(latLng) {
  const request = axios.post(`${googleURL}${latLng.lat},${latLng.lng}&key=${googleAPIKey}`);

  return {
    type : GET_COUNTRY,
    payload : request
  }
}
