export default function numberWithCommas(x){
	if(x === 0)
	{
		return "0";
	}
	else
	{
		if(x)
		{
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
}