import _ from 'lodash';
import React, { Component } from 'react';
import numberWithCommas from './numberWithCommas';
import StackedBar from  './StackedBar';
import { iconsBreakdowns } from '../icons/results';

class BreakdownListBuilder extends Component {
	componentDidMount(){
		this.currency = "$";
	}

	handleClick(e, key){
		if(this.props.loadBreakdown)
		{
			this.props.loadBreakdown(key);
		}
	}

	renderList(){
		const data = this.props.data;
		
		return _.map(data, (datum, key) => {
			return (
				<li className={"list-group-item "+key} key={key} >
					<span className="icon push-left" style={{backgroundImage:`url(${iconsBreakdowns[key].tertiary})`, backgroundRepeat: 'no-repeat' }}>
						
					</span>
					<span className="label">{datum.name}</span><span className="value push-right">${numberWithCommas(datum.value)}</span>
				</li>
			);
	
		});	
	}

	render (){

		return (
			
			<ul className="list-group breakdown-list-wrap">
				{this.renderList()}
			</ul>
			
		)
	}
}



export default BreakdownListBuilder