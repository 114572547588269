import React, { Component } from 'react';

const handle = {
  position: "absolute",
  transform: 'translate(-50%, -50%)',
  width: "35px",
  height: "35px",
  cursor: "pointer",
  borderRadius: "50%",
  border: "solid 1px #000",
  backgroundColor: "#ffe400"
};

export default class Handle extends Component {
  render(){
    const handleStyle = Object.assign({ left: `${this.props.offset}%` }, handle);
    
    return(
      <div style={handleStyle} className="slider-handle">
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  }
}