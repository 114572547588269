import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import resultsReducer from './reducer_results';
import comparisionsReducer from './reducer_comparisions';
import modelsReducer from './reducer_models';
import modelsInfo from './reducer_info';
import inflationReducer from './reducer_inflation';
import countryReducer from './reducer_country';

const reducer = combineReducers({
  form: reduxFormReducer,
  answers : resultsReducer,
  comparisions : comparisionsReducer,
  models : modelsReducer,
  info : modelsInfo,
  country : countryReducer,
  inflation : inflationReducer
})

export default reducer;
