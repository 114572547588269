import React, {Component} from 'react';

import { FormErrors } from './FormErrors';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import uniqid from 'uniqid';

import { iconsInfo } from '../icons/results';

class LoginForm extends Component {

  constructor(props, context) {
     super(props);
     this.state = {
       value: '',
       content: false,
       thanks: false,
       email: '',
       userID: uniqid(),
       formErrors: {
         email: ''
       },
       emailValid: false,

       formValid: false
     };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

   }

   handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
   if(event.target.value > 0){

   }

 }

 handleUserInput = (event) => {

   const target = event.target;
   const Fieldvalue = target.type === 'checkbox'
     ? target.checked
     : target.value;
   const Fieldname = target.name;

   this.setState({[Fieldname]: value});

   if (event.target.value.length > 0) {
     this.setState({content: true});
   } else {
     this.setState({content: false});
   }

   const name = event.target.name;
   const value = event.target.value;
   this.setState({
     [name]: value
   }, () => {
     this.validateField(name, value)
   });
 } 

 validateField(fieldName, value) {
  let fieldValidationErrors = this.state.formErrors;
  let emailValid = this.state.emailValid;


  switch (fieldName) {
   case 'email':
     emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
     fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
     break;
     case 'fullname':

   default:
     break;
  }
  this.setState({
   formErrors: fieldValidationErrors,
   emailValid: emailValid

  }, this.validateForm);
 }


 validateForm() {
   this.setState({
     formValid: this.state.emailValid
   });
 }

 errorClass(error) {
   return (
     error.length === 0
     ? ''
     : 'has-error');
 }


 handleSubmit(event) {

  let data = {
  'email': this.state.email,
  'userID': this.state.userID
  };
 this.setState({thanks: true});


let Encoded = Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
   
   axios({
       url: '	https://communications.aecom.com/l/377462/2018-11-21/f58wyl?' + Encoded ,
       method:'post',
       adapter: jsonpAdapter,
       jsonpCallback: function(response){console.log(response)},
       callbackParamName: 'callback' // optional, 'callback' by default
   }).then((response) => {
    if(response.data.result === 'done')
    {
      this.props.handleLogin(data)
    }
    else
    {
      this.props.handleLogin(false)
    }
   })
   event.preventDefault("success");
 }


  render() {

    return(
      <form id="contact-form" method="post" onSubmit={this.handleSubmit}>
        <div className="all-70 push-center content">
          <div className="column-group gutters">
            <div className="grey-text-box">
              <div className="all-100">
                <div className={this.state.thanks ? "hide" : ""}>
                  <div className="column-group gutters">
                    <div className="all-50 medium-100 small-100 tiny-100">
                      <h2 className="underline">Login</h2>
                    </div>
                    <div className="all-50 medium-100 small-100 tiny-100">
                     
                    </div>
                  </div>
                  <div className="column-group gutters">
                    <div className="all-50 medium-100 small-100 tiny-100">
                      <div className="group">
                        <p><span className="icon push-left" style={{backgroundImage:`url(${iconsInfo["user"].primary})`, backgroundRepeat: 'no-repeat' }}></span><input type="text" placeholder="Email" name="email" id="email" required="required" value={this.state.email} onChange={this.handleUserInput} onBlur={this.handleUserInput} /></p>
                        <span id="emailbar" className="bar"></span>
                      </div>
                      <div className="group">
                        <input type="hidden" name="userID" id="userID" value={this.state.userID} onChange={this.handleUserInput} onBlur={this.handleUserInput}/>
                      </div>
                      <div>
                        <FormErrors formErrors={this.state.formErrors} />
                      </div>
                    </div>
                    <div className="all-50 medium-100 small-100 tiny-100 login-text">
                      <p>Please let us know your email address.</p>
                      <p>We will only use your details to keep you updated about future versions of the model.</p>
                      <p>If you wish to discuss any of the outputs from the model or would like us to help you deliver your city please contact us through the app using the contact button in the left hand menu.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column-group gutters">
            <div className="all-50 medium-100 small-100 tiny-100">
            </div>
            <div className="all-50 medium-100 small-100 tiny-100">
              <button className="grey-btn" type="submit" id="submit" disabled={!this.state.formValid} >START MODELING</button>
              {/*<Link to="/modeling" className="grey-btn">Start modeling</Link>*/}
            </div>
          </div>
        </div>
      </form>

    );
  }
}






export default LoginForm;
