import React from 'react';

import Footer from './footer';

export default function Eula({ history, to, ...props }){
	
	return (
		<div className="how-to static-content">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="column-group gutters">
				<div className="all-80 xlarge-70 large-70 push-center content">
					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box">
								
								<h2>Software Licence</h2>
								<p>Cities cost model 1</p>
								<p>Copyright &copy; 2018 AECOM</p>
								
								<h3>*** END USER LICENSE AGREEMENT ***</h3>
								<p><strong>IMPORTANT: PLEASE READ THIS LICENSE CAREFULLY BEFORE USING THIS SOFTWARE.</strong></p>
								
								<h4>2. DISTRIBUTION</h4>
								<p>Cities cost model 1 and the license herein granted shall not be copied, shared, distributed, re-sold, offered for re-sale, transferred or sub-licensed in whole or in part except that you may make one copy for archive purposes only. For information about redistribution of Cities cost model 1 contact AECOM.</p>

								<h4>3. USER AGREEMENT</h4>
								<h5>3.1 Use</h5>
								<p>Your license to use Cities cost model 1 is limited to the number of licenses purchased by you. You shall not allow others to use, copy or evaluate copies of Cities cost model 1.</p>
								
								<h5>3.2 Use Restrictions</h5>
								<p>You shall use Cities cost model 1 in compliance with all applicable laws and not for any unlawful purpose. Without limiting the foregoing, use, display or distribution of Cities cost model 1 together with material that is pornographic, racist, vulgar, obscene, defamatory, libelous, abusive, promoting hatred, discriminating or displaying prejudice based on religion, ethnic heritage, race, sexual orientation or age is strictly prohibited.</p>
								<p>Each licensed copy of Cities cost model 1 may be used on one single computer location by one user. Use of Cities cost model 1 means that you have loaded, installed, or run Cities cost model 1 on a computer or similar device. If you install Cities cost model 1 onto a multi-user platform, server or network, each and every individual user of Cities cost model 1 must be licensed separately.</p>
								<p>You may make one copy of Cities cost model 1 for backup purposes, providing you only have one copy installed on one computer being used by one person. Other users may not use your copy of Cities cost model 1 . The assignment, sublicense, networking, sale, or distribution of copies of Cities cost model 1 are strictly forbidden without the prior written consent of AECOM. It is a violation of this agreement to assign, sell, share, loan, rent, lease, borrow, network or transfer the use of Cities cost model 1. If any person other than yourself uses Cities cost model 1 registered in your name, regardless of whether it is at the same time or different times, then this agreement is being violated and you are responsible for that violation!</p>

								<h5>3.3 Copyright Restriction</h5>
								<p>This Software contains copyrighted material, trade secrets and other proprietary material. You shall not, and shall not attempt to, modify, reverse engineer, disassemble or decompile Cities cost model 1. Nor can you create any derivative works or other works that are based upon or derived from Cities cost model 1 in whole or in part.</p>
								<p>AECOM's name, logo and graphics file that represents Cities cost model 1 shall not be used in any way to promote products developed with Cities cost model 1 . AECOM retains sole and exclusive ownership of all right, title and interest in and to Cities cost model 1 and all Intellectual Property rights relating thereto.</p>
								<p>Copyright law and international copyright treaty provisions protect all parts of Cities cost model 1, products and services. No program, code, part, image, audio sample, or text may be copied or used in any way by the user except as intended within the bounds of the single user program. All rights not expressly granted hereunder are reserved for AECOM.</p>
								
								<h5>3.4 Limitation of Responsibility</h5>
								<p>You will indemnify, hold harmless, and defend AECOM , its employees, agents and distributors against any and all claims, proceedings, demand and costs resulting from or in any way connected with your use of AECOM's Software.</p>
								<p>In no event (including, without limitation, in the event of negligence) will AECOM , its employees, agents or distributors be liable for any consequential, incidental, indirect, special or punitive damages whatsoever (including, without limitation, damages for loss of profits, loss of use, business interruption, loss of information or data, or pecuniary loss), in connection with or arising out of or related to this Agreement, Cities cost model 1 or the use or inability to use Cities cost model 1 or the furnishing, performance or use of any other matters hereunder whether based upon contract, tort or any other theory including negligence.</p>
								<p>AECOM's entire liability, without exception, is limited to the customers' reimbursement of the purchase price of the Software (maximum being the lesser of the amount paid by you and the suggested retail price as listed by AECOM ) in exchange for the return of the product, all copies, registration papers and manuals, and all materials that constitute a transfer of license from the customer back to AECOM.</p>

								<h5>3.5 Warranties</h5>
								<p>Except as expressly stated in writing, AECOM makes no representation or warranties in respect of this Software and expressly excludes all other warranties, expressed or implied, oral or written, including, without limitation, any implied warranties of merchantable quality or fitness for a particular purpose.</p>

								<h5>3.6 Governing Law</h5>
								<p>This Agreement shall be governed by the law of the United States applicable therein. You hereby irrevocably attorn and submit to the non-exclusive jurisdiction of the courts of United States therefrom. If any provision shall be considered unlawful, void or otherwise unenforceable, then that provision shall be deemed severable from this License and not affect the validity and enforceability of any other provisions.</p>
								
								<h5>3.7 Termination</h5>
								<p>Any failure to comply with the terms and conditions of this Agreement will result in automatic and immediate termination of this license. Upon termination of this license granted herein for any reason, you agree to immediately cease use of Cities cost model 1 and destroy all copies of Cities cost model 1 supplied under this Agreement. The financial obligations incurred by you shall survive the expiration or termination of this license.</p>

								<h4>4. DISCLAIMER OF WARRANTY</h4>
								<p>THIS SOFTWARE AND THE ACCOMPANYING FILES ARE SOLD "AS IS" AND WITHOUT WARRANTIES AS TO PERFORMANCE OR MERCHANTABILITY OR ANY OTHER WARRANTIES WHETHER EXPRESSED OR IMPLIED. THIS DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY Cities cost model 1 AS WELL.</p>
								
								<h4>5. CONSENT OF USE OF DATA</h4>
								<p>You agree that AECOM may collect and use information gathered in any manner as part of the product support services provided to you, if any, related to Cities cost model 1.AECOM may also use this information to provide notices to you which may be of use or interest to you.</p>
							</div>
						</div>
					</div> 
				</div>
			</div>
			<Footer />
		</div>
	)
}