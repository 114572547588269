import React, { Component } from 'react';
import { Transition } from '../transitions';



export default class DynamicImage extends Component {
  constructor(props) {
    super(props)
  }
  
  
  render() {
    let image;
    if(this.props.imageDetails)
    {
      image = <img src={this.props.imageDetails.path} alt={this.props.imageDetails.alt} key={this.props.imageDetails.key} />;
    }
    else
    {
      image = "loading..."
    }
    return (
      <div className="dynamic-image">
        {this.props.imageDetails && (
          <Transition pageKey={this.props.imageDetails.key} duration={500} transition={this.props.transition}>
            {image}
          </Transition>
        )}
      </div>  
    )
  }
}

