import React from 'react';
import { Field } from 'redux-form';

export default function InputPlanning(props){
	const renderError = ({ meta: { touched, error } }) =>
	touched && error ? <span className="error">{error}</span> : false;

	return (
		<div className="all-50">
      <label className="radioCheckbox">
        <Field
          name="planning"
          component="input"
          type="radio"
          value="area"
          onChange={props.onPlanningChange}
        />{' '}
        Area
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="planning"
          component="input"
          type="radio"
          value="population"
          onChange={props.onPlanningChange}
        />{' '}
        Population
        <span className="checkmark"></span>
      </label>
      <Field name="planning" component={renderError} />
  	</div>
	)
}