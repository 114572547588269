import React from 'react';
import { Field } from 'redux-form';

export default function InputDensity(props){
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span className="error">{error}</span> : false;

	return (
		<div className="all-50 small-100 tiny-100">
      <label className="radioCheckbox">
        <Field
          name="density"
          component="input"
          type="radio"
          value="low"
          onChange={props.onDensityChange}
        />{' '}
        Low
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="density"
          component="input"
          type="radio"
          value="medium"
          onChange={props.onDensityChange}
        />{' '}
        Medium
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="density"
          component="input"
          type="radio"
          value="high"
          onChange={props.onDensityChange}
        />{' '}
        High
        <span className="checkmark"></span>
      </label>
      <Field name="density" component={renderError} />
    </div>
	)
}