import React from 'react';
import numberWithCommas from './numberWithCommas';

export default function TotalCostList(props){
	return(
		<ul>
			<li>
				<span className="icon push-left" style={{backgroundImage:`url(${props.icons['totalCost'].primary})`, backgroundRepeat: 'no-repeat' }}>					
				</span>
				<span className="label">Total cost</span>
				<span className="value push-right">${numberWithCommas(props.total)}</span>
			</li>
			<li>
				<span className="icon push-left" style={{backgroundImage:`url(${props.icons['perCapita'].primary})`, backgroundRepeat: 'no-repeat' }}>
				</span>
				<span className="label">Per capita</span>
				<span className="value push-right">${numberWithCommas(props.perCapita)}</span>
			</li>
		</ul>
	)
}