import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter, Router, Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
import BurgerMenu from 'react-burger-menu';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

import '../styles/ink-flex.css';
import '../styles/ink.min.css';
import '../styles/index.css';
import Questions from './questions';
import Results from './results';
import FrontPage from './front-page';
import About from './about';
import HowTo from './how-to';
import Included from './included';
import Excluded from './excluded';
import Compare from './compare';
import ViewAll from './view-all';
import Privacy from './privacy';
import Eula from './eula';
import ContactBar from './contact-bar';
import { setComparisons, getModels, setInflation } from '../actions';
import handleContactSubmit from './handleContactSubmit';
import { climateImages } from './image-details';

import { RouteTransition } from '../transitions'
import Header from './header';
import Footer from './footer';
import MenuWrap from './menu-wrap';
import GoogleAnalytics from "react-ga";

const Menu = BurgerMenu["push"];

GoogleAnalytics.initialize('UA-84589785-12'); // add your UA code
GoogleAnalytics.pageview(window.location.pathname + window.location.search);



class App extends Component {

  constructor(props)
  {
    super(props)
    this.history = createHistory();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openLeftMenu = this.openLeftMenu.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
    this.state = {
      isOpenLeft : false,
      isOpenRight : false
    }
    this.props.getModels();

  }

  onFormSubmit(values){

  }

  getModels2(models){

    let ccmHistory = {};
    if(models)
    {
      //ccmHistory = JSON.parse(ccmHistory);

      var index = 0;
      return (
        <Menu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} right className="right-menu" style={{}}>
          <h2>Your Models</h2>
          <form onSubmit={this.handleSubmit} className="menuForm">
            <ul key="jeff">
            {_.map(models, function(item, key){
              {
                index++;
              }
              return (




                <li key={index}>
                  <label className="radioCheckbox">
                  {item.info.title}
                  <input type="checkbox" name={"model"+index} value={key} className="push-right models" />
                  <span className="checkmark"></span>
                  </label>
                </li>
              )
            })}
            </ul>
            <div className="menu-btns-bottom">
              <button className="push-left sidebar-btn">COMPARE MODELS</button>
              <span className="push-left sidebar-btn" onClick={() => this.history.push({ pathname: "/view-all", state: {transition:"page-fade", duration:700} })}>View all</span>
              <span className="push-left sidebar-btn" onClick={() => this.history.push({ pathname: "/modeling", state: {transition:"page-fade", duration:700} })}>NEW MODEL</span>
            </div>
          </form>

        </Menu>
      )
    }
    else
    {
      return false;
    }
  }

  handleSubmit(e){
    e.preventDefault();
    var checkedValues = document.querySelectorAll('.menuForm .models:checked')
    checkedValues = _.map(checkedValues, function(item){
      return item.value;
    })
    this.props.setComparisons(checkedValues);
    this.history.push({ pathname: "/compare", state: {transition:"page-fade", duration:700} })

  }

  openLeftMenu(){
    this.setState({
      ...this.state,
      isOpenLeft : true
    })
  }

  handleContactSubmit(data){
    handleContactSubmit(data, this.props.info.email)
  }

  selectInflastion(inflation){
    console.log(inflation)
    this.props.setInflation(inflation)
    console.log(this.props.inflation)
  }

  linkNavigate(url){

    this.setState({
      ...this.state,
      isOpenLeft : false,
      isOpenLeft : false
    })

    this.history.push({ pathname: url, state: {transition:"page-fade", duration:700}})
  }

  render() {

    return (
      <div id="outer-container">
        <Header history={this.history} />
        <MenuWrap className="left-menu">
          <Menu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} isOpen={this.state.isOpenLeft} style={{}}>
            <div>
              <ul>
                <li><span style={{cursor:"pointer"}} key="0" onClick={() => {this.linkNavigate('/')}}>HOME</span></li>
                <li><span style={{cursor:"pointer"}} key="1" onClick={() => {this.linkNavigate("/about/")}}>ABOUT</span></li>
                <li><span style={{cursor:"pointer"}} key="2" onClick={() => {this.linkNavigate("/how-to-use-the-model")}}>HOW TO USE THE MODEL</span></li>
                <li><span style={{cursor:"pointer"}} key="3" onClick={() => {this.linkNavigate("/included")}}>WHAT DOES THE MODEL INCLUDE</span></li>
                <li><span style={{cursor:"pointer"}} key="4" onClick={() => {this.linkNavigate("/excluded")}}>WHAT DOES THE MODEL EXCLUDE</span></li>
              </ul>
              <ul>
                <li><span style={{cursor:"pointer"}} key="7" onClick={() => {this.linkNavigate("/privacy")}}>PRIVACY POLICY</span></li>
                <li><span style={{cursor:"pointer"}} key="8" onClick={() => {this.linkNavigate("/eula")}}>SOFTWARE LICENCE</span></li>
              </ul>
              <ul>
                <li><span key="8" style={{cursor:"pointer"}} ><ContactBar text="button" onSubmit={this.handleContactSubmit} >CONTACT THE TEAM</ContactBar></span></li>
                <li><span key="9" >HOW CAN WE HELP YOU</span></li>
              </ul>
              <ul>
                <li><span key="10" >ECONOMICS</span></li>
                <li><span key="11" >CITIES</span></li>
                <li><span key="12" >TRANSPORT</span></li>
                <li><span key="13" >WATER</span></li>
                <li><span key="14" >EDUCATION</span></li>
                <li><span key="15" >POWER</span></li>
              </ul>
              <ul>
              <li><span key="16" >PREDICTED INFLATION BY YEAR</span></li>
              <li><span key="17" >Select the baseline year to calculate results</span></li>
              <li><span style={{cursor:"pointer"}} className={this.props.inflation === 4 ? "inflationselected":""} key="18" onClick={() => {this.selectInflastion(4)}} >2019</span></li>
              <li><span style={{cursor:"pointer"}} className={this.props.inflation === 6.6 ? "inflationselected":""} key="19" onClick={() => {this.selectInflastion(6.6)}}>2020</span></li>
              <li><span style={{cursor:"pointer"}} className={this.props.inflation === 8.5 ? "inflationselected":""} key="20" onClick={() => {this.selectInflastion(8.5)}} >2021</span></li>
              <li><span style={{cursor:"pointer"}} className={this.props.inflation === 10.8 ? "inflationselected":""} key="21" onClick={() => {this.selectInflastion(10.8)}} >2022</span></li>

              </ul>

            </div>
          </Menu>
        </MenuWrap>
        {Object.keys(this.props.models).length > 0 && (
          <MenuWrap className="right-menu">
            {this.getModels2(this.props.models)}
          </MenuWrap>
        )}
        <Router history={this.history}>
          <Route
            render={({ location }) => {
              GoogleAnalytics.set({page: location.pathname})
              GoogleAnalytics.pageview(location.pathname)
              return(
                <RouteTransition pageKey={location.key} {...location.state}>
                  <div className="in-route" id="page-wrap" >
                    <Switch location={location}>
                      <Route path="/eula" component={Eula} />
                      <Route path="/privacy" component={Privacy} />
                      <Route path="/view-all" component={ViewAll} />
                      <Route path="/compare" component={Compare} />
                      <Route path="/excluded" component={Excluded} />
                      <Route path="/included" component={Included} />
                      <Route path="/how-to-use-the-model" component={HowTo} />
                      <Route path="/about" component={About} />
                      <Route path="/results/:id" component={Results} />
                      <Route path="/results" component={Results} />
                      <Route path="/modeling" component={Questions} />
                      <Route path="/" render={(props) => <FrontPage {...props} handleContactSubmit={this.handleContactSubmit} openLeftMenu={this.openLeftMenu}  />} />
                    </Switch>
                  </div>
                </RouteTransition>
              )
            }}
          />
        </Router>

      </div>
    );
  }
}

function mapStateToProps(state){
  return state;
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({ setComparisons, getModels, setInflation }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
