import React from 'react';
import { Field } from 'redux-form';

import renderSliderField from './renderSliderField';

export default function InputDemographic(props){
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span className="error">{error}</span> : false;

	return (
		<div className="all-50 small-100 tiny-100">
      <label className="radioCheckbox">
        <Field
          name="demographic"
          component="input"
          type="radio"
          value="Young population"
          onChange={props.onDemographicChange}
        />{' '}
        Young population
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="demographic"
          component="input"
          type="radio"
          value="Standard demographic"
          onChange={props.onDemographicChange}
        />{' '}
        Standard demographic
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="demographic"
          component="input"
          type="radio"
          value="Ageing population"
          onChange={props.onDemographicChange}
        />{' '}
        Ageing population
        <span className="checkmark"></span>
      </label>
      <Field name="demographic" component={renderError} />
      
    </div>
	)
}