import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import CustomScroll from './custom-scrollbar';
import TotalCostList from './total-cost-list';
import Footer from './footer';
import BlueHeader from './blue-header';
import ContactBar from './contact-bar';
import CompareListBuilder from './compare-list-builder';
import setAdjutableWidths from './set-adjustable-widths';
import { setComparisons, getModels, saveModel, updateModelInfo, deleteModel } from '../actions/';
import Duplicate from './btn-duplicate';
import Delete from './btn-delete';
import Modal from 'react-modal';
import InputName from './input-name';
import ViewAllItem from './view-all-item';

import { iconsSpec, iconsTotals, iconsBreakdowns, iconsEdit } from '../icons/results';
import calculateResults from './calculate-results';
import handleContactSubmit from './handleContactSubmit';

class ViewAll extends Component {

	componentDidMount(){

	}

	constructor(props){
		super(props);

		this.showResults = this.showResults.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.openDuplicateModal = this.openDuplicateModal.bind(this);
		this.closeDuplicateModal = this.closeDuplicateModal.bind(this);
		this.handleContactSubmit = this.handleContactSubmit.bind(this);
		const _this = this;
		this.footerBtns = {
      restart : {
        text : "Restart",
        callback : () => props.history.push({ pathname: "/modeling", state: {transition:"page-fade", duration:700} })
      },
      compare : {
        text : "View Comparisons",
        callback : function(e){
        	 _this.handleSubmit(e)
        }
      }
    }

		this.state = {
			showBreakdown : false,
			breakdownKey: "no-key",
			scrollSliderSettings : {min:0, max:100},
			modalDuplicateIsOpen: false,
			name : {
				showEdit : false
			}
		}

		this.props.getModels();
		this.props.setComparisons([]);
	}

	loadBreakdown(key){
		if(key !== this.state.breakdownKey)
		{
			this.setState({
				showBreakdown : true,
				breakdownKey: key
			})
		}
		else
		{
			this.setState({
				showBreakdown : false,
				breakdownKey: "no-key"
			})
		}

	}

	getResult(key){
		const models = this.props.models;
		const results = models[key].results
		//<span className="edit icon push-right" style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}></span>
		return (
			<div className="compareItem">

					{!this.state.name.showEdit && (
						<h3 onClick={() => this.history.push({ pathname: "/results/"+key, state: {transition:"page-fade", duration:700} })}>
							{models[key].info.title}<label className="radioCheckbox push-right"><input type="checkbox" name={"model"+key} value={key} className="push-right models" /><span className="checkmark"></span></label> <span className="edit icon push-right" onClick={() => {this.handleEditClick('name')}} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}></span> <Duplicate modelKey={key} openDuplicateModal={this.openDuplicateModal} iconPath={iconsEdit.copy.primary} /> <span className="edit icon push-right" onClick={() => {this.handleEditClick('name')}} style={{backgroundImage:`url(${iconsEdit.delete.primary})`, backgroundRepeat: 'no-repeat' }}></span>
						</h3>
					)}
					{this.state.name.showEdit && (
						<h3 onClick={() => this.history.push({ pathname: "/results/"+key, state: {transition:"page-fade", duration:700} })}>
							<InputName value={models[key].info.title} /><span className="edit icon push-right" style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}></span>
						</h3>
					)}

				<div className="compare-list-wrap total-cost-wrap"><TotalCostList total={results.total} perCapita={results.perCapita} icons={iconsTotals}/></div>

			</div>
		)
	}

	handleEditClick(key){

		let newState = {
			...this.state
		};
		newState[key] = {
			...this.state[key]
		};

		if(this.state[key].showEdit)
		{
			newState[key].showEdit = false;
		}
		else
		{
			newState[key].showEdit = true;
		}

		this.setState(newState)
	}

	getTotalsData(){
		const models = this.props.models;
		const results = []
		for(let key in this.props.comparisions)
		{
			results.push(models[this.props.comparisions[key]]);
		}

		let newData = {};
		for(let i = 0; i < results.length; i++)
		{
			for(let key in results[i].results.totals)
			{
				if(!newData[key])
				{
					newData[key] = {};
					newData[key][results[i].info.title] = results[i].results.totals[key];
				}
				else
				{
					newData[key][results[i].info.title] = results[i].results.totals[key];
				}
			}
		}
		return newData
	}

	onSliderChange(a, b){
		const scrollWrap = document.querySelector('.content-wrap');
		const contentWidth = document.querySelector('div.blue-header .all-80').offsetWidth;
		scrollWrap.scrollLeft = a;
	}

	showResults(){
		const comparatorCount = _.keys(this.props.models).length

		let className;
		let rowLimit;

		if(comparatorCount > 2)
		{
			className = "spec-wrap all-33 small-100 tiny-100";
			rowLimit = 3;
		}
		else if(comparatorCount === 2)
		{
			className = "spec-wrap all-50 small-100 tiny-100";
			rowLimit = 2;
		}
		else
		{
			className = "spec-wrap all-100";
			rowLimit = 2;
		}


		let index = 0;
		let rowIndex = 0;
		let jsx = [];
		jsx[rowIndex] = [];

		for(let key in this.props.models)
		{
			if(index % rowLimit == 0 && index > 1)
			{
				rowIndex++;
				jsx[rowIndex] = [];
				jsx[rowIndex].push(<div key={index} className={className}><ViewAllItem history={this.props.history} handleDeleteClick={(modelKey) => {this.handleDeleteClick(modelKey)}} openDuplicateModal={(key) => this.openDuplicateModal(key)} models={this.props.models} saveModelName={(model, field, value) => {this.saveModelName(model, field, value)}} modelKey={key} results={this.props.models[key].results} showEdit={false} handleEditClick={(key) => {this.handleEditClick(key)}} /></div>)
			}
			else
			{
				jsx[rowIndex].push(<div key={index} className={className}><ViewAllItem history={this.props.history} handleDeleteClick={(modelKey) => {this.handleDeleteClick(modelKey)}} openDuplicateModal={(key) => this.openDuplicateModal(key)} models={this.props.models} saveModelName={(model, field, value) => {this.saveModelName(model, field, value)}} modelKey={key} results={this.props.models[key].results} showEdit={false} handleEditClick={(key) => {this.handleEditClick(key)}} /></div>)
			}
			index++;
		}
		// jsx is now each element of the array represents 1 row, which is
		// ignored for horizontal scrolling
		// console.log(jsx)

		return _.map(jsx, function(row, i){
			return <div key={i} className="column-group gutters">{row}</div>;
		})
	}

	openDuplicateModal(modelKey) {
		this.copyModelKey = modelKey;
		this.setState({modalDuplicateIsOpen: true});
  }

  closeDuplicateModal(e) {
  	e.preventDefault();
  	if(this.copyModelKey)
  	{
  		const modelName = document.getElementById('modelName').value
  		this.copyModel(this.copyModelKey, modelName);
  	}
  	//this.props.handleSubmit();
  	this.setState({modalDuplicateIsOpen: false});
  }

  copyModel(modelKey, newModelName){

		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var seconds = today.getSeconds();
		var minutes = today.getMinutes()
		var yyyy = today.getFullYear();
		today = mm + '-' + dd + '-' + yyyy;
		var todayFull = seconds + '-' +minutes + '-' +today;
		let title
		if(newModelName)
		{
			title = newModelName;
		}
		else
		{
			title = "Model from " + today;
		}

		let newModel = _.cloneDeep(this.props.models[modelKey])
		newModel.info.title = title;
		this.props.saveModel({key : todayFull, model : newModel});
  }

  saveModelName(model, field, value){
  	this.props.updateModelInfo({model, field, value})
	}

	handleSliderChange(values, b){
		const scrollWrap = document.querySelector('.content-wrap');
		scrollWrap.scrollLeft = values.scrollLeft;
	}

  handleSubmit(e){
    e.preventDefault();
    var checkedValues = document.querySelectorAll('.viewAllForm .models:checked')
    checkedValues = _.map(checkedValues, function(item){
      return item.value;
    })
    this.props.setComparisons(checkedValues);
    this.props.history.push({ pathname: "/compare", state: {transition:"page-fade", duration:700} })
  }

  handleContactSubmit(data){
    handleContactSubmit(data, this.props.info.email)
  }

  onFormSubmit(e){
  	e.preventDefault()
  }

  handleDeleteClick(modelKey){
  	this.props.deleteModel({modelName:modelKey})
  }

	render(){
		return (
			<div className="view-all">
				<form className="viewAllForm" onSubmit={this.onFormSubmit}>
					<div className="page-content">
						<div className="bg-image"></div>
						<div className="page">
							<BlueHeader title="Compare Models" text="" />
							<div className="content-wrap">

								<div className="column-group gutters">
									<div className="all-80 push-center">
										{this.showResults()}
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer page="view-all" buttonData={this.footerBtns}  />
					<ContactBar className="bar-bottom" onSubmit={this.handleContactSubmit} type="text" text="FIND OUT HOW WE CAN HELP YOU DELIVER YOUR CITY" />
				</form>
				<Modal
				  isOpen={this.state.modalDuplicateIsOpen}
				  onRequestClose={this.closeDuplicateModal}
				  contentLabel="Copy"
				  ariaHideApp={false}
				  className="copy-modal"
				  overlayClassName="copy-overlay"
				>
					<div className="ink-grid">
						<div className="column-group gutters">
				  		<div className="all-80 push-center">
				  			<div className="modal-content">
				          <h2>COPY</h2>
				          <form id="duplicateForm">
				          	<p>Give your Model a new name then edit the answers to adjust your output</p>
				            <input type="text" id="modelName" name="modelName" />
				            <button className="push-right black-btn" onClick={this.closeDuplicateModal}>Copy</button>
				          </form>
				        </div>
				      </div>
				    </div>
				   </div>
				</Modal>
			</div>
		)
	}
}

function mapStateToProps(state){

	let newState = {
		...state
	}

	for(let key in state.models)
	{
		newState.models[key].results = calculateResults(state.models[key].results.answers, state.inflation)
	}

	return newState;
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ setComparisons, getModels, saveModel, updateModelInfo, deleteModel }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAll)
