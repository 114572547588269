import React from 'react';
import { Field } from 'redux-form';

const renderTextInput = ({onChange, name }) => {
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span>{error}</span> : false;

  return (
    <span className="textareaWrap">
      <Field
        name={name}
        component="input"
        onChange={onChange}
      />
      <Field name={name} component={renderError} />
    </span>
  )
}

export default renderTextInput
