import React from 'react';

import Footer from './footer';

export default function HowTo({ history, to, ...props }){

	return (
		<div className="how-to static-content">
			<div className="bg-image"></div>
			<div className="blue-border-animated"></div>
			<div className="column-group gutters">
				<div className="all-80 xlarge-70 large-70 push-center content">
					<div className="column-group gutters">
						<div className="push-center all-100">
							<div className="grey-text-box">

								<h2>What does the Model include?</h2>
								<p>By selecting one of the options from each of these factors the Model will provide a total site preparation and infrastructure cost in US Dollars at November 2018 prices. This will be expressed as a cost per head and a total cost and will show a more detailed split for the elements and in particular the Transport, Utilities and Social Infrastructure.</p>

								<h2>Spatial model</h2>
								<h3>Spatial scenarios</h3>
								<p>AECOM have produced inputs to the cost model from a series of ‘spatial scenarios’.</p>
								<p>These are illustrations of hypothetical cities of different sizes and density levels, which are intended to be generic but may resemble key characteristics of cities that already exist.</p>
								<p>The scenarios show overall city structure including urban centres, density distribution, major green spaces, major roads and fixed public transport routes.</p>

								<h3>Hierarchy of urban centres</h3>
								<p>This approach reflects our best practice approaches to metropolitan scale spatial planning – which includes establishing a hierarchy of urban centres, co-ordinated with density distribution and different levels of public transport provision.</p>

								<h3>Location</h3>
								<p>The scenarios are shown in a coastal location, allowing the provision of a  commercial port, with an airport and with an environmental boundary (e.g. mountains) along the eastern edge.</p>

								<h3>Balanced economic structure</h3>
								<p>Land use and floor space requirements were developed based on the assumption of a ‘balanced’ economic structure – e.g. a mix of industrial and office-based employment, without being overly-reliant on either. Total numbers of jobs (influencing land use / floor space requirements) are influenced by the age profile of the city.</p>

								<h3>Urban expansion</h3>
								<p>Whilst this model is focussed on stand-alone previously undeveloped or developed sites, it can be adapted to transforming / growing existing cities and urban expansions. This approach could refer to rapidly urbanising regions where population growth will be potentially accommodated in new urban centres, for example the new and expanding cities in India, China and Africa.</p>


								<h2>General</h2>
								<a name="site-preperation" id="site-preperation"></a>
								<p>As noted in the Spatial Model section, the Cities Cost Model is based on a best practice approach to Metropolitan scale spatial planning. This therefore is aligned with  best practice transport and utilities principles and social infrastructure requirements to inform outputs on infrastructure costs.</p>

								<h2>Site Preparation</h2>
								<p>This covers the site clearance works and site preparation to make the land ready for new infrastructure to be installed.</p>
								<p>It covers three scenarios</p>
								<p>Previously undeveloped land</p>
								<p>Previously developed land low risk. This will therefore have some limited areas and extent of contamination.</p>
								<p>Previously developed land high risk. This will therefore have extensive areas and extent of contamination reflecting previous land uses. It is noted that this has a significant impact on the total cost.</p>

								<h3>Demolition and site preparation works</h3>
								<p>This covers the demolition of existing buildings and general site clearance to make the land ready for new infrastructure works</p>
								<h3>Remediation</h3>
								<p>This includes the treatment of contaminated material in the ground either through retention within the site or removal from site</p>
								<h3>Archaeological investigations</h3>
								<a name="transport" id="transport"></a>
								<p>This covers the requirement to carry out investigation works either prior to construction or during construction to establish or confirm the presence of archaeological artefacts</p>


								<h2>Transport</h2>
								<p>The infrastructure requirements and hence costs are based on the following principles :</p>
								<ul>
									<li>Transit oriented with all development centred on a public transport network</li>
									<li>In support of this, there will be mixed use development to reduce the need to travel, especially by car</li>
									<li>There is high accessibility of public transport (less than 10-minute walk / 400m distance from each residence)</li>
									<li>There are convenient public transport routes between local centres with express public transport service between major centres</li>
									<li>Segregated public transport route ways will be provided</li>
									<li>There will be sustainable transport mode splits for all densities and the majority of trips will be made by public transport and active modes</li>
								</ul>

								<h3>Aviation</h3>
								<p>Each scenario includes an airport</p>
								<p>The requirement for this assumes that there is no existing airport(s) in the vicinity and that therefore it is best practice to provide a suitable facility that can handle passengers and cargo. This therefore provides suitable connectivity both within the Country and ( where appropriate ) outside the Country.</p>
								<p>The capacity of the airport will vary significantly in relation to population size.</p>

								<h3>Maritime</h3>
								<p>Each scenario includes a commercial port</p>
								<p>The requirement for this assumes that there is no existing port(s) in the vicinity and that therefore it is best practice to provide a suitable facility that can handle commercial traffic. This therefore provides suitable connectivity to markets both within the Country and ( where appropriate ) outside the Country.</p>
								<p>The capacity of the port will vary significantly in relation to population size</p>

								<h3>Public transport</h3>
								<p>Please see transport principles. The City is based on a mode split in favour of public transport through a combination of light and heavy rail, metro and bus. It therefore seeks to encourage usage through location of the network and frequency of operation.</p>

								<h3>Private transport</h3>
								<a name="utilities" id="utilities"></a>
								<p>Please see transport principlesAs noted above, the City is based on a mode split in favour of public transport and therefore the private transport system is intended to cater for necessary journeys which it would not be feasible for a public transport network to provide for.</p>

								<h2>Utilities</h2>
								<p>The infrastructure requirements and hence costs are based on the following principles :</p>

								<h3>Power</h3>
								<p>Temperate and cold climates are expected to deliver their heat through electric-led energy centres on a district level.</p>
								<p>Electric vehicle uptake has been accounted for by assuming that any vehicle charging during peak power consumption periods is provided through decentralised battery storage. This may result in restricted charging during these periods; however unrestricted charging would be available outside the peaks.</p>
								<p>Local renewable generation will provide five per cent of the city’s total electricity demand. The national grid is assumed to be sufficiently decarbonised by large-scale renewable plants to mitigate the need for generating more power locally.</p>
								<p>A city with an arid climate will provide its local renewable generation through solar photovoltaic (PV). A city with a cold climate will provide its local renewable generation through wind turbines. A city with a temperate climate will provide its local renewable generation through a balanced mix of solar PV and offshore wind turbines.</p>

								<h3>District heating/cooling</h3>
								<p>A city with an arid climate will have district cooling networks, but no district heating networks. A city with a temperate or cold climate will have district heating networks, but no district cooling networks.</p>

								<h3>Potable water</h3>
								<p>It is assumed that the local network, supplemented by aquifers where necessary, will have sufficient capacity to serve the city. Therefore no desalination of sea water will be required.</p>

								<h3>Sewerage</h3>
								<p>All grey water produced will be reused locally (irrigation, toilet flushing etc.), i.e. only foul water will be treated at the waste water treatment plant.</p>

								<h3>Surface water</h3>
								<p>This will follow the principles of sustainable urban drainage systems which seeks to reduce the impact of new development on the drainage network and thereby reduce the risk of flooding due to extreme weather events.</p>

								<h3>Telecommunications</h3>
								<p>This will provide a robust backbone network to the whole city with suitable resilience. This can therefore accommodate a comprehensive tele-communications network, which adopts the principles of smart city technology to optimise transport movements and utilities network provision.</p>

								<h3>Solid waste</h3>
								<a name="social-infrastructure" id="social-infrastructure"></a>
								<p>This will be based on best practice with recycling where possible of all solid waste and potential re-use in heat and energy systems.</p>

								<h2>Social Infrastructure</h2>
								<p>Utilises best practice standards from a mix of North American, European, and Asian cities based on AECOM research. This therefore results in a solution whereby standards don’t reflect a singular geography but creates a best practice city that prioritises both sufficient quantity and quality of facilities.</p>
								<p>Social Infrastructure is based on standards from higher density cities in terms of space requirements. This approach has then been applied to other elements of the model.</p>
								<p>Social infrastructure standards have been broken down into a centres hierarchy. In the low density scenario, where there are more local and neighbourhood centres than the actual ideal requirements for the city, uplift has been applied to ensure that there is sufficient provision across the city.</p>
								<p>The model covers the following facilities : </p>

								<h3>Education</h3>
								<p>The provision of facilities for all inhabitants of the City. This is from  nursery through primary and secondary education and  university and technical colleges. This therefore promotes the attainment by the population of optimal educational standards which provides a strong basis for the future development of the City.</p>

								<h3>Health</h3>
								<p>The provision of facilities for all inhabitants within the City covering  primary, secondary and tertiary care. This should promote both a healthy and active lifestyle and also provides suitable care when required due to illness and incapacity.</p>

								<h3>City Management</h3>
								<p>The provision of facilities including public centres and supporting municipal offices to allow the public full access to services and to promote effecient governance of the city by the relevant national / local authorities.</p>

								<h3>Sports and leisure</h3>
								<p>The provision of indoor and outdoor facilities covering a wide range of sports and leisure activities. This is to support and promote an active lifestyle for people of all ages and backgrounds.</p>

								<h3>Cultural and community space</h3>
								<p>The provision of a range of cultural and community facilities that are all accessible to all and promote cultural activities and interaction between the inhabitants of the City.</p>

								<h3>Emergency</h3>
								<p>The provision of facilities including police, fire and ambulance and in support of the health care referred to above.</p>

								<h3>Religious requirements</h3>
								<a name="landscaping" id="landscaping"></a>
								<p>The provision of facilities  to support and promote active regular attendance and which is accessible to all.</p>

								<h2>Landscaping</h2>
								<a name="professional-fees" id="professional-fees"></a>
								<p>Provision of formal and informal parks and open space areas to encourage outdoor living and  outdoor pastimes and leisure activities.</p>

								<h2>Professional Fees</h2>
								<p>A full team of specialist advisors to deal with all relevant matters and to take the project through from inception to completion on site. This includes supporting surveys and studies to inform their work.</p>
								<a name="contingency" id="contingency"></a>
								<p>It also includes for the provision of programme management to support and facilitate developments of this scale.</p>

								<h2>Contingency</h2>
								<p>An allowance to cover the mitigation and management of all risks either known or unknown, such that the project can be completed in an optimal manner.</p>
							</div>
						</div>
					</div>
					<div className="column-group gutters">
						<div className="all-50 small-100 tiny-100">
						</div>
						<div className="all-50 small-100 tiny-100">
							<span className="push-right black-btn" onClick={() => history.push({ pathname: "/excluded", state: {transition:"page-fade", duration:700} })}>WHAT DOES THE MODEL EXCLUDE</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}
