import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

export default function handleContactSubmit(data, email){
	const newData = {
		'email' : email ? email : "",
		'comments' : data.message ? data.message : "",
		'phone' : data.phone ? data.phone : "",
		'area'  : data.interests ? Object.keys(data.interests).join(', ') : ""
	}
	let Encoded = Object.keys(newData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(newData[k])}`).join('&');

	axios({
		url: 'https://communications.aecom.com/l/377462/2018-11-21/f6vncg?' + Encoded ,
		method:'post',
		adapter: jsonpAdapter,
		jsonpCallback: function(response){console.log(response)},
		callbackParamName: 'callback' // optional, 'callback' by default
	}).then((response) => {
		if(response.data.result === 'done')
		{
			console.log("success")
		}
		else
		{
			console.log("fail")
		}
	})
}