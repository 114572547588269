import React from 'react';
import numberWithCommas from './numberWithCommas';

export default function TotalCosts(props){
	return(
		<div className="column-group gutters total">
			<div className="all-50 tiny-100 small-100 showWO" id="total">
				<h5><span className="label">Total cost</span> <span className="push-right value">${numberWithCommas(props.total)}</span></h5>
			</div>
			<div className="all-50 tiny-100 small-100 showWO" id="perCapita">
				<h5><span className="label">Cost per capita</span> <span className="push-right value">${numberWithCommas(props.perCapita)}</span></h5>
			</div>
		</div>
	)
}