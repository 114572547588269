import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import validate from './validate'
import BlueHeader from './blue-header';
import InputPlanning from './input-planning';
import DynamicImage from './dynamic-image';
import { planningImages } from './image-details';


class Question3 extends Component{

  constructor(props){
    super(props);

    this.onPlanningChange = this.onPlanningChange.bind(this);
    this.state = {
      planning : false
    }
  }
  
  onPlanningChange(e){
    this.setState({
      ...this.state,
      planning : e.target.value
    })
  }

  render(){
    const { handleSubmit } = this.props;
    const { planning } = this.state;
    
    return (
      <div className="page-content ink-flex">
        <form onSubmit={handleSubmit} style={{height:"100%"}}>
          <BlueHeader title="Planning" text="How will you be planning your city?" />
          <div className="column-group gutters">
            <div className="all-80 push-center content">
              <div className="column-group gutters">
                <InputPlanning onPlanningChange={this.onPlanningChange} />
                <div className="all-50 small-100 tiny-100">
                  <div style={{position:'relative'}}>
                    <DynamicImage imageDetails={planningImages[planning ? planning : "area"]} transition="img-fade"  />
                  </div>
                </div>
              </div>
              <div className="column-group gutters">
                <div className="all-50 small-100 tiny-100">
                  <p>You can plan your city by either selecting an approximate area you have to build on or by choosing the desired population of your city</p>
                </div>
                <div className="all-50 small-100 tiny-100">
                </div>
              </div>
            </div>
          </div>
          
        </form>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}

export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question3))
