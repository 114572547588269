import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import BlueHeader from './blue-header';
import DynamicImage from './dynamic-image';
import { geologyImages } from './image-details';
import InputGeology from './input-geology';

class Question6 extends Component {

  constructor(props){
    super(props);
    this.onGeologyChange = this.onGeologyChange.bind(this);
    this.state = {
      geology : ""
    }
  }

  renderError({ meta: { touched, error } }) {
    return touched && error ? <span>{error}</span> : false;
  }
  
  onGeologyChange(e){
    this.setState({
      geology : e.target.value ? e.target.value : "Previously undeveloped land"
    })
  }

  render(){
    const { handleSubmit } = this.props;
    const { geology } = this.state;
 
    return (
    <div className="page-content">
      <form onSubmit={handleSubmit}>
        <BlueHeader title="Geology" text="Select city geology" />
        <div className="all-80 push-center content">
          <div className="column-group gutters">
            <InputGeology onGeologyChange={this.onGeologyChange} />
            <div className="all-50 small-100 tiny-100">
              <div style={{position:'relative'}}>
                <DynamicImage imageDetails={geologyImages[geology ? geology : "Previously undeveloped land"]} transition="img-fade"  />
              </div>
            </div>
          </div>
          <div className="column-group gutters">
            <div className="all-50 small-100 tiny-100">
              <p>{geology ? geologyImages[geology].text : "This has an impact on site preparation costs in particular where the site is previously developed land with a significant risk of contamination"}</p>
            </div>
          </div>
        </div>
      </form>
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}
export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question6))

