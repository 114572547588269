import { GET_COUNTRY } from '../actions';


export default function(state = {}, action) {
  switch (action.type) {
    
    case GET_COUNTRY:
 
    		return state;

    default:
      return state
  }
}