import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

import CustomScroll from './custom-scrollbar';
import TotalCostList from './total-cost-list';
import ModelSpecList from './model-spec-list';
import Footer from './footer';
import BlueHeader from './blue-header';
import ContactBar from './contact-bar';
import CompareListBuilder from './compare-list-builder';
import setAdjutableWidths from './set-adjustable-widths';
import ModelSpec from './model-spec';
import { getModels } from '../actions/';
import RenderScrollSlider from './renderScrollSlider'
import handleContactSubmit from './handleContactSubmit';

import { iconsSpec, iconsTotals, iconsBreakdowns, iconsEdit } from '../icons/results';
import calculateResults from './calculate-results';

class Compare extends Component {

	componentDidMount(){
		const widthInfo = setAdjutableWidths();
		const contentWidth = (this.props.comparisions.length * (widthInfo.contentWidth/3) + widthInfo.rightMargin) - widthInfo.contentWidth;
		this.setState({
			...this.state,
			scrollSliderSettings:{min:0, max:contentWidth}
		})
	}

	constructor(props){
		super(props);

		this.showResults = this.showResults.bind(this);
		this.handleContactSubmit = this.handleContactSubmit.bind(this);
		this.footerBtns = {
      restart : {
        text : "New model",
        callback : () => props.history.push({ pathname: "/modeling", state: {transition:"page-fade", duration:700} })
      },
      viewAll : {
        text : "View all models",
        callback : () => props.history.push({ pathname: "/view-all", state: {transition:"page-fade", duration:700} })
      }

		}

		this.state = {
			showBreakdown : false,
			breakdownKey: "no-key",
			scrollSliderSettings : {min:0, max:100}
		}

		this.props.getModels();
	}

	loadBreakdown(key){
		if(key !== this.state.breakdownKey)
		{
			this.setState({
				showBreakdown : true,
				breakdownKey: key
			})
		}
		else
		{
			this.setState({
				showBreakdown : false,
				breakdownKey: "no-key"
			})
		}

	}

	getResult(key){
		const models = this.props.models;
		const results = models[this.props.comparisions[key]].results;
		//<span className="edit icon push-right" style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}></span>
		return (
			<div className="compareItem">
				<h3>{models[this.props.comparisions[key]].info.title}</h3>
				<div className="compare-list-wrap total-cost-wrap"><TotalCostList total={results.total} perCapita={results.perCapita} icons={iconsTotals}/></div>
				<div className="compare-list-wrap model-spec"><ModelSpecList {...results.answers} icons={iconsSpec} /></div>
			</div>
		)
	}

	getTotals(key){
		const data = this.getTotalsData();
		let breakdownData = "";
		if(this.state.showBreakdown)
		{
			breakdownData = this.getBreakdownData(this.state.breakdownKey);
		}
		return (
			<CompareListBuilder data={data} handleContactSubmit={this.handleContactSubmit} loadBreakdown={this.loadBreakdown.bind(this)} showBreakdown={this.state.showBreakdown} breakdownKey={this.state.breakdownKey} breakdownData={breakdownData} icons={iconsTotals} />
		)
	}

	getTotalsData(){
		const models = this.props.models;
		const results = []
		for(let key in this.props.comparisions)
		{
			results.push(models[this.props.comparisions[key]]);
		}

		let newData = {};
		for(let i = 0; i < results.length; i++)
		{
			for(let key in results[i].results.totals)
			{
				if(!newData[key])
				{
					newData[key] = {};
					newData[key][results[i].info.title] = results[i].results.totals[key];
				}
				else
				{
					newData[key][results[i].info.title] = results[i].results.totals[key];
				}
			}
		}
		return newData
	}

	getBreakdownData(breakdownKey){
		const models = this.props.models;
		const results = []
		for(let key in this.props.comparisions)
		{
			results.push(models[this.props.comparisions[key]]);
		}

		let newData = {};
		if(results[0])
		{
			if(results[0].results.breakdowns[breakdownKey])
			{
				for(let i = 0; i < results.length; i++)
				{
					for(let key in results[i].results.breakdowns[breakdownKey])
					{
						if(!newData[key])
						{
							newData[key] = {};
							newData[key][results[i].info.title] = results[i].results.breakdowns[breakdownKey][key];
						}
						else
						{
							newData[key][results[i].info.title] = results[i].results.breakdowns[breakdownKey][key]
						}
					}
				}

				return newData;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}


	}

	onSliderChange(a, b){
		const scrollWrap = document.querySelector('.content-wrap');
		const contentWidth = document.querySelector('div.blue-header .all-80').offsetWidth;
		scrollWrap.scrollLeft = a;
	}

	showResults(){
		const comparatorCount = this.props.comparisions.length;

		let className;
		let rowLimit;

		if(comparatorCount > 2)
		{
			className = "adjustable-column fit-33 spec-wrap";
			rowLimit = 3;
		}
		else
		{
			className = "adjustable-column fit-50 spec-wrap";
			rowLimit = 2;
		}

		let index = 0;
		let rowIndex = 0;
		let jsx = [];
		jsx[rowIndex] = [];

		for(let key in this.props.comparisions)
		{
			if(index % rowLimit == 0 && index > 1)
			{
				rowIndex++;
				jsx[rowIndex] = [];
				jsx[rowIndex].push(<div key={index} className={className}>{this.getResult(key)}</div>)
			}
			else
			{
				jsx[rowIndex].push(<div key={index} className={className}>{this.getResult(key)}</div>)
			}
			index++;
		}
		// jsx is now each element of the array represents 1 row, which is
		// ignored for horizontal scrolling
		// console.log(jsx)

		return <div className="column-group adjustable-column-group">{jsx}</div>;
	}

	handleSliderChange(values, b){
		const scrollWrap = document.querySelector('.content-wrap');
		scrollWrap.scrollLeft = values.scrollLeft;
	}

	handleContactSubmit(data){

		handleContactSubmit(data, this.props.info.email)
	}

	render(){

		return (
			<div className="compare">
				<div className="page-content">
					<div className="bg-image"></div>
					<div className="page">
						<BlueHeader title="Compare Models" text="" />

						<div className="column-group" style={{ height: "80px", flex: "0 1 auto", minHeight: "80px" }}>

						{this.props.comparisions.length === 0 && (
							<div className="all-80 push-center top-space">
							<h1 style={{color:"white"}}>You have not selected any models to compare</h1>
							</div>
						)}
						</div>
						<div className="content-wrap">
							<div className="wrap">
								<ModelSpec models={this.props.models} />
								{this.getTotals()}
							</div>
						</div>
					</div>
				</div>
				<Footer page="compare" buttonData={this.footerBtns}  />
				<ContactBar className="bar-bottom" onSubmit={this.handleContactSubmit} type="text" text="FIND OUT HOW WE CAN HELP YOU DELIVER YOUR CITY" />
			</div>
		)
	}
}

function mapStateToProps(state){

	let newState = {
		...state
	}

	for(let key in state.models)
	{
		newState.models[key].results = calculateResults(state.models[key].results.answers, state.inflation)
	}

	return newState;
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ getModels }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Compare)
