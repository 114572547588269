import React from 'react';
import { Field } from 'redux-form';

import renderSliderField from './renderSliderField';

export default function InputGeology(props){
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span className="error">{error}</span> : false;

	return (
		<div className="all-50 small-100 tiny-100">
      <label className="radioCheckbox">
        <Field
          name="geology"
          component="input"
          type="radio"
          value="Previously undeveloped land"
          onChange={props.onGeologyChange}
        />{' '}
        Previously undeveloped land
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="geology"
          component="input"
          type="radio"
          value="Previously developed land low risk"
          onChange={props.onGeologyChange}
        />{' '}
        Previously developed land low risk
        <span className="checkmark"></span>
      </label>
      <label className="radioCheckbox">
        <Field
          name="geology"
          component="input"
          type="radio"
          value="Previously developed land high risk"
          onChange={props.onGeologyChange}
        />{' '}
        Previously developed land high risk
        <span className="checkmark"></span>
      </label>
      <Field name="geology" component={renderError} />
      


    </div>
	)
}