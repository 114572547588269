import { SET_INFLATION } from '../actions';

export default function(state, action) {


  // switch (action.type) {
  //
  //   case SET_INFLATION:
  //   console.log("inflation",action.payload)
  //
  //   		return action.payload;
  //
  //   // case GET_INFO:
  //   //
  //   // 	return state;
  //
  //   default:
  //   if(this.state === "")
  //       return 4
  //   }else{
  //     return state
  //   }
  //
  //
  // }

if(action.type === SET_INFLATION){
  console.log("inflation",action.payload)

      return action.payload;


}else{

  if(typeof state !== 'number'){
    console.log("set4")
      return 4
  }else{
    console.log("keep")
    return state
  }

}



}
