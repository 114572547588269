const validate = values => {
  const errors = {}
  if (!values.location) {
    errors.location = 'Please enter a country'
  }
  if (!values.climate) {
    errors.climate = 'Please click the map to select an area'
  }
  if (!values.planning) {
    errors.planning = 'Required'
  }
  if(values.planning === 'population')
  {
    if (!values.population) {
      errors.population = 'Required'
    } else if (!Number.isInteger(parseInt(values.population))) {
      errors.population = 'Invalid number'
    }
  }
  else
  {
    if (!values.area) {
      errors.area = 'Required'
    } else if (!Number.isInteger(parseInt(values.area))) {
      errors.area = 'Invalid number'
    }
  }
  
  if (!values.density) {
    errors.density = 'Required'
  }
  if (!values.geology) {
    errors.geology = 'Required'
  }
  if (!values.demographic) {
    errors.demographic = 'Required'
  }
  return errors
}

export default validate
