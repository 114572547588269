import L from 'leaflet';
import imgMarker from '../images/marker-icon.png';
import imgMarkerRet from '../images/marker-icon-2x.png';
import imgMarkerShad from '../images/marker-shadow.png';

var defaultIcon = L.icon({
	iconUrl: imgMarker,
	iconRetinaUrl: imgMarkerRet,
	shadowUrl: imgMarkerShad,
	iconAnchor: [15, 42],
	iconSize: [25, 41]
});

export { defaultIcon };