import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import Header from './header';
import Footer from './footer';
import HomePage from './home-page';
import LoginPage from './login-page';
import { saveInfo, getInfo } from '../actions/';

class FrontPage extends Component {
	
	componentDidUpdate(){
		
		if(this.props.info)
		{
			if(this.props.info.email)
			{
				this.hasLoggedIn = true;
			}
		}
	}

	constructor(props){
		super(props)
		
		this.handleLogin = this.handleLogin.bind(this);
	
		this.hasLoggedIn = false;
		if(this.props.info)
		{
			if(this.props.info.email)
			{
				this.hasLoggedIn = true;
			}
		}
	}
	handleLogin(response){
		if(response)
		{
			this.props.saveInfo({key:"email", value:response.email});
			this.props.saveInfo({key:"userID", value:response.userID});
			this.props.saveInfo({key:"version", value:1});
		}
		else
		{

		}
	}
	render(){
		return (
			<div>
				{this.hasLoggedIn ? (
					<HomePage history={this.props.history} handleContactSubmit={this.props.handleContactSubmit} openLeftMenu={this.props.openLeftMenu} />
				) : (
					<LoginPage handleLogin={this.handleLogin}  />
				)}
			</div>
		)
	}
	
}

function mapStateToProps(state){
	
	return state;
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ saveInfo, getInfo }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontPage)
