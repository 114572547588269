import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

export default function handleSaveDataSubmit(data, email){
	const newData = {
		'email' : email ? email : "",
		'data' : data ? data : ""
	}
	let Encoded = Object.keys(newData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(newData[k])}`).join('&');
	
	axios({
		url: 'https://communications.aecom.com/l/377462/2018-11-21/f63t3n?' + Encoded ,
		method:'post',
		adapter: jsonpAdapter,
		jsonpCallback: function(response){console.log(response)},
		callbackParamName: 'callback' // optional, 'callback' by default
	}).then((response) => {
		if(response.data.result === 'done')
		{
			console.log("success")
		}
		else
		{
			console.log("fail")
		}
	})
}


