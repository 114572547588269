import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Modal from 'react-modal';
import GoogleAnalytics from "react-ga";
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

import { getResults, saveModel } from '../actions';
import InfoDisplay from './infoDisplay';
import Header from './header';
import BlueHeader from './blue-header';
import Footer from './footer';
import TotalCost from './total-cost';
import ModelSpec from './model-spec-list-results';
import ResultsList from './results-list';
import RadialBar from './RadialBar';
import StackedBar from  './StackedBar';
import ResultListBuilder from './result-list-builder';
import { iconsSpec, iconsTotals, iconsBreakdowns } from '../icons/results';
import { ResultsTransition } from '../transitions';
import ContactBar from './contact-bar';
import calculateResults from './calculate-results';
import handleContactSubmit from './handleContactSubmit';
import handleSaveDataSubmit from './handleSaveDataSubmit';
import roundLargeNumbers from './roundLargeNumbers';


class Results extends Component {

	componentDidMount(){


	}

	constructor(props){
		super(props);
		if(this.props.form.wizard)
		{
			this.props.getResults(this.props.form.wizard.values);
		}
		else if(this.props.match.params.id)
		{

			if(this.props.models[this.props.match.params.id])
			{
				this.resultData = this.props.models[this.props.match.params.id].results;
			}
		}
		else
		{
			this.props.history.push({ pathname: "/", state: {transition:"page-fade", duration:2000} })
		}
		this.openContactModal = this.openContactModal.bind(this);
		this.openSaveModal = this.openSaveModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeContactModal = this.closeContactModal.bind(this);
		this.closeSaveModal = this.closeSaveModal.bind(this);
		this.saveResults = this.saveResults.bind(this);
		this.handleSaveSubmit = this.handleSaveSubmit.bind(this);
		this.handleContactSubmit = this.handleContactSubmit.bind(this);
		this.state = {
			showBreakdown : false,
			breakdownKey: "no-key",
			breakdownData:{},
			modalSaveIsOpen: false,
			saved : false
		};

		this.footerBtns = {
      restart : {
        text : "Restart",
        callback : () => props.history.push({ pathname: "/modeling", state: {transition:"page-fade", duration:700} })
      },
      save : {
        text : "Save",
        callback : this.openSaveModal
      },
      viewAll : {
        text : "View All",
        callback : () => props.history.push({ pathname: "/view-all", state: {transition:"page-fade", duration:700} })
      }
		}

		this.customStyles = {
		  content : {
		    top                   : '50%',
		    left                  : '50%',
		    right                 : 'auto',
		    bottom                : 'auto',
		    marginRight           : '-50%',
		    transform             : 'translate(-50%, -50%)'
		  }
		};
	}

	openContactModal(type, a, b) {
		this.setState({modalContactIsOpen: true});
  }

  openSaveModal(type, a, b) {
		this.setState({modalSaveIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.

  }

  closeContactModal() {
    this.setState({modalContactIsOpen: false});
  }

  closeSaveModal() {
    this.setState({modalSaveIsOpen: false});
  }

	loadBreakdown(key){
		if(this.resultData)
		{
			if(this.resultData.breakdowns[key])
			{
				this.setState({
					showBreakdown : true,
					breakdownKey: key
				})
			}
		}
		else
		{
			if(this.props.results.breakdowns[key])
			{
				this.setState({
					showBreakdown : true,
					breakdownKey: key
				})
			}
		}

	}
	handleSaveSubmit(e){
		e.preventDefault();

		this.saveResults(e.target.modelName.value ? e.target.modelName.value : null)
		this.closeSaveModal();
	}

	saveResults(modelName){

		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var seconds = today.getSeconds();
		var minutes = today.getMinutes()
		var yyyy = today.getFullYear();
		today = mm + '-' + dd + '-' + yyyy;
		var todayFull = seconds + '-' +minutes + '-' +today;
		let title
		if(modelName)
		{
			title = modelName;
		}
		else
		{
			title = "Model from " + today;
		}
		let currentState = {
			form : this.props.form,
			results : this.props.results,
			info : {
				dateTime : new Date(),
				title : title
			}
		}

		let updatedModels = _.cloneDeep(this.props.models)
		updatedModels[todayFull] = currentState;

		this.props.saveModel({key : todayFull, model : currentState});


		let saveData = {};
		saveData["info"] = this.props.info;
		saveData[title] = currentState.results;

		saveData = JSON.stringify(saveData);
		GoogleAnalytics.event({
		  category: 'Save',
		  action: 'ModelSaved',
		  label: "Saved model"
		});

		handleSaveDataSubmit(saveData, this.props.info.email)
		this.setState({saved:true});

	}

	handleContactSubmit(data){
		handleContactSubmit(data, this.props.info.email)
	}

	render(){

		let data;
		if(this.resultData)
		{
			data = this.resultData;
		}
		else
		{
			data = this.props.results;
		}

		if(data.breakdowns)
		{

console.log(data)

			const { perCapita, total } = data;
			const transportCost = data.totals.transport.value;
			const transportData = _.map(data.breakdowns.transport, function(item, key){
				let percentage = (item.value / transportCost) * 100;
				return {name:item.name, value:percentage}
			})
			const utilitiesData = _.map(data.breakdowns.utilities, function(item, key){
				return {name:item.name, value:item.percentage}
			})
			const socialData = _.map(data.breakdowns.socialInfrastructure, function(item, key){
				return {name:item.name, value:item.percentage}
			})

			return(
				<div className="results">
					<div className="page-content">
						<div className="bg-image"></div>
						<div className="page">
							<BlueHeader title="Results" text="To get the full picture register and save your results" />
							<div className="content-wrap">
								<div className="all-80 push-center content">

									<TotalCost total={total} perCapita={perCapita} />

										<ResultsTransition pageKey={this.state.breakdownKey} duration={700} transition={"results-fade"}>
											{ this.state.showBreakdown && <div onClick={() => (this.setState({showBreakdown : false,breakdownKey: null, breakdownData:{}}))} className="back-arrow"></div> }

											{ !this.state.showBreakdown && <div className="all-33 tiny-100 small-100 results-list-wrap"><ModelSpec {...data.answers} icons={iconsSpec} /></div> }

											<div className="all-33 tiny-100 small-100 results-list-wrap">
												<ResultListBuilder data={data.totals} loadBreakdown={this.loadBreakdown.bind(this)} icons={iconsTotals} />
											</div>

											{!this.state.showBreakdown && (
												<div className="all-33 tiny-100 small-100 results-list-wrap" id="stacked-bar-wrap"  >
													<ul>
														<li><StackedBar data={socialData} breakdownKey="social" /></li>
														<li><StackedBar data={transportData} breakdownKey={"transport"} /></li>
														<li><StackedBar data={utilitiesData} breakdownKey="utilities" /></li>
													</ul>
												</div>
											)}
											{ this.state.showBreakdown && this.state.breakdownKey === 'transport' && <div className="all-33 tiny-100 small-100 results-list-wrap" id="radial-bar-wrap"  ><RadialBar data={transportData} /></div> }
											{ this.state.showBreakdown && this.state.breakdownKey === 'socialInfrastructure' && <div className="all-33 tiny-100 small-100 results-list-wrap" id="radial-bar-wrap"  ><RadialBar data={socialData} /></div> }
											{ this.state.showBreakdown && this.state.breakdownKey === 'utilities' && <div className="all-33 tiny-100 small-100 results-list-wrap" id="radial-bar-wrap"  ><RadialBar data={utilitiesData} /></div> }


											{ this.state.showBreakdown && <div className="all-33 tiny-100 small-100 results-list-wrap"><ResultListBuilder data={data.breakdowns[this.state.breakdownKey]} icons={iconsBreakdowns} /> </div>}
										</ResultsTransition>

								</div>
							</div>
						</div>
					</div>

					<Footer page="results" buttonData={this.footerBtns} modelSaved={this.state.saved}  />
					<ContactBar className="bar-bottom" onSubmit={this.handleContactSubmit} type="text" text="FIND OUT HOW WE CAN HELP YOU DELIVER YOUR CITY" />
	        <Modal
	          isOpen={this.state.modalSaveIsOpen}
	          onAfterOpen={this.afterOpenModal}
	          onRequestClose={this.closeSaveModal}
	          contentLabel="Example Modal"
	          ariaHideApp={false}
	          className="save-modal"
	          overlayClassName="save-overlay"
	        >
	        	<div className="ink-grid">
	        		<div className="column-group gutters">
		        		<div className="all-80 push-center">
		        			<div className="modal-content">
					          <h2>Save</h2>

					          <p>Give your Model a title and add it to a group for easy comparison</p>
					          <form onSubmit={this.handleSaveSubmit}>
					            <input type="text" name="modelName"  />
					            <button className="push-right black-btn">Save</button>
					          </form>
					        </div>
				        </div>
				      </div>
		         </div>
	        </Modal>
				</div>
			)
	}
	else
	{
		return(
			<p>Loading...</p>
		)
	}
	}
}

function mapStateToProps(state){

	let results;
	if(state.form.wizard)
	{
		results = calculateResults(state.form.wizard.values, state.inflation);
	}
	else
	{
		results = false;
	}

	const newState = {
		...state,
		results : results
	}

	return newState;

}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ getResults, saveModel }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Results)
