import React, { Component } from 'react';

import Duplicate from './btn-duplicate';
import Delete from './btn-delete';
import TotalCostList from './total-cost-list';
import { iconsTotals, iconsEdit } from '../icons/results';
import InputName from './input-name';

export default class ViewAllItem extends Component{

	constructor(props){
		super(props)

		this.handleEditClick = this.handleEditClick.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.state = {
			showEdit : false,
			nameValue : this.props.models[this.props.modelKey].info.title
		}
	}
	//openDuplicateModal={this.openDuplicateModal}
	updateValue(value, b){
		this.setState({
			...this.state,
			nameValue : value.target.value
		})
	}
	handleEditClick(){

		let newState = {
			...this.state
		};

		if(this.state.showEdit)
		{
			newState.showEdit = false;
		}
		else
		{
			newState.showEdit = true;
		}

		this.setState(newState)
	}

	render(){
		const _this = this;
		const { modelKey } = this.props;
		return(
				<div className="compareItem">

						{!this.state.showEdit && (
							<h3>
								<span className="model-name" onClick={() => _this.props.history.push({ pathname: "/results/"+modelKey, state: {transition:"page-fade", duration:700} })}>{this.props.models[modelKey].info.title}</span>
								<label className="radioCheckbox push-right"><input type="checkbox" name={"model"+modelKey} value={modelKey} className="push-right models" /><span className="checkmark"></span></label>
								<span className="edit icon push-right" onClick={this.handleEditClick} style={{backgroundImage:`url(${iconsEdit.edit.primary})`, backgroundRepeat: 'no-repeat' }}></span>
								<Duplicate openDuplicateModal={(key) => this.props.openDuplicateModal(key)} modelKey={modelKey}  iconPath={iconsEdit.copy.primary} />
								<Delete modelKey={modelKey} handleDeleteClick={(modelKey) => {this.props.handleDeleteClick(modelKey)}} iconPath={iconsEdit.delete.primary} />
							</h3>
						)}
						{this.state.showEdit && (
							<h3>
								<InputName value={this.state.nameValue} onChange={this.updateValue} />
								<span className="edit icon push-right" onClick={() => {this.props.saveModelName(modelKey, 'title', this.state.nameValue);this.handleEditClick()}} style={{backgroundImage:`url(${iconsEdit.tick.primary})`, backgroundRepeat: 'no-repeat' }}>
								</span>
							</h3>
						)}

					<div className="compare-list-wrap total-cost-wrap"><TotalCostList total={this.props.results.total} perCapita={this.props.results.perCapita} icons={iconsTotals}/></div>

				</div>
		)
	}

}
