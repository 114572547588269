import React, { Component } from 'react';


export default class DuplicateBtn extends Component{
	
	constructor(props){
		super(props)

		this.openDuplicateModal = this.openDuplicateModal.bind(this);
		this.closeDuplicateModal = this.closeDuplicateModal.bind(this);
		this.state = {
			modalDuplicateIsOpen: false
		}
	}

	openDuplicateModal() {
		this.setState({modalDuplicateIsOpen: true});
  }

  closeDuplicateModal(e) {
  	this.props.handleSubmit()
  	this.setState({modalDuplicateIsOpen: false});
  }

  handleChange(){

  }

  handleFormSubmit(a){
  	a.handleSubmit()
  }

	render(){
		const { modelKey } = this.props;
		return (
			
			<span className="edit icon push-right" style={{backgroundImage:`url(${this.props.iconPath})`, backgroundRepeat: 'no-repeat' }} onClick={() => {this.props.openDuplicateModal(modelKey)}}>
				
			</span>
		)

	}
	
}

