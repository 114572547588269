import React from 'react';
import { Field } from 'redux-form';

const renderRadioCheckbox = ({onChange, value, label, name }) => {
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span>{error}</span> : false;

  return (
    <span className="radioCheckboxWrap">
      <label className="radioCheckbox">
        <Field
          name={name}
          component="input"
          type="checkbox"
          value={value}
          onChange={onChange}
        />
        <span className="checkmark"></span>
        <span className="label">{label}</span>
      </label>
      <Field name={name} component={renderError} />
    </span>
  )
}


export default renderRadioCheckbox
