import React, { Component } from 'react';

export default class InfoDisplay extends Component{
	
	render(){
		const { title, text } = this.props.workingOutDisplay;
		return(
			<div>
				<h6>{ title }</h6>
				<p>{ text }</p>
			</div>
		)	
	}
	
}
