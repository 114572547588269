import * as d3 from "d3";

export function radialBarChart() {
    // All options that should be accessible to caller
    var margin = {top: 10, right: 15, bottom: 40, left: 15};
    var containerWidth = 400;
    var containerHeight = 400;
    var width, height, colors, stackKeys;
    
    
    var PI = Math.PI;
    var arcMinRadius = 40;
    var arcPadding = 0;
    var labelPadding = -5;
    var numTicks = 10;
    var currentRegion;

    var scale = d3.scaleLinear().domain([0, 100]).range([0, 2 * PI]);
    var ticks = scale.ticks(numTicks).slice(0, -1);
    



    var data = [];
    var updateWidth;
    var updateHeight;
    var updateFillColor;
    var updateData;
    var updateSingleBar;
    var updateColors;

    function chart(selection) {
        
        selection.each(function() {
            
            var color = d3.scaleOrdinal()
            .range(colors);
            width = width - margin.left - margin.right
            height = width ;
            var chartRadius = height / 2;
            
            var keys = data.map(function (d, i) {
                return d.region;
            });
            var numArcs = keys.length;
            if(numArcs < 5)
            {
                arcMinRadius = 90;
            }
           
            color.domain(stackKeys);
            
            var arcWidth = ((chartRadius - arcMinRadius - numArcs * arcPadding) / numArcs)-2;

            var arc = d3.arc().innerRadius(function (d, i) {
                return getInnerRadius(i);
            }).outerRadius(function (d, i) {
                return getOuterRadius(i);
            }).startAngle(0).endAngle(function (d, i) {
                return scale(d);
            });

            var dom = d3.select(this);
            var svg = dom.append('svg')
                .attr('width', width)
                .attr('height', height)
                //.attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .append('g')
                .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
                .attr('class', 'radial-bar-chart');

            var radialAxis = svg.append('g').selectAll('g').data(data, function(d){
                d.value = Math.round(d.value)
                return d;
            }).enter().append('g');

            radialAxis.append('path')
                .style("fill", "#ffffff")
                .style("opacity", .1)
                .attr("class", "axis")
                .transition()
                .duration(1000)
                .attrTween('d', function(d, i) {
                     if(d.value != 0)
                     {
                        var interpolate = d3.interpolate(0, 100);
                        return function (t) {
                            d.endAngle = interpolate(t);
                            return arc(interpolate(t), i);
                        };
                     }
                })

            var tooltip = svg.append('g')
                .attr('class', 'tooltip');
            var tooltipText = tooltip.append('text')
                .attr('text-anchor', 'middle')
                .attr("class", "tooltip-text")
                .attr("dx", 3)
                .attr("dy", 10);
            var globalValue;
            globalValue = Math.round(data[0].value);

            tooltipText
                .style("opacity", 0)
                .transition()
                .delay(function (d, i) {
                    return i * 200;
                })
                .duration(2000)
                .style("opacity", 1)
                .text(Math.round(globalValue)+"%")
                .attr("fill", "#ffe400")


            var arcs = svg.append('g')
                .attr('class', 'data')
                .selectAll('path.arc')
                .data(data, function(d){ return d.name})
                .enter()
                .append('path')
                .attr('class', 'arc')
                .style('fill', function (d, i) {
                    return color(d.name);
                });

            arcs.transition()
                .transition()
                .delay(function (d, i) {
                    return i * 200;
                })
                .duration(1000)
                .attrTween('d', arcTween);

            dom.append("span")
                .text(function(){
                    if(data.title)
                    {
                        return data.title;
                    }
                })
                .attr("class", "label push-center");

            arcs.on('click', function(d){

                tooltipText.text(Math.round(d.value)+'%').attr('fill', function () {
                    return color(d.name);
                });
            });
            function arcTween(d, i) {
                if(this._current)
                {
                    var startAngle = Math.round(this._current.value);
                }
                else
                {
                    var startAngle = 0;
                }

                var interpolate = d3.interpolate(startAngle, Math.round(d.value));
                this._current = d;
                return function (t) {

                    return arc(interpolate(t), i);
                };
            }

            function arcTween2(d, i) {

                var interpolate = d3.interpolate(27, 0);
                return function (t) {
                    d.endAngle = interpolate(t);
                    return arc(interpolate(t), i);
                };
            }

            function getInnerRadius(index) {
                return arcMinRadius + (numArcs - (index + 1)) * (arcWidth + arcPadding);
            }

            function getOuterRadius(index) {
                return getInnerRadius(index) + arcWidth;
            }
            updateColors = function() {

            };

            // update functions
           /* updateWidth = function() {
                widthScale = width / maxValue;
                bars.transition().duration(1000).attr('width', function(d) {
                    return d * widthScale;
                });
                svg.transition().duration(1000).attr('width', width);
            };*/
            /*updateHeight = function() {
                barSpacing = height / data.length;
                barHeight = barSpacing - barPadding;
                bars.transition().duration(1000).attr('y', function(d, i) {
                        return i * barSpacing;
                    })
                    .attr('height', barHeight);
                svg.transition().duration(1000).attr('height', height);
            };*/
            updateFillColor = function() {
                /*svg.transition().duration(1000).style('fill', fillColor);*/
            };
            
            updateSingleBar = function(barName, ele){
                var tempBars = d3.select(ele.get(0)).selectAll("path.arc");
                tempBars.transition()
                    .duration(300)
                    .attrTween( 'd', function(d, i) {
                        if(d.name == barName)
                        {

                            if(d.state)
                            {
                                if(d.state == "off")
                                {
                                    if(d._value)
                                    {
                                        d.value = d._value
                                        delete d._value;
                                    }
                                    delete d.state;
                                    
                                    d3.select(ele.get(0)).selectAll("text.tooltip-text")
                                    .attr("fill", color(d.name))
                                    .text(Math.round(d.value) + "%");
                                    
                                    var interpolate = d3.interpolate(0, Math.round(d.value));
                                    return function (t) {
                                        d.endAngle = interpolate(t);
                                        return arc(interpolate(t), i);
                                    };
                                }
                                else
                                {
                                    var interpolate = d3.interpolate(Math.round(d.value), 0);
                                    d.state = "off";
                                    d._value = d.value;
                                    d.value = 0;
                                    
                                    var globalValue;
                                    
                                    data.forEach(function(d, i){
                                        if(d.name == "Global")
                                        {
                                            globalValue = d.value;
                                        }
                                    })

                                     d3.select(ele.get(0)).selectAll("text.tooltip-text")
                                    .attr("fill", color("Global"))
                                    .text(Math.round(globalValue)+"%");

                                    return function (t) {
                                        d.endAngle = interpolate(t);
                                        return arc(interpolate(t), i);
                                    };

                                }

                            }
                            else
                            {
                                var interpolate = d3.interpolate(Math.round(d.value), 0);
                                d.state = "off";
                                d._value = d.value;
                                d.value = 0;

                                var globalValue;
                                    
                                data.forEach(function(d, i){
                                    if(d.name == "Global")
                                    {
                                        globalValue = d.value;
                                    }
                                })

                                 d3.select(ele.get(0)).selectAll("text.tooltip-text")
                                .attr("fill", color("Global"))
                                .text(Math.round(globalValue)+"%");

                                return function (t) {
                                    d.endAngle = interpolate(t);
                                    return arc(interpolate(t), i);
                                };
                            }

                        }
                        
                      });
                var tempAxisBars = d3.select(ele.get(0)).selectAll("path.axis");
                tempAxisBars.transition()
                    .duration(300)
                    .attrTween( 'd', function(d, i) {
                        if(d.name == barName)
                        {

                            if(d.state)
                            {
                                if(d.state == "off")
                                {


                                    var interpolate = d3.interpolate(100, 0);
                                    return function (t) {
                                        d.endAngle = interpolate(t);
                                        return arc(interpolate(t), i);
                                    };
                                }
                                else
                                {

                                    var interpolate = d3.interpolate(0, 100);
                                    return function (t) {
                                        d.endAngle = interpolate(t);
                                        return arc(interpolate(t), i);
                                    };
                                }

                            }
                            else
                            {

                                var interpolate = d3.interpolate(0, 100);
                                return function (t) {
                                    d.endAngle = interpolate(t);
                                    return arc(interpolate(t), i);
                                };
                            }

                        }
                        else
                        {
                            return null;
                        }

                      } );

            };
            updateData = function() {

                keys = data.map(function (d, i) {
                return d.region;
                });
                numArcs = keys.length;
                color.domain([0, numArcs]);
                arcWidth = ((chartRadius - arcMinRadius - numArcs * arcPadding) / numArcs)-2;
                arc = d3.arc().innerRadius(function (d, i) {
                    return getInnerRadius(i);
                }).outerRadius(function (d, i) {
                    return getOuterRadius(i);
                }).startAngle(0).endAngle(function (d, i) {
                    return scale(d);
                });

                var update = svg.selectAll('path.arc')
                    .data(data, function(d){ return d.name })
                    .style('fill', function (d, i) {
                        return color(d.name);
                    });

                update
                    .transition()
                    .duration(1000)
                    .attrTween('d', arcTween)

                update.enter()
                    .append('path')
                    .attr('class', 'arc')
                    .style('fill', function (d, i) {
                        return color(d.name);
                    })
                    .transition()
                    .attrTween('d', arcTween)
                    .duration(1000)

            }
              
        });
    }
    chart.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        if (typeof updateWidth === 'function') updateWidth();
        return chart;
    };
    chart.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        if (typeof updateHeight === 'function') updateHeight();
        return chart;
    };
    /*chart.fillColor = function(value) {
        if (!arguments.length) return fillColor;
        fillColor = value;
        if (typeof updateFillColor === 'function') updateFillColor();
        return chart;
    };*/
    chart.data = function(value, region) {
        if (!arguments.length) return data;
        data = value;
        
        
        if(region)
        {
            currentRegion = region
        }
        if (typeof updateData === 'function') updateData();
        return chart;
    };
    chart.toggleBar = function(barName, ele) {
        if (!arguments.length) return data;

        if (typeof updateSingleBar === 'function') updateSingleBar(barName, ele);
        return chart;
    };
    chart.colors = function(value) {
        if (!arguments.length) return colors;
        colors = value;
        if (typeof updateColors === 'function') updateColors();
        return chart;
    };
    chart.init = function(data, ele, tempWidth, tempColors, tempStackKeys) {
        if (!arguments.length) return data;
        width = tempWidth;
        chart.data(data, null);
        colors = tempColors;
        stackKeys = tempStackKeys;
        d3.select(ele).call(chart);
        
        return chart;
    };
    
    return chart;
}


export function horizontalStackedBarChart() {

    var stackKeys,
    colors,
    data = [],
    allData = {},
    margin = {top: 0, right: 5, bottom: 0, left: 0},
    parseDate = d3.timeParse("%m/%Y"),
    //width = width - margin.left - margin.right,
    height = 15 - margin.top - margin.bottom;
    var xScale, yScale, xAxis, yAxis, updateData, width;
    
    var updateWidth;
    var updateHeight;
    var updateColors;
    var updateData;
    var updateStackKeys;

    function chart(selection) {
        
        var color = d3.scaleOrdinal()
        .range(colors);
        
        color.domain(stackKeys);
        
        width = width - margin.left - margin.right;
        xScale = d3.scaleLinear().rangeRound([0, width]);
        yScale = d3.scaleBand().rangeRound([height, 0]).padding(0.1);
        xAxis = d3.axisBottom(xScale);
        yAxis =  d3.axisLeft(yScale);

        selection.each(function() {
            var dom = d3.select(this);
            var svg = dom.append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var stack = d3.stack()
            .keys(stackKeys)
            /*.order(d3.stackOrder)*/
            .offset(d3.stackOffsetNone);

        var layers = stack(data);
            yScale.domain(data.map(function(d) {
               
                return d.Keys;
            }));

            var totalValue = 0;
            for(var index in data[0])
            {
                if(index != "answerCode" && index != "Keys" && index != "Region")
                {
                    totalValue += data[0][index]
                }
            }

            xScale.domain([0, totalValue]);

            var label = d3.select(this).selectAll(".stacked-bar-header")
            label.text(data[0].Keys)
            
            //color.domain([0, layers.length])
            
            var layer = svg.selectAll(".layer")
            .data(layers)
            .enter().append("g")
            .attr("class", "layer")
            .style("fill", function(d, i) { 
                return color(d.key); 
            });

            var rect = layer.selectAll("rect")
              .data(function(d) { 
                return d; 
            })
            .enter().append("rect")
              .attr("y", function(d) { return yScale(d.data.Keys); })
              .attr("x", 0)
              .attr("height", yScale.bandwidth())
              .attr("width", 0)

              rect.transition()
              .duration(1000)
              .delay(function (d, i) {
                    return i * 200;
                })
              .attr("width", function(d) {
                
                return xScale(d[1]) - xScale(d[0])
                })
              .attr("x", function(d) { return xScale(d[0]); })

              layer.append("text")
              .attr("x", 0)
              .style("opacity", 0)
              .transition()
              .duration(1000)
              .style("opacity", 1)
              .text(function(d){
                
                var val = (d[0][1] - d[0][0]);
                if(val < 8)
                {
                    return null;
                }
                else
                {
                    
                    val+="%";
                }
                
                return "";
              })
              .attr("x", function(d){
                
                return xScale(d[0][1])  - 5;
              })
              .attr("y", "26")
              .attr("text-anchor", "end")
              .attr("width", "30")
              .attr("height", "40")
              .attr("fill", "#000000")

            // update functions
            updateWidth = function() {

            };
            updateHeight = function() {

            };
            updateColors = function() {

            };
            updateStackKeys = function() {

            };
            updateData = function() {

                var layers = stack(data);
                
                var totalValue = 0;
                for(var index in data[0])
                {
                    if(index != "answerCode" && index != "Keys" && index != "Region")
                    {
                        totalValue += data[0][index]
                    }
                }

            xScale.domain([0, totalValue]);

                layer = svg.selectAll(".layer")
                .data(layers)
                .style("fill", function(d, i) { 
                    return color(i);
                })


                rect = layer.selectAll("rect")
                .data(function(d) { return d; })
                rect.transition()
                .ease(d3.easeBounce)  
                .duration(1000)
                .delay(function (d, i) {
                    return i * 200;
                })
                .attr("width", function(d) {
                    
                    return (xScale(d[1]) - xScale(d[0]))
                })
                .attr("x", function(d, i) { return xScale(d[0]); })

                layer.selectAll("text")
                .data(function(d) { return d; })
                .text(function(d){

                
                    var val = (d[1] - d[0]);
                    
                    if(val < 8)
                    {
                        val = null;
                    }
                    else
                    {
                        val+="%";
                    }
                        
                    return val;
                })
                .transition()
                .ease(d3.easeBounce) 
                .duration(1000)
                .attr("x", function(d){
                    
                    return xScale(d[1])  - 5;
                })
                .style("opacity", function(d){
                    if((d[1] - d[0]) == 0)
                    {
                        return 0;
                    }
                    else
                    {
                        return 1;
                    }
                })
                
            }
        });
    }
    chart.width = function(value) {
        if (!arguments.length) return width;
        width = value;
        if (typeof updateWidth === 'function') updateWidth();
        return chart;
    };
    chart.height = function(value) {
        if (!arguments.length) return height;
        height = value;
        if (typeof updateHeight === 'function') updateHeight();
        return chart;
    };
    chart.colors = function(value) {
        if (!arguments.length) return colors;
        colors = value;
        if (typeof updateColors === 'function') updateColors();
        return chart;
    };
    chart.stackKeys = function(value) {
        if (!arguments.length) return stackKeys;
        stackKeys = value;
        if (typeof updateStackKeys === 'function') updateStackKeys();
        return chart;
    };
    chart.data = function(value, tempWidth) {
        if (!arguments.length) return data;
        data = value;
        width = tempWidth;
        if (typeof updateData === 'function') updateData();
        return chart;
    };
    chart.init = function(data, width,  ele, colors, stackKeys) {
        if (!arguments.length) return data;
        chart.data(data, width, "Europe");
        chart.colors(colors);
        chart.stackKeys(stackKeys);

        d3.select(ele).call(chart);
        
        return chart;
    };
    
    return chart;
}
