import React from 'react';
import logo from '../images/AECOM_logo_white.png';
import ModelSubmitButton from './btn-model-submit';
import Button from './btn-footer';

export default function footer(props){
	const { page, modelSaved }  = props;
	return (
		<div className="footer">

		

			{/*<span className="logo">
				<img  src={logo} alt="AECOM"/>
			</span>*/}
			<div className="push-center logo">
				<img src={logo} alt="AECOM" />
			</div>
			{ page === 1 && (
				<div className="footer-btns">
					<Button text="Back" callback={props.buttonData.navigateBack.callback} />
					<ModelSubmitButton text="Next" />
				</div>
			)}

			{ (page > 1 && page < 7) && (
				<div className="footer-btns">
					<Button text="Back" callback={props.buttonData.back.callback} />
					<ModelSubmitButton text="Next" />
				</div>
			)}
			
			{ page === 7 && (
				<div className="footer-btns">
					<Button text="Back" callback={props.buttonData.back.callback} />
					<ModelSubmitButton text="Next" />
				</div>
			)}
			{ page === 'results' && (
				<div className="footer-btns">
					<Button text="Restart" callback={props.buttonData.restart.callback} />
					{modelSaved && <Button text="View all models" callback={props.buttonData.viewAll.callback} />}
					{!modelSaved && <Button text="Save" callback={props.buttonData.save.callback} />}
					
				</div>
			)}
			{ page === 'view-all' && (
				<div className="footer-btns">
					<Button text="New Model" callback={props.buttonData.restart.callback} />
					<Button text="View Comparisons" callback={props.buttonData.compare.callback} />
				</div>
			)}
			{ page === 'compare' && (
				<div className="footer-btns">
					<Button text="New Model" callback={props.buttonData.restart.callback} />
					<Button text="View all models" callback={props.buttonData.viewAll.callback} />
				</div>
			)}
			<span className="buttons push-right"></span>
			
			
		</div>
	)
}