import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'



function Button(props){

	return (
		<a
		className="push-right btn-footer"
    onClick={() => props.dispatch(submit('wizard'))}>{props.text}</a>
	)
}

export default connect()(Button)