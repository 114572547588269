import React from 'react';

export default function ProgressBar(props){
	const { page, values } = props;
	var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0];
    const pageWidth = w.innerWidth || e.clientWidth || g.clientWidth;

    let barWidth = (100 / 7) * (page - 1);
    let areaPopSwitch;
    
    if(!values.planning)
    {
    	areaPopSwitch = {
    		label : "Population",
    		value : ""
    	}
    }
    else
    {
    	areaPopSwitch = {
    		label : values.planning,
    		value : values[values.planning]
    	}
    }
	return(
		<div className="column-group all-100">
			<div className="all-80 push-center progress-bar">
				<div className="highlight" style={{width:barWidth+"%"}}></div>
				<div><span className="label">Location</span><span className="value push-right">{(values.location) ? values.location.label : ""}&nbsp;</span></div>
				<div><span className="label">Climate</span><span className="value push-right">{values.climate ? values.climate : ""}&nbsp;</span></div>
				<div><span className="label">Planning</span><span className="value push-right">{values.planning ? values.planning : ""}&nbsp;</span></div>
                <div><span className="label">Density</span><span className="value push-right">{values.density ? values.density : ""}&nbsp;</span></div>
				<div><span className="label">{areaPopSwitch.label}</span><span className="value push-right">{ areaPopSwitch.value }&nbsp;</span></div>
				<div><span className="label">Geology</span><span className="value push-right">{values.geology ? values.geology : ""}&nbsp;</span></div>
				<div><span className="label">Demographic</span><span className="value push-right">{values.demographic ? values.demographic : ""}&nbsp;</span></div>
			</div>
		</div>
	)
}