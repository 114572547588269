import _ from 'lodash';
import React, { Component } from 'react';
import numberWithCommas from './numberWithCommas';
import StackedBar from  './StackedBar';

class ListBuilder extends Component {
	componentDidMount(){
		this.currency = "$";
	}

	handleClick(e, key){
		if(this.props.loadBreakdown)
		{
			this.props.loadBreakdown(key);
		}
	}

	renderList(){
		const totals = this.props.data;
		
		return _.map(totals, (datum, key) => {
			let myCursor;
			let arrow;
			let barGraph;
			let iconType = "primary";
			
			if(datum.breakdown)
			{
				myCursor = "pointer";
				arrow = "arrow";
				barGraph = "";
				iconType = "tertiary";
			}
			else
			{
				myCursor = "default";
				arrow = "";
				barGraph = "";
			}
			
			return (
				<li className={"list-group-item " + arrow + " " + key} key={key} onClick={(e) => this.handleClick(e, key)} style={{cursor:myCursor}} >
					<span className="icon push-left" style={{backgroundImage:`url(${this.props.icons[key][datum.color]})`, backgroundRepeat: 'no-repeat' }}>
					</span>

					<span className="label">{datum.name}</span><span className="value push-right">${numberWithCommas(datum.value)} {barGraph}</span>
				</li>
			);
	
		});	
	}

	render (){

		return (
			
			<ul className="list-group results-list-wrap">
				{this.renderList()}
			</ul>
			
		)
	}
}



export default ListBuilder