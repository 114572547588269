import React from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group";

export function Transition({ transition = '', duration = 0, pageKey, children }){

	return (
		<div className={transition+"-group"}>
			<TransitionGroup>
				<CSSTransition
					key={pageKey}
					timeout={{ enter: duration, exit: duration }}
					classNames={transition}
				>
					<div>{ children }</div>
				</CSSTransition>
			</TransitionGroup>
		</div>
	)
}

export function ResultsTransition({ transition = '', duration = 0, pageKey, children }){

	return (
		<TransitionGroup>
			<CSSTransition
				key={pageKey}
				timeout={{ enter: duration, exit: duration }}
				classNames={transition}
			>
				<div className={transition+"-group" + " column-group gutters"}>{ children }</div>
			</CSSTransition>
		</TransitionGroup>
	)
}


const childFactoryCreator = (props) => child => React.cloneElement(child, props)

export function RouteTransition({ transition = '', duration = 0, pageKey, children }){

	return (
		<TransitionGroup childFactory={childFactoryCreator({ classNames: transition, timeout: duration })} className="transition-group"  >
			<CSSTransition
				key={pageKey}
				timeout={0}
				classNames={transition}

			>
				<div className="route-page">
					{ children }
				</div>
			</CSSTransition>
		</TransitionGroup>
	)
}
