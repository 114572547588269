import React from 'react'

const style = {
  padding: '10px 20px',
  width: 140,
  display: 'block',
  margin: '20px auto',
  fontSize: '16px'
}

export default function Button(props){
  let position;
  switch(props.text){
    case "Back":
    case "Restart":
    case "New model":
      position = "push-left"
    break

    case "Next":
    case "Save":
    case "View Comparisons":
    case "View all models":
      position = "push-right"
    break
  }
	return (
		<a
		className={position}
    onClick={props.callback}>{props.text}</a>
	)
}

