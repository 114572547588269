export default function(population){
 
  if(parseInt(population) < 100000)
  {
    return 50000;
  }
  if(parseInt(population) < 250000)
  {
    return 100000;
  }
  if(parseInt(population) < 500000)
  {
    return 250000;
  }
  if(parseInt(population) < 1000000)
  {
    return 500000;
  }
  if(parseInt(population) < 2000000)
  {
    return 1000000;
  }
  if(parseInt(population) === 2000000)
  {
    return 2000000;
  }
}