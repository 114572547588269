import React from 'react';
import { Field } from 'redux-form';

export default function InputClimate(props){
	const renderError = ({ meta: { touched, error } }) =>
	touched && error ? <span>{error}</span> : false;

	return (
		<div className="all-50 small-100 tiny-100">
			<label className="radioCheckbox">
	      <Field
	        name="climate"
	        component="input"
	        type="radio"
	        value="arid"
	        onChange={props.onClimateChange}
	      />{' '}
	      Arid
	      <span className="checkmark"></span>
	    </label>
	    <label className="radioCheckbox">
	      <Field
	        name="climate"
	        component="input"
	        type="radio"
	        value="tropical"
	        onChange={props.onClimateChange}
	      />{' '}
	      Tropical
	      <span className="checkmark"></span>
	    </label>
	    <label className="radioCheckbox">
	      <Field
	        name="climate"
	        component="input"
	        type="radio"
	        value="temperate"
	        onChange={props.onClimateChange}
	      />{' '}
	      Temperate
	      <span className="checkmark"></span>
	    </label>
	    <label className="radioCheckbox">
	      <Field
	        name="climate"
	        component="input"
	        type="radio"
	        value="cold"
	        onChange={props.onClimateChange}
	      />{' '}
	      Cold
	      <span className="checkmark"></span>
	    </label>
	    <Field name="climate" component={renderError} />
	  </div>
	)
}