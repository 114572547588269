import { SET_COMPARISIONS } from '../actions';

export default function(state = {}, action) {
  switch (action.type) {
    
    case SET_COMPARISIONS:
    		
    		return action.payload.models
     
    default:
      return state
  }
}