import React from 'react';

export default function BlueHeader(props){
	return(
		<div className="column-group blue-header all-100">
			<div className="all-80 push-center">
				<h2>{props.title}</h2>
				<p>{props.text}</p>
			</div>
		</div>
	)
}