import React, { Component } from 'react';
import { Map, Marker, Popup, TileLayer, GeoJSON } from 'react-leaflet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import { getClimateData, getCountryGeoData } from '../json';
import { defaultIcon } from '../icons';

class LocationMap extends Component{
	
	constructor(props){
		super(props)
		
		this.handleClick = this.handleClick.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.state = {
			markers : [],
			animate: false,
			latlng: {
	      lat: this.props.position[0],
	      lng: this.props.position[1],
	    }
		}
		this.maxMarkerLength = 1;
		this.featuresById = {};
		this.countryBounds = {}
	}

	componentDidMount(){
		//this.props.fetchClimateData();
	}

	handleClick(event){
		this.addMarker(event);
	}

	addMarker(e){
	    const { markers } = this.state;
	   let coords;
	   
	   
	   	coords = e.latlng
	   
	   	
	   if(markers.length >= this.maxMarkerLength)
	   	{
	   		markers.shift();
	   		markers.push([coords.lat, coords.lng])
	   	}
	   	else
	   	{
	   		markers.push([coords.lat, coords.lng])
	   	}

	    this.setState({markers})
	}

	onEachFeature(feature, layer) {
		layer.on({
		  click: this.clickToFeature.bind(this)
		});
	}

	onEachCountryFeature(feature, layer) {
		this.countryBounds[feature.id] = layer.getBounds();
	}

	clickToFeature(e) {
		var layer = e.target;
		this.props.onLocationSelect(layer.feature.properties.gridcode)
		this.getCountry(e.latlng);
	}

	getCountry(latlng){
		const _this = this;
		const googleURL = "https://reverse.geocoder.api.here.com/6.2/reversegeocode.json?prox="+latlng.lat+"%2C"+latlng.lng+"%2C250&mode=retrieveAddresses&maxresults=1&gen=9&app_id=M5BsBUx1qS445OT7gBbY&app_code=JbBgrZYxxfTJ1FHvY85h6w";
		const request = axios.get(googleURL)
			.then(response => {
			
				if(response.data.Response)
				{
					if(response.data.Response.View[0])
					{
						this.props.setLocation(response.data.Response.View[0].Result[0].Location.Address.Country);
					}
				}
			});
	}
	
	render(){
		/**/
		const _this = this;
		
		const position = [this.props.position[0], this.props.position[1]]
		let currentCountryCode;
		
		if(this.props.countryCode)
		{
			currentCountryCode = this.props.countryCode;
		}
		else
		{
			if(this.refs.leafletMap)
			{
				this.refs.leafletMap.leafletElement.setView(this.props.position, 1);
			}
			
		}
		
		

		return(

			<Map 
		  	ref='leafletMap'
		  	bounds={currentCountryCode ? this.countryBounds[currentCountryCode] : null}
			  onClick={this.handleClick}
			  animate={true}
		  >

			<TileLayer
		      url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}"
		      attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		      ext= 'png'
		    />
		    {this.state.markers.map((position, idx) => 
	          <Marker key={`marker-${idx}`} position={position} icon={defaultIcon}>
	          <Popup>
	            <span>A pretty CSS3 popup. <br/> Easily customizable.</span>
	          </Popup>
	        </Marker>
	        )}
	        
	       	<GeoJSON 
	        	data={getCountryGeoData()}
	        	onEachFeature={this.onEachCountryFeature.bind(this)}
	        	style={{color:"transparent"}}
	        />

	        <GeoJSON 
	        	data={getClimateData()}
	        	onEachFeature={this.onEachFeature.bind(this)}
	        	style={{color:"transparent"}}
	        />
		  </Map>
		)	
	}
	
}

function mapStateToProps(state){
	return state;
}

export default connect(mapStateToProps)(LocationMap)
