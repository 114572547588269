import densityLow from '../images/Low_perth.png';
import densityMedium from '../images/medium_berlin.png';
import densityHigh from '../images/high_new_york.png';
import population50000 from '../images/50000_folkstone.png';
import population100000 from '../images/100000_lincoln.png';
import population250000 from '../images/250000-Verona.png';
import population500000 from '../images/500000-Copenhagen.png';
import population1000000 from '../images/1-Million-San_Francisco.png';
import population2000000 from '../images/2-Million-Barcelona.png';
import geologyGreenfield from '../images/greenfield.png';
import geologyBrownfieldLow from '../images/brown_low.png';
import geologyBrownfieldHigh from '../images/brown_high.png';
import demographicYoung from '../images/young-01.png';
import demographicStandard from '../images/standard-01.png';
import demographicAgeing from '../images/ageing-01.png';
import climateArid from './../images/arid.png';
import climateTemperate from './../images/temparate-01.png';
import climateCold from '.././images/cold.png';
import planningArea from '../images/area.png';
import planningPopulation from '../images/population.png';


export const densityImages = {
	low : {
		path : densityLow,
		altText : "Low density", 
		key : "lowDensity",
		text : "A spread-out city with low density suburbs full of detached dwellings. On average approximately 40 inhabitants per hectare."
	},
	medium : {
		path : densityMedium,
		altText : "Medium density", 
		key : "mediumDensity",
		text : "A mixed city  with higher density centres and medium-density suburban areas. On average approximately 70 inhabitants per hectare."
	},
	high : {
		path : densityHigh,
		altText : "High density", 
		key : "highDensity",
		text : "A compact city with many multi-dwelling buildings. Over 160 inhabitants per hectare."
	}
}

export const populationImages = {};

populationImages['default'] = {
	path : population50000,
	altText : "50,000", 
	key : "50000"
}
populationImages['50000'] = {
	path : population50000,
	altText : "50,000", 
	key : "50000"
}
populationImages['100000'] = {
	path : population100000,
	altText : "100,000", 
	key : "100000"
}
populationImages['250000'] = {
	path : population250000,
	altText : "250,000", 
	key : "250000"
}
populationImages['500000'] = {
	path : population500000,
	altText : "500,000", 
	key : "500000"
}
populationImages['1000000'] = {
	path : population1000000,
	altText : "1,000,000", 
	key : "1000000"
}
populationImages['2000000'] = {
	path : population2000000,
	altText : "2,000,000", 
	key : "2000000"
}

export const geologyImages = {};

geologyImages['Previously undeveloped land'] = {
	path : geologyGreenfield,
	altText : "Previously undeveloped land", 
	key : "reviouslyUndevelopedLand", 
	text : "Land which has had no or very limited previous development"
}

geologyImages['Previously developed land low risk'] = {
	path : geologyBrownfieldLow,
	altText : "Previously developed land low risk", 
	key : "previouslyDevelopedLandLowRisk",
	text : "Previously developed land but with limited risk of contamination of the existing ground due to the nature of previous land uses."
}

geologyImages['Previously developed land high risk'] = {
	path : geologyBrownfieldHigh,
	altText : "Previously developed land high risk", 
	key : "previouslyDevelopedLandHighRisk",
	text : "Previously developed land but with a significant risk of contamination of the existing ground due to the nature of previous land uses"
}

	
export const demographicImages = {};

demographicImages['Young population'] = {
	path : demographicYoung,
	altText : "Young population", 
	key : "youngPopulation"
}

demographicImages['Standard demographic'] = {
	path : demographicStandard,
	altText : "Standard demographic", 
	key : "standardDemographic"
}

demographicImages['Ageing population'] = {
	path : demographicAgeing,
	altText : "Ageing population", 
	key : "Ageing population"
}

export const climateImages = {};

climateImages['arid'] = {
	path : climateArid,
	altText : "Arid", 
	key : "arid",
	text : "An arid climate is one that receives less than 10 inches (25.4 centimetres) of rainfall in an entire year."
}

climateImages['tropical'] = {
	path : climateArid,
	altText : "Tropical", 
	key : "tropical",
	text : "A temperate climate has every month with an average temperature of 18 C or higher and with significant precipitation."
}

climateImages['temperate'] = {
	path : climateTemperate,
	altText : "Temperate", 
	key : "temperate",
	text : "Temperate climates are those without extremes of temperature and precipitation (rain and snow). The changes between summer and winter are generally invigorating without being frustratingly extreme."
}

climateImages['cold'] = {
	path : climateCold,
	altText : "Cold", 
	key : "cold",
	text : "These climates have average temperatures above 10 °C in their warmest months and average below −3 °C in their coldest month. These usually occur in the interiors of continents, or on their east coasts, north of 40° North latitude."
}

export const planningImages = {};

planningImages['area'] = {
	path : planningArea,
	altText : "Area", 
	key : "area",
	text : "An arid climate is one that receives less than 10 inches (25.4 centimetres) of rainfall in an entire year."
}

planningImages['population'] = {
	path : planningPopulation,
	altText : "Population", 
	key : "population",
	text : "Temperate climates are those without extremes of temperature and precipitation (rain and snow). The changes between summer and winter are generally invigorating without being frustratingly extreme."
}
	
	
