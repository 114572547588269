import React from 'react';
import { Field } from 'redux-form';

import renderSelectField from './renderSelectField';
import renderHiddenField from './renderHiddenField';

export default function InputLocation(props){
	const renderError = ({ meta: { touched, error } }) =>
	touched && error ? <span>{error}</span> : false;
  
	return (
		<div className="all-100 push-center content">
      <Field
        name="location"
        options={props.countryData}
        onChange={props.handleChange}
        component={renderSelectField}
        value={props.location ? props.location.value : ""}
      />
      <Field
        name="climate"
        onChange={props.handleClimateHiddenChange}
        component={renderHiddenField}
      />
    </div>
  )
}