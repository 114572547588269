import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import validate from './validate';
import BlueHeader from './blue-header';
import LocatonMap from './map';
import imgMarker from '../images/marker-icon.png';
import { getCountryData, getCountryGeoData } from '../json';
import { getIncomeData } from '../json';
import InputLocation from './input-location';

import 'leaflet/dist/leaflet.css';

class Question1 extends Component {



  constructor(props){
   super(props)
   //const { handleSubmit } = props
    var countryData = getCountryData();
    var countryData2 = getCountryGeoData();
    this.incomeData = getIncomeData();
    this.countryNames = {};
    var newData = countryData.map(item=>{
      
      return  {value:item.name, label:item.name, latlng:item.latlng, code:item.alpha3Code}
    })

    var newData2 = countryData2.features.map(item=>{
      this.countryNames[item.id] = item.properties.name;
      return {value:item.properties.name, label:item.properties.name, code:item.id};
    })
    
    let zoomLevel = 1;
    if(this.props.inputValue)
    {
      zoomLevel = 5;
    }
    this.state = {
      countryData : newData2,
      latlng : {
        lat : 31.505,
        lng : -0.9
      },
      zoomLevel : zoomLevel,
      location : this.props.inputValue
    }
    
    this.handleChange = this.handleChange.bind(this);
    this.setClimate = this.setClimate.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.climateByGridcode = {
        af:"tropical",
        am:"tropical",
        as:"tropical",
        aw:"tropical",
        bsh:"arid",
        bsk:"arid",
        bwh:"arid",
        bwk:"arid",
        cfa:"temperate",
        cfb:"temperate",
        cfc:"temperate",
        csa:"temperate",
        csb:"temperate",
        csc:"temperate",
        cwa:"temperate",
        cwb:"temperate",
        cwc:"temperate",
        dfa:"cold",
        dfb:"cold",
        dfc:"cold",
        dfd:"cold",
        dsa:"cold",
        dsb:"cold",
        dsc:"cold",
        dsd:"cold",
        dwa:"cold",
        dwb:"cold",
        dwc:"cold",
        dwd:"cold",
        ef:"cold",
        et:"cold"
      }

  }
  

  dragStart(event) {
      //event.dataTransfer.setData("Text", event.target.id);
  }

  /* Events fired on the drop target */
  allowDrop(event) {
      event.preventDefault();
  }

  drop(event) {
      //event.preventDefault();
      let dropXY = this.getCursorPosition(event.currentTarget, event);
      this.myClick(dropXY.x, dropXY.y);
  }

  getCursorPosition(element, event) {
      var rect = element.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
     
      return {x:x, y:y};
  }

  myClick(x, y)
  {
    var ev = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true,
        'screenX': x,
        'screenY': y,
        'detail':2
    });

    var el = document.getElementById("leaflet-map-wrap").children[0];

    el.dispatchEvent(ev);
  }

  setClimate(gridcode){
    var climate = this.climateByGridcode[gridcode.toLowerCase()];
    this.props.change('climate', climate);
  }

  handleChange(selectedItem){
   
    this.props.change('location', selectedItem.value);
    this.props.change('climate', null);
    if(this.incomeData[selectedItem.code])
    {
      this.props.change('income', this.incomeData[selectedItem.code]['Income group']);
    }
    else
    {
      this.props.change('income', "default");
    }
    //TypeError: Cannot read property '0' of undefined
    this.setState({
      location : selectedItem
    })
  }
  
  setLocation(countryCode){
    
    this.props.change('location', {value:this.countryNames[countryCode], label:this.countryNames[countryCode], code:countryCode});
    if(this.incomeData[countryCode])
    {
      this.props.change('income', this.incomeData[countryCode]['Income group']);
    }
    else
    {
      this.props.change('income', "default");
    }
    this.setState({
      location : {
        value : this.countryNames[countryCode],
        label:this.countryNames[countryCode],
        code : countryCode
      }
    })
  }

  handleClimateHiddenChange(e){
   
  }

  render(){
    let countryCode = null
    if(this.state.location)
    {
      countryCode = this.state.location.code
    }
    
    return (
      <div className="column-group page-content">
      
        <form onSubmit={this.props.handleSubmit}>
          <BlueHeader title="Location" text="Choose where the city wil be located" />
          <div className="all-80 small-100 tiny-100 push-center content">
            <div className="column-group gutters">
              <div className="all-50 small-100 tiny-100">
                  <InputLocation location={this.state.location} countryData={this.state.countryData} handleChange={this.handleChange} handleClimateHiddenChange={this.handleClimateHiddenChange} />
              </div>
              <div className="all-50 small-100 tiny-100">
                <div className="all-100 push-center"> 
                  <div id="leaflet-map-wrap" className="droptarget" onDrop={this.drop} onDragOver={this.allowDrop}> 
                    <LocatonMap setLocation={this.setLocation} countryCode={this.state.location ? this.state.location.code : null} position={[this.state.latlng.lat, this.state.latlng.lng]} zoomLevel={this.state.zoomLevel} onLocationSelect={this.setClimate}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="column-group gutters">
              <div className="all-50 small-100 tiny-100">
                <p>Type in the country you would like to locate your city, and then click on the map in the approximate position you would like to build it.  </p>
              </div>
              <div className="all-50 small-100 tiny-100">
              </div>
            </div>
          </div>
        </form>
      </div>
    )

  }
  
}

const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question1))



