import React from 'react';
import { Field } from 'redux-form';

import renderSliderField from './renderSliderField';

export default function InputPopulation(props){
  const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span className="error">{error}</span> : false;

	return (
		<div className="all-50 small-100 tiny-100 question5a" >
      <Field
        type="text"
        name="population"
        onChange={props.onSliderChange}
        component={renderSliderField}
        settings={props.settings}
        width={props.width}
      />
      <Field name="population" component={renderError} />
    </div>
	)
}