import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';

import BlueHeader from './blue-header';
import DynamicImage from './dynamic-image';
import { populationImages } from './image-details';
import getPopulationBracket from './get-population-bracket.js';
import InputPopulation from './input-population';


class Question5a extends Component {
 //const { handleSubmit, previousPage } = props

  constructor(props){
    super(props);

    this.onSliderChange = this.onSliderChange.bind(this)
    this.settings = {
      min : 50000,
      max : 2000000
    }
    const inputValue = getPopulationBracket(props.inputValue)
    this.state = {
      populationBracket : inputValue ? inputValue : null
    }
  }
  onSliderChange(e, a, b)
  {
    this.setState({
      populationBracket : getPopulationBracket(a)
    })
  }

  render(){
    
    const { populationBracket } = this.state;
    let imageDetails = populationImages["default"];
    if(populationImages[populationBracket])
    {
      imageDetails = populationImages[populationBracket];
    }
    this.settings.valueType = "population"
    return (
      <div className="page-content">
      <form onSubmit={this.props.handleSubmit}>
        <BlueHeader title="Population" text="Select population size of the city" />
        <div className="all-80 push-center content">
          <div className="column-group gutters">
            <InputPopulation settings={this.settings} width="500" onSliderChange={this.onSliderChange} />
            <div className="all-50 small-100 tiny-100">
              <div style={{position:'relative'}}>
                <DynamicImage imageDetails={imageDetails} transition="img-fade-fast" />
              </div>
            </div>
          </div>
          <div className="column-group gutters">
            <div className="all-50 small-100 tiny-100">
              <p>The model has been developed using statistical analysis such that any given population between 50,000 and 2,000,000 can be selected and an output generated.</p>
            </div>
            <div className="all-50 small-100 tiny-100">
            </div>
          </div>
        </div>
      </form>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    answers: state.form.reduxFormReducer
  }
}
export default reduxForm({
  form: 'wizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(connect(mapStateToProps)(Question5a))
